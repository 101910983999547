import { useEffect } from "react";
import {
  EdgeProps,
  getEdgeCenter,
  getSmoothStepPath,
} from "react-flow-renderer";
import useCanvasFunctions from "../../useCanvasFunctions";
import "./CustomEdge.scss";
import { Tooltip } from "common/components/Tooltip";

const foreignObjectSize = 40;

export default function CustomEdge(props: EdgeProps) {
  const {
    id,
    sourceX,
    sourceY,
    targetX,
    targetY,
    sourcePosition,
    targetPosition,
    style = {},
    selected,
    markerEnd,
  } = props;
  const edgePath = getSmoothStepPath({
    sourceX,
    sourceY,
    sourcePosition,
    targetX,
    targetY,
    targetPosition,
    borderRadius: 0,
  });
  const [edgeCenterX, edgeCenterY] = getEdgeCenter({
    sourceX,
    sourceY,
    targetX,
    targetY,
  });

  // const addNewNode = useStore((state) => state.addNode);
  // const reactFlow = useReactFlow();
  const { addNodeInBetween } = useCanvasFunctions();

  const onEdgeClick = (_: any, id: string) => {
    addNodeInBetween(id);
  };
  useEffect(() => {
    selected && console.log(props);
  }, [props, selected]);

  return (
    <>
      <path
        id={id}
        style={style}
        className="react-flow__edge-path"
        d={edgePath}
        markerEnd={markerEnd}
      />
      <foreignObject
        width={200}
        height={foreignObjectSize}
        x={edgeCenterX - foreignObjectSize / 2}
        y={edgeCenterY - foreignObjectSize / 2}
        className="edgebutton-foreignobject"
        requiredExtensions="http://www.w3.org/1999/xhtml"
      >
        <div className="wrapper">
          <button
            className="edgebutton"
            onClick={(event) => onEdgeClick(event, id)}
            title="New Step"
          >
            +
          </button>
          {/* <Tooltip>
            <Tooltip.Trigger as="div">
              <button className="edgebutton" onClick={(event) => onEdgeClick(event, id)} title="New Step">
                +
              </button>

              <Tooltip.Label label="New Step" />
            </Tooltip.Trigger>
          </Tooltip> */}
        </div>
      </foreignObject>
    </>
  );
}
