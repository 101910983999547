import {
  CanvasModal,
  CanvasModalHeader,
} from "common/components/CanvasModal/CanvasModal";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";
import { fromEvent } from "file-selector";
import getBlobDuration from "get-blob-duration";
import { useMemo } from "react";
import { useDropzone } from "react-dropzone";
import { VIDEO_MODAL_PAGES, useVideoModalStore } from "../MainNode/MainNode";
import "./UploadVideoModal.scss";

interface UploadVideoModalProps {
  isOpen?: boolean | undefined;
  onClose(value: boolean): void;
}

async function getVideoDuration(videoFile: File | Blob | DataTransferItem) {
  return new Promise<number>((res, rej) => {
    if (!videoFile.type.startsWith("video/")) rej("File must be video");
    const video = document.createElement("video");
    video.preload = "metadata";
    video.onloadeddata = function () {
      res(video.duration);
    };
    let file;
    if (videoFile instanceof DataTransferItem) file = videoFile.getAsFile();
    else file = videoFile;
    if (!file) return res(1);
    video.src = URL.createObjectURL(file);
  });
}

export const UploadVideoModal = ({
  isOpen,
  onClose,
}: UploadVideoModalProps) => {
  const { setFile, setPage, page } = useVideoModalStore(
    ({ setFile, page, setPage, file }) => ({
      setFile,
      setPage,
      file,
      page,
    })
  );
  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragReject,
    fileRejections,
  } = useDropzone({
    accept: {
      "video/*": [],
    },
    noDragEventsBubbling: true,
    maxSize: 5e8,
    maxFiles: 1,
    multiple: false,
    async getFilesFromEvent(event) {
      let files = await fromEvent(event);
      console.log({ files });
      const fileRes = await Promise.all(
        files.map(async (videoFile) => {
          let file;
          if (videoFile instanceof DataTransferItem)
            file = videoFile.getAsFile();
          else file = videoFile;

          const duration = await getBlobDuration(file!).catch(() => 0);
          const isValid = duration < 60 * 60;
          return Object.defineProperty(videoFile, "isValid", {
            value: isValid,
            writable: false,
          });
        })
      );
      return fileRes;
    },

    validator(f) {
      let file: (File & { isValid?: boolean }) | null = f;
      if (file.size > 5e8)
        return { message: "File too large (max 500 MB)", code: "FileTooLarge" };
      if (file.isValid === false)
        return {
          message: "File Duration should be less than 15 minutes",
          code: "LargeFileDuration",
        };
      return null;
    },

    onDrop(files) {
      console.log("Before files.length if");
      if (files.length) {
        console.log("Inside files.length if");
        setFile(files[0]);
        if (!isDragReject) {
          console.log("Inside !isDragReject");
          setPage(VIDEO_MODAL_PAGES.UPLOAD_PROGRESS);
        }
      }
    },
  });
  console.log({ fileRejections, isDragReject, page });
  const isInvalid = useMemo(
    () => isDragReject || fileRejections.length,
    [fileRejections.length, isDragReject]
  );

  return (
    <>
      <CanvasModal
        isClosableOnBlur
        isOpen={isOpen}
        onClose={onClose}
        className="upload-video-modal"
      >
        <CanvasModalHeader>
          <div className="upload-video-modal__header">
            <h2 className="upload-video-modal__heading">Upload Video</h2>
          </div>
        </CanvasModalHeader>
        <div className="upload-video-modal__body">
          <div
            {...getRootProps({
              className: classNames(
                "dropzone upload-video-modal__video-drag-field ",
                {
                  active: isDragActive,
                  error: isInvalid,
                }
              ),
            })}
          >
            <input {...getInputProps()} />
            <div className="video-drag-field__icon">
              <FontAwesomeIcon icon={solid("cloud-arrow-up")} />
            </div>
            <div className="video-drag-field__text">
              <h2>Drag your file here</h2>
              <p>or click here to choose a file</p>
              {!!isInvalid && (
                <strong style={{ color: "var(--red-700)" }}>
                  {
                    fileRejections[fileRejections.length - 1]?.errors[
                      fileRejections[fileRejections.length - 1].errors.length -
                        1
                    ]?.message
                  }
                </strong>
              )}
            </div>
            <div className="video-drag-field__help">
              <FontAwesomeIcon icon={solid("question")} />
            </div>
          </div>
        </div>
        <div className="upload-video-modal__footer">
          <button
            onClick={() => setPage(VIDEO_MODAL_PAGES.ADD_VIDEO)}
            className="upload-video-modal__save-btn"
          >
            <span>
              <FontAwesomeIcon fixedWidth icon={solid("left-long")} />
            </span>
            <span>Back</span>
          </button>
        </div>
      </CanvasModal>
    </>
  );
};
