import { Switch } from "@headlessui/react";

import "./LabelledSwitch.scss";

interface LabelledSwitchProps {
  enabled: boolean;
  onChange: React.Dispatch<React.SetStateAction<boolean>>;
  enabledLabel?: string;
  disabledLabel?: string;
}

export const LabelledSwitch = ({
  enabled,
  onChange,
  enabledLabel,
  disabledLabel,
}: LabelledSwitchProps) => {
  return (
    <div className="main-node-settings__content">
      <Switch
        checked={enabled}
        onChange={onChange}
        className={`${
          enabled ? "labelled-switch--enabled" : ""
        } labelled-switch`}
      >
        {enabled && enabledLabel && (
          <div className="labelled-switch__label">{enabledLabel}</div>
        )}
        {!enabled && disabledLabel && (
          <div className="labelled-switch__label">{disabledLabel}</div>
        )}
        <span className="labelled-switch__thumb"></span>
      </Switch>
    </div>
  );
};
