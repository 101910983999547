import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { createStore, StoreApi, useStore } from "zustand";

import { InputTextField } from "common/components/InputTextField/InputTextField";
import { TooltipMenu } from "common/components/TooltipMenu";
import { useOutComesStore } from "modules/canvas/components/OutcomeNode";
import { currentNodeContext } from "modules/canvas/currentNodeContext";
import { NodeDataChildChoice } from "modules/canvas/data/types";
import { createContext, useContext, useEffect, useState } from "react";
import { isValidURL } from "modules/canvas/utils";

enum CHOICE_SETTING_PAGE {
  HOME = "home",
  REDIRECT_URL = "redirect_url",
  JUMP_STEP = "jump_step",
}

interface ChoiceSettingsState {
  currentPage: CHOICE_SETTING_PAGE;
  setCurrentPage: (page: CHOICE_SETTING_PAGE) => void;
}

interface ChoiceSettingsContextType {
  store: StoreApi<ChoiceSettingsState>;
  open: boolean;
  currentChoice: NodeDataChildChoice;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const ChoiceSettingsStore = createStore<ChoiceSettingsState>((set) => ({
  currentPage: CHOICE_SETTING_PAGE.HOME,
  setCurrentPage: (page) => set((state) => ({ ...state, currentPage: page })),
}));

const ChoiceeSettingsContext = createContext({} as ChoiceSettingsContextType);

export const MainNodeChoiceOutcomesSettings: React.FC<{
  choice: NodeDataChildChoice;
}> = ({ choice }) => {
  const [open, setOpen] = useState(false);

  const { currentPage, setCurrentPage } = useStore(ChoiceSettingsStore);

  const handleTooltipOpen = (open: boolean) => {
    setOpen(open);
    if (!open) setCurrentPage(CHOICE_SETTING_PAGE.HOME);
  };

  return (
    <TooltipMenu open={open} onOpenChange={handleTooltipOpen}>
      <TooltipMenu.Trigger
        className="main-node-choice__action"
        aria-label="Choice Settings"
      >
        <FontAwesomeIcon fixedWidth icon={solid("cog")} />
      </TooltipMenu.Trigger>
      <TooltipMenu.Content>
        <ChoiceeSettingsContext.Provider
          value={{
            store: ChoiceSettingsStore,
            open,
            setOpen,
            currentChoice: choice,
          }}
        >
          {currentPage === CHOICE_SETTING_PAGE.HOME && (
            <MainNodeChoiceSettingsHome />
          )}

          {currentPage === CHOICE_SETTING_PAGE.REDIRECT_URL && (
            <MainNodeChoiceSettingsRedirect />
          )}
        </ChoiceeSettingsContext.Provider>
      </TooltipMenu.Content>
    </TooltipMenu>
  );
};

const MainNodeChoiceSettingsHome = () => {
  const { store, setOpen } = useContext(ChoiceeSettingsContext);
  const { setCurrentPage } = useStore(store);
  // const { saveModifiedChoiceValue, deleteBranchNode } = useCanvasFunctions();
  // const { id } = useContext(currentNodeContext);

  // const setContinueStep = () => {
  //   const newChoice: NodeDataChildChoice = {
  //     ...currentChoice,
  //     selectedOption: ChoiceActionTypes.CONTINUE,
  //     branchId: undefined,
  //     redirectURL: undefined,
  //     jumpToStepId: undefined,
  //   };

  //   if (currentChoice.branchId) deleteBranchNode(currentChoice.branchId);
  //   saveModifiedChoiceValue(newChoice, id);
  // };
  return (
    <>
      <TooltipMenu.Header>
        <span>Settings</span>
      </TooltipMenu.Header>
      <TooltipMenu.Options>
        <TooltipMenu.Option
          onClick={() => setCurrentPage(CHOICE_SETTING_PAGE.REDIRECT_URL)}
        >
          <TooltipMenu.OptionIcon>
            <FontAwesomeIcon fixedWidth icon={solid("link")} />
          </TooltipMenu.OptionIcon>
          <span>Redirect to URL</span>
        </TooltipMenu.Option>
      </TooltipMenu.Options>
      <TooltipMenu.Footer>
        <TooltipMenu.ActionButton onClick={() => setOpen(false)}>
          Done
        </TooltipMenu.ActionButton>
      </TooltipMenu.Footer>
    </>
  );
};

const MainNodeChoiceSettingsRedirect = () => {
  const { store, setOpen, currentChoice } = useContext(ChoiceeSettingsContext);
  const [url, setUrl] = useState("");
  const { setCurrentPage } = useStore(store);
  const { data } = useContext(currentNodeContext);
  const {
    editOutcomeCta,
    multipleOutComes,
    currentOutcomeType,
    singleOutcome,
  } = useOutComesStore();
  const [isUrlValid, setIsUrlValid] = useState(true);

  const handleSaveButton = () => {
    setRedirectURL(url);
    setCurrentPage(CHOICE_SETTING_PAGE.HOME);
    setOpen(false);
  };
  useEffect(() => {
    const URL =
      currentOutcomeType === "multiple"
        ? multipleOutComes
            ?.find((outcome) => outcome.id === data.rootNodeId)
            ?.nodeData?.ctaLabels?.find((data) => data.id === currentChoice.id)
            ?.url
        : singleOutcome?.nodeData?.ctaLabels?.find(
            (data) => data.id === currentChoice.id
          )?.url;

    if (URL) setUrl(URL);
  }, [
    currentChoice.id,
    currentOutcomeType,
    data.rootNodeId,
    multipleOutComes,
    singleOutcome?.nodeData?.ctaLabels,
  ]);

  const setRedirectURL = (url: string) => {
    editOutcomeCta(currentChoice.id, data.rootNodeId, {
      url,
    });
    // const newChoice: NodeDataChildChoice = {
    //   ...currentChoice,
    //   redirectURL: url,
    //   selectedOption: ChoiceActionTypes.REDIRECT,
    //   branchId: undefined,
    //   jumpToStepId: undefined,
    // };
    // if (currentChoice.branchId) deleteBranchNode(currentChoice.branchId);
    // saveModifiedChoiceValue(newChoice, id);
  };
  return (
    <>
      <TooltipMenu.Header>
        <TooltipMenu.BackNav
          onClick={() => setCurrentPage(CHOICE_SETTING_PAGE.HOME)}
        />
        <span>Redirect To URL</span>
      </TooltipMenu.Header>
      <TooltipMenu.Body>
        <InputTextField
          variant="small"
          value={url}
          hasError={!isUrlValid}
          onChange={(e) => {
            setUrl(e.target.value);
            setIsUrlValid(isValidURL(e.target.value));
          }}
        />
      </TooltipMenu.Body>
      <TooltipMenu.Footer>
        <TooltipMenu.ActionButton
          disabled={!isUrlValid}
          onClick={handleSaveButton}
        >
          Save
        </TooltipMenu.ActionButton>
      </TooltipMenu.Footer>
    </>
  );
};

// const MainNodeChoiceSettingsJump = () => {
//   const { store, setOpen, currentChoice } = useContext(ChoiceeSettingsContext);
//   const { setCurrentPage } = useStore(store);
//   const { saveModifiedChoiceValue, deleteBranchNode, getHierachy } = useCanvasFunctions();
//   const { id } = useContext(currentNodeContext);
//   const reactFlow = useReactFlow<NodeData>();

//   const handleSaveButton = () => {
//     setOpen(false);
//   };
//   const allNodes = useMemo(() => {
//     const steps: { label: string; id: string }[] = [];
//     const hierarchy = getHierachy(currentChoice.branchId ?? "");
//     reactFlow.getNodes().forEach((node) => {
//       if (node.type === NodeTypes.BRANCHED_NODE) return;
//       if (node.id === ABSOLUTE_ROOT_NODE_ID) return;
//       if (node.type === NodeTypes.OUTCOME_NODE) return;
//       if (node.data.isOutcomeNode) return;
//       if (hierarchy && hierarchy.nodeIds.has(node.id)) return;
//       steps.push({
//         id: node.id,
//         label: node.data.customSmallLabel ?? "",
//       });
//     });
//     return steps;
//   }, [currentChoice.branchId, getHierachy, reactFlow]);
//   const save = (data: { label: string; id: any }) => {
//     console.log({ data });
//     setJumpToStep(data.id);
//   };
//   const setJumpToStep = (nodeId: string) => {
//     const newChoice: NodeDataChildChoice = {
//       ...currentChoice,
//       selectedOption: ChoiceActionTypes.JUMP_TO_STEP,
//       jumpToStepId: nodeId,
//       branchId: undefined,
//       redirectURL: undefined,
//     };
//     deleteBranchNode(currentChoice.branchId ?? "");
//     saveModifiedChoiceValue(newChoice, id);
//   };
//   const currentStep = useMemo(() => {
//     const jumpToStepNode = reactFlow.getNode(currentChoice.jumpToStepId ?? "");
//     if (
//       currentChoice.jumpToStepId &&
//       jumpToStepNode &&
//       currentChoice.selectedOption === ChoiceActionTypes.JUMP_TO_STEP
//     ) {
//       return {
//         label: jumpToStepNode?.data.customSmallLabel,
//         id: jumpToStepNode?.id,
//       };
//     } else {
//       return {
//         label: "None Selected",
//         id: nanoid(),
//         unavailable: true,
//       };
//     }
//   }, [currentChoice.jumpToStepId, currentChoice.selectedOption, reactFlow]);
//   return (
//     <>
//       <TooltipMenu.Header>
//         <TooltipMenu.BackNav onClick={() => setCurrentPage(CHOICE_SETTING_PAGE.HOME)} />
//         <span>Jump to Step</span>
//       </TooltipMenu.Header>
//       <TooltipMenu.Body>
//         <Listbox value={currentStep} onChange={save}>
//           <div className="listbox__wrapper">
//             <Listbox.Button className="listbox__button">
//               <span className="listbox__label">{currentStep.label}</span>
//               <span className="listbox__button-icon">
//                 <FontAwesomeIcon fixedWidth icon={solid("caret-down")} />
//               </span>
//             </Listbox.Button>

//             <Listbox.Options className="listbox__options">
//               {allNodes.map((item, i) => (
//                 <Listbox.Option
//                   key={i}
//                   className={({ active, selected }) =>
//                     `listbox__option ${active ? "listbox__option--active" : ""}
//                     ${selected ? "listbox__option--selected" : ""}`
//                   }
//                   value={item}
//                 >
//                   <span>{item.label}</span>
//                 </Listbox.Option>
//               ))}
//             </Listbox.Options>
//           </div>
//         </Listbox>
//       </TooltipMenu.Body>

//       <TooltipMenu.Footer>
//         <TooltipMenu.ActionButton onClick={handleSaveButton}>Save</TooltipMenu.ActionButton>
//       </TooltipMenu.Footer>
//     </>
//   );
// };
