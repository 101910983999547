import { createStore, StoreApi, useStore } from "zustand";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { NodeCardSideAction } from "common/components/NodeCardSideAction";
import { TooltipMenu } from "common/components/TooltipMenu";
import { createContext, useContext, useMemo, useState } from "react";
import { Listbox } from "@headlessui/react";
import { KangarooIcon } from "assets/icons/KangarooIcon";
import { useReactFlow } from "react-flow-renderer";
import useCanvasFunctions from "modules/canvas/useCanvasFunctions";
import { currentNodeContext } from "modules/canvas/currentNodeContext";
import {
  EdgeData,
  EdgeTypes,
  NodeData,
  NodeDataChildType,
  NodeTypes,
  SelectedLastBranchSetting,
} from "modules/canvas/data/types";
import { nanoid } from "nanoid";
import {
  ABSOLUTE_ROOT_NODE_ID,
  choices,
} from "modules/canvas/data/initialData";

enum CHOICE_SETTING_PAGE {
  HOME = "home",
  REDIRECT_URL = "redirect_url",
  JUMP_STEP = "jump_step",
}

interface ChoiceSettingsState {
  currentPage: CHOICE_SETTING_PAGE;
  setCurrentPage: (page: CHOICE_SETTING_PAGE) => void;
}

interface ChoiceSettingsContextType {
  store: StoreApi<ChoiceSettingsState>;
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

interface MainNodeNextStepUIProps {
  handleAddStepAction?: () => void;
}

const ChoiceSettingsStore = createStore<ChoiceSettingsState>((set) => ({
  currentPage: CHOICE_SETTING_PAGE.HOME,
  setCurrentPage: (page) => set((state) => ({ ...state, currentPage: page })),
}));

const ChoiceeSettingsContext = createContext({} as ChoiceSettingsContextType);

export const MainNodeNextStepUI = ({
  handleAddStepAction,
}: MainNodeNextStepUIProps) => {
  const [open, setOpen] = useState(false);

  const { currentPage, setCurrentPage } = useStore(ChoiceSettingsStore);

  const handleTooltipOpen = (open: boolean) => {
    setOpen(open);
    if (!open) setCurrentPage(CHOICE_SETTING_PAGE.HOME);
  };

  return (
    <>
      <NodeCardSideAction position="bottom">
        <NodeCardSideAction.Wrapper>
          <TooltipMenu open={open} onOpenChange={handleTooltipOpen}>
            <TooltipMenu.Trigger
              className="main-node-choice__action next-step-popup"
              aria-label="Choice Settings"
            >
              <FontAwesomeIcon fixedWidth icon={solid("cog")} />
            </TooltipMenu.Trigger>

            <TooltipMenu.Content>
              <ChoiceeSettingsContext.Provider
                value={{ store: ChoiceSettingsStore, open, setOpen }}
              >
                {currentPage === CHOICE_SETTING_PAGE.HOME && (
                  <MainNodeChoiceSettingsHome />
                )}

                {currentPage === CHOICE_SETTING_PAGE.JUMP_STEP && (
                  <MainNodeChoiceSettingsJump />
                )}
              </ChoiceeSettingsContext.Provider>
            </TooltipMenu.Content>
          </TooltipMenu>
        </NodeCardSideAction.Wrapper>
      </NodeCardSideAction>
    </>
  );
};

const MainNodeChoiceSettingsHome = () => {
  const { store, setOpen } = useContext(ChoiceeSettingsContext);
  const { setCurrentPage } = useStore(store);
  const reactFlow = useReactFlow<NodeData, EdgeData>();
  const { getNode, addNodeAtEnd, formatCanvas } = useCanvasFunctions();
  const currNode = useContext(currentNodeContext);
  const setReturnToMainFlow = () => {
    const rootNode = getNode(currNode.data.rootNodeId);
    if (!rootNode) return;
    const derivedNode = getNode(rootNode.data.derivedFromNodeId ?? "");
    if (
      !derivedNode ||
      derivedNode.data.children.type !== NodeDataChildType.CHOICES
    )
      return;
    const targetId = reactFlow
      .getEdges()
      .find(
        (e) => e.source === derivedNode.id && e.type === EdgeTypes.BUTTON_EDGE
      )?.target;
    if (!targetId) return;
    const currNewNode = reactFlow.getNode(currNode.id)!;
    currNewNode.data = {
      ...currNewNode.data,
      selectedLastBranchSetting: SelectedLastBranchSetting.RETURN_TO_PARENT,
      selectedLastBranchSettingValue: targetId,
    };
    reactFlow.setNodes(
      reactFlow
        .getNodes()
        .map((node) => (node.id === currNewNode.id ? currNewNode : node))
    );
  };
  const createNewStep = () => {
    addNodeAtEnd(currNode.id, {
      data: {
        children: { type: NodeDataChildType.CHOICES, choices: choices() },
        rootNodeId: currNode.data.rootNodeId,
        hasTargetNode: false,
        label: "New step",
        step: currNode.data.step + 1,
        selectedLastBranchSetting: currNode.data.selectedLastBranchSetting,
        selectedLastBranchSettingValue:
          currNode.data.selectedLastBranchSettingValue,
      },
    });
    const node = getNode(currNode.id)!;
    node.data = {
      ...node.data,
      selectedLastBranchSetting: undefined,
      selectedLastBranchSettingValue: undefined,
    };
    setTimeout(() => {
      reactFlow.setNodes(
        reactFlow.getNodes().map((n) => (n.id === node.id ? node : n))
      );
      formatCanvas();
    });
  };
  return (
    <>
      <TooltipMenu.Header>
        <span>After This</span>
      </TooltipMenu.Header>
      <TooltipMenu.Options>
        <TooltipMenu.Option onClick={setReturnToMainFlow}>
          <TooltipMenu.OptionIcon>
            <FontAwesomeIcon fixedWidth icon={solid("left")} />
          </TooltipMenu.OptionIcon>
          <span>Return Main Flow</span>
          {currNode.data.selectedLastBranchSetting ===
            SelectedLastBranchSetting.RETURN_TO_PARENT && (
            <div className="main-node-choice__active-setting">
              <FontAwesomeIcon fixedWidth icon={solid("check")} />
            </div>
          )}
        </TooltipMenu.Option>
        <TooltipMenu.Option
          onClick={() => setCurrentPage(CHOICE_SETTING_PAGE.JUMP_STEP)}
        >
          <TooltipMenu.OptionIcon>
            <KangarooIcon />
          </TooltipMenu.OptionIcon>
          <span>Jump to Step</span>
          {currNode.data.selectedLastBranchSetting ===
            SelectedLastBranchSetting.JUMP_TO_STEP && (
            <div className="main-node-choice__active-setting">
              <FontAwesomeIcon fixedWidth icon={solid("check")} />
            </div>
          )}
        </TooltipMenu.Option>
        <TooltipMenu.Option onClick={createNewStep}>
          <TooltipMenu.OptionIcon>
            <FontAwesomeIcon fixedWidth icon={solid("circle-plus")} />
          </TooltipMenu.OptionIcon>
          <span>Create New Step</span>
        </TooltipMenu.Option>
      </TooltipMenu.Options>
      <TooltipMenu.Footer>
        <TooltipMenu.ActionButton onClick={() => setOpen(false)}>
          Done
        </TooltipMenu.ActionButton>
      </TooltipMenu.Footer>
    </>
  );
};

const MainNodeChoiceSettingsJump = () => {
  const { store, setOpen } = useContext(ChoiceeSettingsContext);
  const { setCurrentPage } = useStore(store);
  const reactFlow = useReactFlow();
  const { data, id } = useContext(currentNodeContext);
  const { getNode } = useCanvasFunctions();

  const currentStep = useMemo(() => {
    const jumpToStepNode = reactFlow.getNode(
      data.selectedLastBranchSettingValue ?? ""
    );
    if (
      data.selectedLastBranchSettingValue &&
      jumpToStepNode &&
      data.selectedLastBranchSetting === SelectedLastBranchSetting.JUMP_TO_STEP
    ) {
      return {
        label:
          jumpToStepNode.id === "3"
            ? "Optin Screen"
            : jumpToStepNode?.data.customSmallLabel,
        id: jumpToStepNode?.id,
      };
    } else {
      return {
        label: "None Selected",
        id: nanoid(),
        unavailable: true,
      };
    }
  }, [
    data.selectedLastBranchSetting,
    data.selectedLastBranchSettingValue,
    reactFlow,
  ]);

  const allNodes = useMemo(() => {
    const steps: { label: string; id: string }[] = [];
    reactFlow.getNodes().forEach((node) => {
      if (node.type === NodeTypes.BRANCHED_NODE) return;
      if (node.id === ABSOLUTE_ROOT_NODE_ID) return;
      if (node.type === NodeTypes.OUTCOME_NODE) return;
      if (node.data.isOutcomeNode) return;
      steps.push({
        id: node.id,
        label:
          node.id === "3" ? "Optin Screen" : node.data.customSmallLabel ?? "",
      });
    });
    return steps;
  }, [reactFlow]);

  const setJumpToStep = (val: {
    label: any;
    id: string;
    unavailable?: undefined;
  }) => {
    const currNode = getNode(id)!;

    currNode.data = {
      ...currNode.data,
      selectedLastBranchSetting: SelectedLastBranchSetting.JUMP_TO_STEP,
      selectedLastBranchSettingValue: val.id,
    };
    reactFlow.setNodes(
      reactFlow.getNodes().map((n) => (n.id === currNode.id ? currNode : n))
    );
  };

  const handleSaveButton = () => {
    setOpen(false);
  };

  return (
    <>
      <TooltipMenu.Header>
        <TooltipMenu.BackNav
          onClick={() => setCurrentPage(CHOICE_SETTING_PAGE.HOME)}
        />
        <span>Jump to Step</span>
      </TooltipMenu.Header>
      <TooltipMenu.Body>
        <Listbox value={currentStep} onChange={setJumpToStep}>
          <div className="listbox__wrapper">
            <Listbox.Button className="listbox__button">
              <span className="listbox__label">{currentStep.label}</span>
              <span className="listbox__button-icon">
                <FontAwesomeIcon fixedWidth icon={solid("caret-down")} />
              </span>
            </Listbox.Button>

            <Listbox.Options className="listbox__options">
              {allNodes.map((item, i) => (
                <Listbox.Option
                  key={i}
                  className={({ active, selected }) =>
                    `listbox__option ${active ? "listbox__option--active" : ""}
                    ${selected ? "listbox__option--selected" : ""}`
                  }
                  value={item}
                >
                  <span>{item.label}</span>
                </Listbox.Option>
              ))}
            </Listbox.Options>
          </div>
        </Listbox>
      </TooltipMenu.Body>

      <TooltipMenu.Footer>
        <TooltipMenu.ActionButton onClick={handleSaveButton}>
          Save
        </TooltipMenu.ActionButton>
      </TooltipMenu.Footer>
    </>
  );
};
