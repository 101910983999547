import { useEffect, useState } from "react";

import {
  CanvasModal,
  CanvasModalHeader,
} from "common/components/CanvasModal/CanvasModal";

import "./NewOutcomeModal.scss";
import { InputTextField } from "common/components/InputTextField/InputTextField";

import { LabelledSwitch } from "common/components/LabelledSwitch/LabelledSwitch";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { OUT_COMES_PAGE, useOutComesStore } from "../OutcomeNode";
import { nanoid } from "nanoid";
import { isValidURL } from "modules/canvas/utils";

interface NewOutcomeModalProps {
  isOpen?: boolean | undefined;
  onClose(value: boolean): void;
}

export const NewOutcomeModal = ({ isOpen, onClose }: NewOutcomeModalProps) => {
  const [newName, setNewName] = useState<string>("");
  const [url, setUrl] = useState<string>("");

  const [enabled, setEnabled] = useState(true);
  const {
    addMultipleOutCome,
    setPage,
    editingOutcomeId,
    currentOutcomeType,
    editMultipleOutCome,
    multipleOutComes,
    setEditingOutcomeId,
    editSingleOutcome,
    singleOutcome,
  } = useOutComesStore();

  useEffect(() => {
    const outcome =
      currentOutcomeType === "multiple" &&
      multipleOutComes?.find((outcome) => outcome.id === editingOutcomeId);
    setNewName("");
    setUrl("");
    if (editingOutcomeId && outcome) {
      setNewName(outcome.label);
      setUrl(outcome.redirectURL ?? "");
      setEnabled(outcome.type === "flow");
    } else {
      setNewName(singleOutcome?.label ?? "");
      setUrl(singleOutcome?.redirectURL ?? "");
    }
  }, [
    currentOutcomeType,
    editingOutcomeId,
    multipleOutComes,
    singleOutcome?.label,
    singleOutcome?.redirectURL,
  ]);

  const handleSubmit = () => {
    if (!editingOutcomeId) {
      if (currentOutcomeType === "single") {
        editSingleOutcome({
          id: nanoid(),
          label: newName,
          redirectURL: url,
          type: !enabled ? "url" : "flow",
          nodeData: {
            id: nanoid(),
            ctaLabels: [
              {
                id: nanoid(),
                url: "",
                value: "Option1",
              },
            ],
            title: "Optional title / question",
          },
        });
      } else {
        addMultipleOutCome({
          isWebinarMode: false,
          id: nanoid(),
          label: newName,
          redirectURL: url,
          type: !enabled ? "url" : "flow",
          answers: [],
          delay: 0,
          nodeData: {
            id: nanoid(),
            ctaLabels: [
              {
                id: nanoid(),
                url: "",
                value: "Option1",
              },
            ],
            title: "Optional title / question",
          },
        });
      }
    } else {
      editMultipleOutCome(editingOutcomeId, {
        label: newName,
        redirectURL: url,
        type: !enabled ? "url" : "flow",
      });
      setEditingOutcomeId("");
    }

    setPage(OUT_COMES_PAGE.MULTIPLE_OUTCOME_LIST);
  };

  const [isURLValid, setIsURLValid] = useState(true);

  useEffect(() => {
    if (enabled) setIsURLValid(true);
    if (!enabled && url.length) setIsURLValid(isValidURL(url));
  }, [enabled, url]);

  return (
    <>
      <CanvasModal
        isClosableOnBlur
        isOpen={isOpen}
        onClose={() => {
          if (editingOutcomeId) setEditingOutcomeId("");

          onClose(false);
        }}
        className="new-outcome-modal"
      >
        <CanvasModalHeader>
          <div className="new-outcome-modal__header">
            <button
              onClick={() => setPage(OUT_COMES_PAGE.MULTIPLE_OUTCOME_LIST)}
              className="new-outcome-modal__back-button"
            >
              <FontAwesomeIcon fixedWidth icon={solid("caret-left")} />
              Back
            </button>
            <h2 className="new-outcome-modal__heading">New Outcome</h2>
          </div>
        </CanvasModalHeader>
        <div className="new-outcome-modal__body">
          <div className="new-outcome-modal__content">
            <InputTextField
              value={newName}
              onChange={(e) => setNewName(e.target.value)}
              label="Outcome Name"
            />

            <div className="new-outcome-modal__switch">
              <p className="new-outcome-modal__switch-label">Destination</p>
              <LabelledSwitch
                enabled={enabled}
                onChange={setEnabled}
                enabledLabel="FLOW"
                disabledLabel="URL"
              />
            </div>

            {enabled ? null : (
              <div className="new-outcome-modal__url-input-field">
                <InputTextField
                  value={url}
                  hasError={!isURLValid}
                  onChange={(e) => setUrl(e.target.value)}
                  label="Redirect URL"
                />
              </div>
            )}
          </div>
        </div>

        <div className="new-outcome-modal__footer">
          {!!newName && (
            <button
              disabled={!isURLValid}
              type="button"
              onClick={handleSubmit}
              className="new-outcome-modal__continue-btn"
            >
              Save
            </button>
          )}
        </div>
      </CanvasModal>
    </>
  );
};
