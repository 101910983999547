import { useState } from "react";

import {
  CanvasModal,
  CanvasModalHeader,
} from "common/components/CanvasModal/CanvasModal";

import { NodeDataChildChoice } from "modules/canvas/data/types";

import "./AddBranchModal.scss";

interface AddBranchModalProps {
  isOpen?: boolean | undefined;
  onClose(value: boolean): void;
  choices: NodeDataChildChoice[];
  onSubmit: (choice: NodeDataChildChoice) => any;
}

export const AddBranchModal = ({
  isOpen,
  onClose,
  choices,
  onSubmit,
}: AddBranchModalProps) => {
  const [selectedChoice, setSelectedChoice] = useState<NodeDataChildChoice>(
    {} as NodeDataChildChoice
  );

  const handleSelectChoice = (choice: NodeDataChildChoice) => {
    setSelectedChoice(choice);
  };

  return (
    <>
      <CanvasModal
        isClosableOnBlur
        isOpen={isOpen}
        onClose={onClose}
        className="add-branch-modal"
      >
        <CanvasModalHeader>
          <div className="add-branch-modal__header">
            <h2 className="add-branch-modal__heading">Choose the option</h2>
            <p className="add-branch-modal__sub-heading">
              that will lead to this step...
            </p>
            <p className="add-branch-modal__heading-note">
              Want multiple options?{" "}
              <a
                target="_blank"
                href="https://interface.helpscoutdocs.com/"
                rel="noreferrer"
              >
                Find out how!
              </a>
            </p>
          </div>
        </CanvasModalHeader>
        <div className="add-branch-modal__body">
          <div className="add-branch-modal__choice-list">
            {choices.map((choice) => (
              <button
                key={choice.id}
                type="button"
                className={`add-branch-modal__choice-btn ${
                  selectedChoice.id === choice.id ? "selected" : ""
                }`}
                disabled={!!choice.branchId}
                onClick={() => handleSelectChoice(choice)}
              >
                {choice.value}
              </button>
            ))}
          </div>
        </div>

        {!!Object.keys(selectedChoice).length && (
          <div className="add-branch-modal__footer">
            <button
              type="button"
              onClick={() => onSubmit(selectedChoice)}
              className="add-branch-modal__continue-btn"
            >
              Continue
            </button>
          </div>
        )}
      </CanvasModal>
    </>
  );
};
