export const PersonPlaceholder = () => {
  return (
    <svg
      width="256"
      height="256"
      viewBox="0 0 256 256"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        opacity="0.2261"
        d="M128.01 15C99.779 15 83.4501 29.3734 77.6603 49.9197C74.9281 59.6605 74.8056 71.1327 75.6471 83.5954C74.0905 83.5291 72.3339 83.5291 70.4471 83.5954C61.2746 84.0585 68.0283 105.607 74.2051 118.265C75.51 120.851 78.2383 120.253 79.409 119.923C81.7514 141.331 85.0156 147.749 93.2124 157.163L93.0674 167.371C92.8725 181.292 85.0656 192.819 72.3143 200.244C71.2779 200.84 70.2483 201.437 69.2068 201.896C63.2222 204.88 56.6555 206.397 50.0193 206.662C19.3208 208.121 9.23426 222.377 0 241H256C246.762 222.377 236.682 208.121 205.981 206.662C199.344 206.331 192.705 204.88 186.789 201.896C185.817 201.371 184.788 200.84 183.682 200.244C170.934 192.819 163.059 181.292 162.929 167.371L162.806 157.163C171.003 147.749 174.18 141.331 176.591 119.923C177.696 120.321 180.486 120.916 181.791 118.265C188.032 105.607 194.741 84.0585 185.572 83.5954C183.682 83.5291 181.929 83.4628 180.371 83.5954C181.213 71.2029 181.133 59.6605 178.332 49.9197C172.542 29.3734 156.236 15 128.01 15L128.01 15Z"
        fill="#CAD0DA"
      />
    </svg>
  );
};
