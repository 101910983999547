import { Edge, Node } from "react-flow-renderer";
import { NodeData, EdgeData, NodeTypes } from "../data/types";
import { isChoiceSelected } from "./isChoiceSelected";

export function clamp(num: number, min: number, max: number) {
  if (min > max)
    throw new Error(
      `Clamp: Min (${min}) should not be greater than Max (${max})`
    );
  return num <= min ? min : num >= max ? max : num;
}

export function prevValClamp(
  currVal: number,
  prevVal: number,
  min: number,
  max: number
) {
  return currVal > max || currVal < min ? prevVal : currVal;
}

type NodeParam = string | Node<NodeData>;

const getNode = (
  node: NodeParam,
  nodes: Node<NodeData>[]
): Node<NodeData> | undefined => {
  if (typeof node === "string") return nodes.find((n) => n.id === node);

  return node;
};

function mutationFilter<T>(arr: T[], cb: (el: T) => boolean) {
  for (let l = arr.length - 1; l >= 0; l -= 1) {
    if (!cb(arr[l])) {
      arr.splice(l, 1);
    }
  }
}

export const deleteNodeInBetweenWithRelationsMutable = (
  nodeId: NodeParam,
  nodes: Node<NodeData>[],
  edges: Edge<EdgeData>[],
  keepSourceNode = false
) => {
  const node = getNode(nodeId, nodes);
  if (
    !(
      node &&
      (node.type === NodeTypes.CARD || node.type === NodeTypes.BRANCHED_NODE)
    )
  )
    return;

  if (isChoiceSelected(node)) {
    node.data.children.choices
      ?.filter((choice) => !!choice.branchId)
      .map((choice) => choice.branchId)
      .forEach(
        (id) => id && deleteNodeInBetweenWithRelationsMutable(id, nodes, edges)
      );
  }

  if (!keepSourceNode) {
    edges
      .filter((edge) => edge.source === node.id)
      .forEach((edge) =>
        deleteNodeInBetweenWithRelationsMutable(edge.target, nodes, edges)
      );
    mutationFilter(nodes, (n) => n.id !== nodeId);
    mutationFilter(
      edges,
      (edge) => !(edge.source === nodeId || edge.target === nodeId)
    );
  }
  console.log(nodes, edges);
};
