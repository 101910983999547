import { useState } from "react";

import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { NodeDataChildChoice } from "modules/canvas/data/types";

import useCanvasFunctions from "modules/canvas/useCanvasFunctions";
import { AddBranchModal } from "../../AddBranchModal/AddBranchModal";
import { NodeCardSideAction } from "common/components/NodeCardSideAction";
import { Tooltip } from "common/components/Tooltip";

interface MainNodeAddBranchUIProps {
  choices: NodeDataChildChoice[];
  nodeId: string;
}

export const MainNodeAddBranchUI = ({
  choices,
  nodeId,
}: MainNodeAddBranchUIProps) => {
  const { addBranchedNode } = useCanvasFunctions();

  const [openModal, setOpenModal] = useState(false);

  const createNewBranch = (choice: NodeDataChildChoice) => {
    addBranchedNode(choice, nodeId);
    setOpenModal(false);
  };

  return (
    <div>
      <NodeCardSideAction position="right">
        <NodeCardSideAction.Wrapper>
          <Tooltip>
            <Tooltip.Trigger as="div">
              <NodeCardSideAction.Button
                aria-label="New Branched Step"
                onClick={() => setOpenModal(true)}
              >
                <FontAwesomeIcon fixedWidth icon={solid("plus")} />
              </NodeCardSideAction.Button>
            </Tooltip.Trigger>

            <Tooltip.Label label="New Branched Step" />
          </Tooltip>
        </NodeCardSideAction.Wrapper>
      </NodeCardSideAction>

      {openModal && (
        <AddBranchModal
          isOpen={openModal}
          onSubmit={createNewBranch}
          onClose={setOpenModal}
          choices={choices}
        />
      )}
    </div>
  );
};
