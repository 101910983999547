import { useRef, useState } from "react";
import {
  useFloating,
  useInteractions,
  useRole,
  useDismiss,
  useFocus,
  useHover,
  offset,
  arrow,
} from "@floating-ui/react-dom-interactions";
import { FaPlus } from "react-icons/fa";

import { NodeCardSideAction } from "common/components/NodeCardSideAction";

import "./OutcomeNodeAddStep.scss";

interface OutcomeNodeAddStepUIProps {
  handleAddStepAction?: () => void;
}

export const OutcomeNodeAddStepUI = ({
  handleAddStepAction,
}: OutcomeNodeAddStepUIProps) => {
  const [open, setOpen] = useState(false);

  const arrowRef = useRef(null);

  const { x, y, reference, floating, strategy, context } = useFloating({
    placement: "right",
    open,
    onOpenChange: setOpen,
    middleware: [
      offset(12),
      arrow({
        element: arrowRef,
      }),
    ],
  });

  const { getReferenceProps, getFloatingProps } = useInteractions([
    useHover(context),
    useFocus(context),
    useRole(context, { role: "tooltip" }),
    useDismiss(context),
  ]);

  return (
    <NodeCardSideAction position="bottom">
      <NodeCardSideAction.Wrapper
        {...getReferenceProps({
          ref: reference,
        })}
      >
        <NodeCardSideAction.Button
          type="button"
          aria-label="Add Outcome"
          onClick={handleAddStepAction}
        >
          <FaPlus />
        </NodeCardSideAction.Button>
        {open && (
          <div
            {...getFloatingProps({
              className: "outcome-node-add-step__tooltip",
              ref: floating,
              style: {
                position: strategy,
                top: y ?? 0,
                left: x ?? 0,
              },
            })}
          >
            <div>Add Outcome</div>
            <div ref={arrowRef} className="arrow" />
          </div>
        )}
      </NodeCardSideAction.Wrapper>
    </NodeCardSideAction>
  );
};
