import { useEffect } from "react";

import {
  CanvasModal,
  CanvasModalHeader,
} from "common/components/CanvasModal/CanvasModal";

import "./OutcomeModal.scss";

import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useModalStore } from "../Modals";
import { OUT_COMES_PAGE, useOutComesStore } from "../OutcomeNode";

interface OutcomeModalProps {
  isOpen?: boolean | undefined;
  onClose(value: boolean): void;
}

export const OutcomeModal = ({ isOpen, onClose }: OutcomeModalProps) => {
  const { setShouldShowAnimation } = useModalStore();
  useEffect(() => {
    if (isOpen) setTimeout(() => setShouldShowAnimation(false), 500);
  }, [isOpen, setShouldShowAnimation]);

  const { setPage, setOutcomesType } = useOutComesStore();

  return (
    <>
      <CanvasModal
        isClosableOnBlur
        isOpen={isOpen}
        onClose={(s) => {
          setOutcomesType("multiple");
          onClose(s);
        }}
        className="new-interface-modal"
      >
        <CanvasModalHeader>
          <div className="outcome-modal__header">
            <h2 className="new-interface-modal__heading">Choose Outcome/s</h2>
          </div>
        </CanvasModalHeader>
        <div className="outcome-modal__body">
          <div className="outcome-modal__content">
            <button
              onClick={() => {
                setOutcomesType("multiple");
                setPage(OUT_COMES_PAGE.MULTIPLE_OUTCOME_LIST);
              }}
              type="button"
              className={`add-branch-modal__choice-btn`}
            >
              <div className="outcome-modal__content-wrapper">
                <div className="outcome-modal__icon-wrapper">
                  <FontAwesomeIcon
                    fixedWidth
                    size="1x"
                    icon={solid("arrow-right-arrow-left")}
                  />
                </div>
                <div className="outcome-modal__text-content-wrapper">
                  <h2>Multiple Outcomes</h2>
                  <p>Send people to different places</p>
                </div>
              </div>
            </button>

            <button
              onClick={() => {
                setOutcomesType("single");
                setPage(OUT_COMES_PAGE.SINGLE_OUTCOME_LIST);
              }}
              type="button"
              className={`add-branch-modal__choice-btn`}
            >
              <div className="outcome-modal__content-wrapper">
                <div className="outcome-modal__icon-wrapper">
                  <FontAwesomeIcon fixedWidth icon={solid("arrow-right")} />
                </div>
                <div className="outcome-modal__text-content-wrapper">
                  <h2>Single Outcome</h2>
                  <p>Send people to the same place</p>
                </div>
              </div>
            </button>
          </div>
        </div>
      </CanvasModal>
    </>
  );
};
