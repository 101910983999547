import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import "../MainNode.scss";
interface Props {
  inputClass?: string;
  type?: string;
}

export const MainNodeAutoAdvance: React.FC<Props> = () => {
  return (
    <div className="main-node-flow__auto-advance">
      <div className="main-node-flow__auto-advance-icon">
        <FontAwesomeIcon fixedWidth icon={solid("hourglass-end")} />
      </div>

      <p>
        User input disabled. <br /> Step auto-advances after video.{" "}
      </p>
    </div>
  );
};
