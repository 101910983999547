import { useTooltipStore } from "common/components/TooltipMenu";
import { useCanvasStateStore } from "pages/Canvas";
import { useCallback, useEffect, useRef } from "react";
import ReactFlow, {
  Controls,
  useEdgesState,
  useNodesState,
} from "react-flow-renderer";
import { SingleOutcomeType, useOutComesStore } from "./components/OutcomeNode";
import { editData } from "./data/api/editData.api";
import { edgeTypes, nodeTypes } from "./data/constants";
import { initialEdges, initialNodes } from "./data/initialData";
import { SavingState } from "./data/types";

function Canvas() {
  const [nodes, , onNodesChange] = useNodesState(initialNodes);
  const [edges, , onEdgesChange] = useEdgesState(initialEdges);

  const controller = useRef<AbortController | null>();

  const { setIsPaneMoved } = useTooltipStore();
  const {
    flowId,
    flowName,
    savingState,
    isRetrying,
    setSavingState,
    setRetries,
    setIsRetrying,
    setShowErrorModal,
  } = useCanvasStateStore();
  const { singleOutcome, multipleOutComes, currentOutcomeType } =
    useOutComesStore();
  const isRetryingNow = useRef(false);
  const retries = useRef(0);

  useEffect(() =>
    useCanvasStateStore.subscribe((state) => {
      isRetryingNow.current = state.isRetrying;
    })
  );

  const retrySave = useCallback(() => {
    let id = setInterval(() => {
      setSavingState(SavingState.SAVING);
      if (!flowId || !flowName || isRetryingNow.current) return;
      if (retries.current >= 3) {
        clearInterval(id);
        setShowErrorModal(true);
        return;
      }
      return editData(
        nodes,
        edges,
        flowId,
        flowName,
        currentOutcomeType === "single"
          ? singleOutcome ?? ({} as SingleOutcomeType)
          : multipleOutComes ?? []
      )
        .then((res) => {
          setRetries(0);
          setSavingState(SavingState.SUCCESS);
          clearTimeout(id);
          setTimeout(() => {
            setSavingState(SavingState.IDLE);
            setIsRetrying(false);
          }, 5 * 1000);
        })
        .catch((e) => {
          retries.current += 1;
          setSavingState(SavingState.ERROR);
        });
    }, 3000);
  }, [
    currentOutcomeType,
    edges,
    flowId,
    flowName,
    multipleOutComes,
    nodes,
    setIsRetrying,
    setRetries,
    setSavingState,
    singleOutcome,
  ]);

  useEffect(() => {
    if (controller.current) controller.current.abort();
    if (!flowId || !flowName || isRetrying) return;
    let anotherId: NodeJS.Timeout;
    let id = setTimeout(() => {
      setSavingState(SavingState.SAVING);
      editData(
        nodes,
        edges,
        flowId,
        flowName,
        currentOutcomeType === "single"
          ? singleOutcome ?? ({} as SingleOutcomeType)
          : multipleOutComes ?? []
      )
        .then(() => {
          controller.current = null;
          setSavingState(SavingState.SUCCESS);
          anotherId = setTimeout(
            () => setSavingState(SavingState.IDLE),
            5 * 1000
          );
        })
        .catch((e) => {
          setSavingState(SavingState.ERROR);
          retrySave();
        });
    }, 1000);

    return () => {
      clearTimeout(id);
      clearTimeout(anotherId);
    };
  }, [
    currentOutcomeType,
    edges,
    flowId,
    flowName,
    isRetrying,
    multipleOutComes,
    nodes,
    retries,
    retrySave,
    setIsRetrying,
    setRetries,
    setSavingState,
    singleOutcome,
  ]);

  const onMove = useCallback(() => {
    console.log("MOVING");
    setIsPaneMoved(true);
  }, [setIsPaneMoved]);

  const onMoveEnd = useCallback(() => {
    setIsPaneMoved(false);
  }, [setIsPaneMoved]);

  const proOptions = { hideAttribution: true, account: "" };

  return (
    <ReactFlow
      edgeTypes={edgeTypes}
      nodeTypes={nodeTypes}
      nodes={nodes}
      edges={edges}
      onNodesChange={onNodesChange!}
      onEdgesChange={onEdgesChange!}
      fitView
      nodesDraggable={false}
      minZoom={0.1}
      panOnScroll
      onMoveStart={onMove}
      onMoveEnd={onMoveEnd}
      proOptions={{ hideAttribution: true, account: "" }}
      selectionKeyCode={null}
      deleteKeyCode={null}
      multiSelectionKeyCode={null}
      // onPaneClick={onMove}
      // onPaneScroll={onMove}
    >
      {/* <MiniMap /> */}
      {/* <Controls showInteractive={false} /> */}
    </ReactFlow>
  );
}

export default Canvas;
