import { useState } from "react";

import {
  CanvasModal,
  CanvasModalHeader,
} from "common/components/CanvasModal/CanvasModal";

import "./NameYourVideoModal.scss";
import { InputTextField } from "common/components/InputTextField/InputTextField";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { useVideoModalStore, VIDEO_MODAL_PAGES } from "../MainNode/MainNode";

interface NameYourVideoModalProps {
  isOpen?: boolean | undefined;
  onClose(value: boolean): void;
}

export const NameYourVideoModal = ({
  isOpen,
  onClose,
}: NameYourVideoModalProps) => {
  const [newName, setNewName] = useState<string>("");
  const { setName, setPage } = useVideoModalStore();
  return (
    <>
      <CanvasModal
        isOpen={isOpen}
        onClose={onClose}
        className="name-your-video-modal"
      >
        <CanvasModalHeader>
          <div className="name-your-video-modal__header">
            {/* <button className="name-your-video-modal__back-btn">
              <FontAwesomeIcon icon={solid("caret-left")} />
              BACK
            </button> */}
            <h2 className="name-your-video-modal__heading">Name Your Video</h2>
            <div></div>
          </div>
        </CanvasModalHeader>
        <div className="name-your-video-modal__body">
          <div className="name-your-video-modal__content">
            <InputTextField
              value={newName}
              onChange={(e) => setNewName(e.target.value)}
              label="Video Name"
              placeholder="Untitled"
            />
          </div>
        </div>

        <div className="name-your-video-modal__footer">
          {!!newName && (
            <button
              type="button"
              onClick={() => {
                setName(newName);
                setPage(VIDEO_MODAL_PAGES.UPLOAD_PROGRESS);
              }}
              className="name-your-video-modal__continue-btn"
            >
              Save
              <span>
                <FontAwesomeIcon fixedWidth icon={solid("right-long")} />
              </span>
            </button>
          )}
        </div>
      </CanvasModal>
    </>
  );
};
