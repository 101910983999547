import { NodeCard } from "common/components/NodeCard";
import { OutcomeModal } from "../OutcomeModal/OutcomeModal";
import { OutcomeNodeAddStepUI } from "./ui/OutcomeNodeAddStep.ui";
import { OUT_COMES_PAGE, useOutComesStore } from ".";
import { MultipleOutcomeModal } from "../MultipleOutcomeModal/MultipleOutcomeModal";
import { MapOutcomeAnswerModal } from "../MapOutcomeAnswerModal/MapOutcomeAnswerModal";
import { SingleOutcomeModal } from "../SingleOutcomeModal/SingleOutcomeModal";
import { NewOutcomeModal } from "../NewOutcomeModal/NewOutcomeModal";
import { ChangeWeightingModal } from "../ChangeWeightingModal/ChangeWeightingModal";

import "./OutcomeNode.scss";
import { MultipleOutcomeNodeSettings } from "./ui/MultipleOutcomeNodeSetting";
import { useModalStore } from "../Modals";
import useCanvasFunctions from "modules/canvas/useCanvasFunctions";
interface OutcomeNodeUIProps {
  data: any;
  selected: boolean;
}

export const OutcomeNodeUI = ({ data, selected }: OutcomeNodeUIProps) => {
  const { page, setPage, getSelectedOutcomesArr } = useOutComesStore();
  const { setShouldShowAnimation } = useModalStore();
  const hasOutcome = !!getSelectedOutcomesArr().length;
  const { buildOutcomeNodes } = useCanvasFunctions();
  const close = () => {
    buildOutcomeNodes(getSelectedOutcomesArr());
    setPage(OUT_COMES_PAGE.NONE);
    setShouldShowAnimation(true);
  };
  return (
    <>
      <ChangeWeightingModal
        isOpen={page === OUT_COMES_PAGE.CHANGE_WEIGHTING}
        onClose={close}
      />
      <OutcomeModal isOpen={page === OUT_COMES_PAGE.OUTCOMES} onClose={close} />
      <NewOutcomeModal
        isOpen={page === OUT_COMES_PAGE.ADD_NEW_OUTCOME}
        onClose={close}
      />
      <MultipleOutcomeModal
        isOpen={page === OUT_COMES_PAGE.MULTIPLE_OUTCOME_LIST}
        onClose={close}
      />
      <SingleOutcomeModal
        isOpen={page === OUT_COMES_PAGE.SINGLE_OUTCOME_LIST}
        onClose={close}
      />
      <MapOutcomeAnswerModal
        isOpen={page === OUT_COMES_PAGE.MAP_ANS_TO_OUTCOME}
        onClose={close}
      />
      <div className="outcome-node">
        <NodeCard className="outcome-node__card">
          <div className="outcome-node__body">
            <div className="outcome-node__label">Outcome/s</div>
          </div>
          {hasOutcome ? (
            <div className="outcome-card-side-action">
              {selected && hasOutcome && <MultipleOutcomeNodeSettings />}
            </div>
          ) : (
            <div>
              {selected && !hasOutcome && (
                <OutcomeNodeAddStepUI
                  handleAddStepAction={() => setPage(OUT_COMES_PAGE.OUTCOMES)}
                />
              )}
            </div>
          )}
        </NodeCard>
      </div>
    </>
  );
};
