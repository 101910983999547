import { useContext, useEffect, useState } from "react";
import { useStore } from "zustand";

import { MainNodeSettingsContext } from "./MainNodeSettings.ui";
import { NODE_SETTINGS_PAGE } from "./MainNodeSettingStore";
import useCanvasFunctions from "modules/canvas/useCanvasFunctions";
import { currentNodeContext } from "modules/canvas/currentNodeContext";
import { TooltipMenu } from "common/components/TooltipMenu";
import { InputTextField } from "common/components/InputTextField/InputTextField";
import { prevValClamp } from "modules/canvas/utils/math";
import { useOutComesStore } from "modules/canvas/components/OutcomeNode";

export const MainNodeSettingsDelay = () => {
  const { store } = useContext(MainNodeSettingsContext);
  const { setCurrentPage } = useStore(store);
  const { saveNodeData } = useCanvasFunctions();
  const { id, data } = useContext(currentNodeContext);
  const { editSingleOutcome, editMultipleOutCome, currentOutcomeType } =
    useOutComesStore();

  const [input, setInput] = useState(0);

  useEffect(() => setInput(data.delay ?? 0), [data.delay]);

  const handleSave = () => {
    if (data.isOutcomeNode) {
      const newData = { delay: input };
      if (currentOutcomeType === "single") editSingleOutcome(newData);
      else editMultipleOutCome(data.rootNodeId, newData);
    }
    saveNodeData({ delay: input }, id);
    setCurrentPage(NODE_SETTINGS_PAGE.HOME);
  };

  return (
    <>
      <TooltipMenu.Header>
        <TooltipMenu.BackNav
          onClick={() => setCurrentPage(NODE_SETTINGS_PAGE.HOME)}
        />
        <span>Delay Answers</span>
      </TooltipMenu.Header>
      <TooltipMenu.Body>
        <p>Enter the % of video when answer shows</p>
        <br />
        <InputTextField
          variant="small"
          min="0"
          max="100"
          value={input.toString()}
          onChange={(e) =>
            setInput((prev) =>
              prevValClamp(parseFloat(e.target.value), prev, 0, 100)
            )
          }
          type="number"
        />
      </TooltipMenu.Body>
      <TooltipMenu.Footer>
        <TooltipMenu.ActionButton onClick={handleSave}>
          Save
        </TooltipMenu.ActionButton>
      </TooltipMenu.Footer>
    </>
  );
};
