import React, { useEffect, useState } from "react";
import "./index.scss";
const styles = {
  startStage: { transition: "all 500ms linear" },
  exit: { transition: "all 500ms linear", opacity: 0 },
};

function Loader({ isShown }: { isShown: boolean }) {
  const [shouldRender, setShouldRender] = useState(false);

  useEffect(() => {
    const img = new Image();
    img.onload = () => setShouldRender(true);
    img.src = "/loading-screen-logo.png";
  }, []);

  return (
    <>
      <div
        className="loader"
        style={{
          ...(!isShown ? styles.exit : styles.startStage),
        }}
      >
        <div
          style={{
            visibility: shouldRender ? "visible" : "hidden",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div>
              <div
                style={{
                  width: "130px",
                  height: "130px",
                  margin: "0 auto",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  color: "white",
                }}
              >
                <svg
                  aria-hidden="true"
                  focusable="false"
                  data-prefix="fad"
                  data-icon="spinner-third"
                  className="svg-inline--fa fa-spinner-third fa-5x fa-spin"
                  role="img"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 512 512"
                >
                  <g className="fa-duotone-group">
                    <path
                      style={{ opacity: 0.1 }}
                      className="fa-secondary"
                      fill="currentColor"
                      d="M493.7 350.5C456 444.9 363.7 512 256 512c-141.2 0-256-114.8-256-256s114.8-256 256-256c11.91 0 23.54 1.104 35.03 2.686C272.4 .8672 256 15.62 256 34.52c0 15.8 11.58 29.17 27.23 31.4c.9648 .1367 1.844 .5116 2.805 .6639C276.2 65.04 266.2 64 256 64C150.1 64 64 150.1 64 256s86.13 192 192 192c81.67 0 151.4-51.34 179.1-123.4c-.248 .6406-.4215 1.317-.6754 1.954c-5.869 14.7 .0045 31.38 13.75 39.23C464.8 375.3 486.5 368.2 493.7 350.5z"
                    ></path>
                    <path
                      className="fa-primary"
                      fill="currentColor"
                      d="M283.2 65.92C267.6 63.69 256 50.32 256 34.52c0-19.38 17.19-34.69 36.38-31.95C416.4 20.29 512 127.2 512 256c0 33.38-6.544 65.26-18.27 94.49c-7.162 17.86-28.85 24.87-45.56 15.32c-13.74-7.854-19.62-24.53-13.75-39.23C443.2 304.7 448 280.9 448 256C448 159.4 376.3 79.18 283.2 65.92z"
                    ></path>
                  </g>
                </svg>
              </div>

              <img
                style={{ maxWidth: "50vw", width: "220px", margin: "0 auto" }}
                src="/loading-screen-logo.png"
                alt=""
              />
              <h2
                style={{
                  textTransform: "uppercase",
                  color: "#929292",
                  textAlign: "center",
                  marginTop: "3em",
                  fontSize: "1em",
                  letterSpacing: "4px",
                  fontFamily: `"Roboto", sans-serif`,
                }}
              >
                LOADING INTERFACE FLOW BUILDER
              </h2>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Loader;
