import _ from "lodash";
import { useCanvasStateStore } from "pages/Canvas";
import { useCallback, useEffect } from "react";
import { useReactFlow } from "react-flow-renderer";
import {
  IOutcome,
  SingleOutcomeType,
  useOutComesStore,
} from "../components/OutcomeNode";
import useCanvasFunctions from "../useCanvasFunctions";
import { getData } from "./api/getData.api";
import { SERVER_URL } from "./env";

export const Setup = () => {
  const {
    setIsLoadingFlow,
    setIsEditing,
    setFlowId,
    setFlowName,
    isLoaderShown,
    setIsLoaderShown,
  } = useCanvasStateStore(
    ({
      setIsLoadingFlow,
      setIsEditing,
      setFlowId,
      setFlowName,
      setIsLoaderShown,
      isLoaderShown,
    }) => ({
      setIsLoadingFlow,
      setIsEditing,
      setFlowName,
      setFlowId,
      setIsLoaderShown,
      isLoaderShown,
    })
  );
  const { setNodes, setEdges } = useReactFlow();
  const { buildOutcomeNodes } = useCanvasFunctions();
  const { setMultipleOutcomes, setOutcomesType, setSingleOutcome } =
    useOutComesStore(
      ({ setMultipleOutcomes, setOutcomesType, setSingleOutcome }) => ({
        setMultipleOutcomes,
        setOutcomesType,
        setSingleOutcome,
      })
    );

  const hideLoader = useCallback(() => {
    setTimeout(() => setIsLoaderShown(false), 1050);
  }, [setIsLoaderShown]);

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const flowId = searchParams.get("flow-id");
    if (!flowId) {
      setIsLoadingFlow(false);
      hideLoader();
      return;
    }

    // setIsLoadingFlow(true);
    getData(flowId).then((data) => {
      // alert("Got data");
      if (data.status === 401 || data.status === 417) {
        window.open(`${SERVER_URL}/login`, "_self");
        return;
      }
      console.log(data);
      setNodes(data.data.nodes);
      setEdges(data.data.edges as any);
      setFlowName(data.data.title);
      if (Array.isArray(data.data.outcome)) {
        setMultipleOutcomes(
          (data.data.outcome as any).map((el: any) =>
            _.defaultsDeep(el, {
              answers: [],
              id: "",
              label: "",
              type: "flow",
              nodeData: {
                ctaLabels: [],
                id: "",
                thumbnail: "",
                title: "",
                video: "",
                videoID: "",
              },
              delay: 0,
              redirectURL: "",
              isWebinarMode: false,
            } as IOutcome)
          )
        );
        setOutcomesType("multiple");
      } else {
        setOutcomesType("single");
        setSingleOutcome(
          _.defaultsDeep(
            data.data.outcome as any,
            {
              label: "",
              redirectURL: "",
              delay: 0,
              id: "",
              type: "flow",
              nodeData: {
                ctaLabels: [],
                id: "",
                thumbnail: "",
                title: "",
                video: "",
                videoID: "",
              },
              isWebinarMode: false,
            } as SingleOutcomeType
          )
        );
      }
      setIsLoadingFlow(false);
      setIsEditing(true);
      setFlowId(flowId);
      hideLoader();

      setTimeout(() => {
        buildOutcomeNodes(
          (Array.isArray(data.data.outcome)
            ? data.data.outcome
            : [data.data.outcome]) as any
        );
      }, 1000);
    });
  }, [
    buildOutcomeNodes,
    hideLoader,
    setEdges,
    setFlowId,
    setFlowName,
    setIsEditing,
    setIsLoadingFlow,
    setMultipleOutcomes,
    setNodes,
    setOutcomesType,
    setSingleOutcome,
  ]);
  return <></>;
};
