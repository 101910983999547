import { useEffect, useMemo, useState } from "react";

import {
  CanvasModal,
  CanvasModalHeader,
} from "common/components/CanvasModal/CanvasModal";

import { InputTextField } from "common/components/InputTextField/InputTextField";
import "./ChangeWeightingModal.scss";

import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useMapOutcomeStore } from "../MapOutcomeAnswerModal/MapOutcomeAnswerModalStore";
import { OUT_COMES_PAGE, useOutComesStore } from "../OutcomeNode";

interface ChangeWeightingModalProps {
  isOpen?: boolean | undefined;
  onClose(value: boolean): void;
}

export const ChangeWeightingModal = ({
  isOpen,
  onClose,
}: ChangeWeightingModalProps) => {
  const { setPage, changeWeighting, selectedOutcomeId, multipleOutComes } =
    useOutComesStore();
  const { currentSelectedAnsId, currentSelectedQuestionId } =
    useMapOutcomeStore();
  const currAnswer = multipleOutComes // useMemo(() => {
    ?.find((outcome) => outcome.id === selectedOutcomeId)
    ?.answers.find((ans) => ans.choiceId === currentSelectedAnsId);
  // }, [currentSelectedAnsId, multipleOutComes, selectedOutcomeId]);
  const [newName, setNewName] = useState(currAnswer?.weight ?? "1.00");
  const save = () => {
    if (currentSelectedAnsId) {
      console.log(currentSelectedAnsId, currentSelectedQuestionId, newName);
      changeWeighting(currentSelectedAnsId, selectedOutcomeId ?? "", newName);
      setNewName("1.00");
      setPage(OUT_COMES_PAGE.MAP_ANS_TO_OUTCOME);
      console.log(currentSelectedQuestionId);
    } else {
      alert(currentSelectedAnsId + " " + currentSelectedQuestionId);
    }
  };
  useEffect(() => {
    setNewName(currAnswer?.weight ?? "1.00");
  }, [currAnswer, isOpen]);

  return (
    <>
      <CanvasModal
        isClosableOnBlur
        isOpen={isOpen}
        onClose={onClose}
        className="change-weighting-modal"
      >
        <CanvasModalHeader>
          <div className="change-weighting-modal__header">
            <h2 className="change-weighting-modal__heading">
              Change Weighting
            </h2>
          </div>
        </CanvasModalHeader>
        <div className="change-weighting-modal__body">
          <div className="change-weighting-modal__content">
            <InputTextField
              value={newName.toString()}
              type="number"
              onChange={
                (e) => {
                  const val = e.target.value ?? "1.00";
                  // if (isNaN(parseFloat(val)) || val.split(".").length > 2) return;
                  setNewName(val);
                }
                // setNewName(parseFloat(e.target.value ?? "1.00").toLocaleString("en-US", { minimumFractionDigits: 2 }))
              }
              label="Weighting"
            />
          </div>
        </div>

        <div className="change-weighting-modal__footer">
          {/* {!!newName && ( */}
          <button
            onClick={() => setPage(OUT_COMES_PAGE.MAP_ANS_TO_OUTCOME)}
            type="button"
            className="change-weighting-modal__continue-btn"
          >
            Cancel
          </button>
          <button
            type="button"
            className="change-weighting-modal__continue-btn"
            onClick={save}
          >
            Save
            <FontAwesomeIcon fixedWidth icon={solid("right-long")} />
          </button>
          {/* )} */}
        </div>
      </CanvasModal>
    </>
  );
};
