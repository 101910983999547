import { Edge, Node } from "react-flow-renderer";
import { IOutcome, SingleOutcomeType } from "../components/OutcomeNode";
import { EdgeData, NodeData } from "./types";
import { SERVER_URL } from "./env";

export const API_URL = `${SERVER_URL}/api`;

export const saveData = async (
  nodes: Node<NodeData>[],
  edges: Edge<EdgeData>[],
  outcomes: IOutcome[] | SingleOutcomeType,
  title = "Untitled"
) => {
  const formdata = new FormData();
  // formdata.append("token", token);
  formdata.append("title", title);
  formdata.append("nodes", JSON.stringify(nodes));
  formdata.append("edges", JSON.stringify(edges));
  formdata.append("outcome", JSON.stringify(outcomes));
  return fetch(`${API_URL}/create-survey`, {
    method: "POST",
    headers: {
      Accept: "application/json",
    },
    credentials: "include",
    body: formdata,
  })
    .then((res) => {
      if (res.ok) return res.json();
      throw new Error("Not ok");
    })
    .then((data) => {
      // alert("Success! Quiz id: " + data.data.uuid);
      return data;
    });
};
