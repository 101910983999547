import { createContext, PropsWithChildren } from "react";
import { NodeProps } from "react-flow-renderer";
import { NodeData } from "./data/types";

type CurrentNodeContextType = NodeProps<NodeData>;
export const currentNodeContext = createContext<CurrentNodeContextType>(
  {} as CurrentNodeContextType
);

export const CurrentNodeContextProvider: React.FC<
  PropsWithChildren<{ node: CurrentNodeContextType }>
> = ({ children, node }) => {
  return (
    <currentNodeContext.Provider value={node}>
      {children}
    </currentNodeContext.Provider>
  );
};
