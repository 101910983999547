import { useDraggable } from "@dnd-kit/core";
import classNames from "classnames";

import useCanvasFunctions from "modules/canvas/useCanvasFunctions";
import { Question } from "./MapOutcomeAnswerModalQuestionsList";
import { useMapOutcomeStore } from "./MapOutcomeAnswerModalStore";
import { isChoiceSelected } from "modules/canvas/utils/isChoiceSelected";

export const MapOutcomeAnswerModalAnswersList = () => {
  // const data = tempData; // should be data from store
  const { getCurrentQuestion, currentSelectedQuestionId } =
    useMapOutcomeStore();
  const answers = getCurrentQuestion()?.answers;
  console.log({ answers, currentSelectedQuestionId });
  return (
    <ul className="map-outcome-modal__answer-list">
      {answers?.map((item, i) => {
        return (
          <MapOutcomeAnswerModalAnswerItem
            key={item.choiceId}
            data={{ ...item, index: i }}
          />
        );
      })}
    </ul>
  );
};

interface MapOutcomeAnswerModalAnswerItemProps {
  data: Question["answers"][0] & { index?: number };
}

const badgeColors = ["blue", "green", "teal", "purple"];

const MapOutcomeAnswerModalAnswerItem = ({
  data,
}: MapOutcomeAnswerModalAnswerItemProps) => {
  const { openedAnswer } = useMapOutcomeStore();
  const { attributes, listeners, setNodeRef, isDragging } = useDraggable({
    id: data.choiceId,
    data: {
      type: "answer-item",
      ...data,
    },
  });
  const { getNode } = useCanvasFunctions();
  const currQuestion = getNode(data.questionId);
  const label = isChoiceSelected(currQuestion)
    ? currQuestion.data.children.choices?.find((c) => c.id === data.choiceId)
        ?.value ?? ""
    : "";

  return (
    <li
      ref={setNodeRef}
      className={classNames("map-outcome-modal__answer-item", {
        "map-outcome-modal__answer-item--dragging": isDragging,
        highlighted: openedAnswer === data.choiceId,
      })}
      {...listeners}
      {...attributes}
    >
      <div
        className={`map-outcome-modal__answer-badge ${
          badgeColors[(data.index ?? 0) % badgeColors.length]
        }`}
      >
        {label[0].toUpperCase()}
      </div>
      <div className="map-outcome-modal__answer-label">{label}</div>
    </li>
  );
};

export const MapOutcomeAnswerModalAnswerItemOverlay = ({
  data,
}: MapOutcomeAnswerModalAnswerItemProps) => {
  const { getNode } = useCanvasFunctions();
  const currQuestion = getNode(data.questionId);
  const label = isChoiceSelected(currQuestion)
    ? currQuestion.data.children.choices?.find((c) => c.id === data.choiceId)
        ?.value ?? ""
    : "";
  console.log({ data }, data.index);
  return (
    <li
      className={`map-outcome-modal__answer-item map-outcome-modal__answer-item--drag-item`}
    >
      <div
        className={`map-outcome-modal__answer-badge ${
          badgeColors[(data.index ?? 0) % badgeColors.length]
        }`}
      >
        {label?.[0]?.toUpperCase() ?? "A"}
      </div>
      <div className="map-outcome-modal__answer-label">{label}</div>
    </li>
  );
};
