import { createContext, ElementType, useContext, useRef } from "react";
import { useControlledState } from "@react-stately/utils";
import {
  useFloating,
  useInteractions,
  useRole,
  useDismiss,
  useFocus,
  useHover,
  offset,
  arrow,
  UseFloatingReturn,
  autoUpdate,
  ReferenceType,
} from "@floating-ui/react-dom-interactions";

import "./Tooltip.scss";

interface ToolTipContextType {
  open: boolean | undefined;
  setOpen: (value: boolean) => void;
  arrowRef: React.MutableRefObject<HTMLDivElement | null>;
  tooltipFloating: UseFloatingReturn<ReferenceType>;
  getReferenceProps: (
    userProps?: React.HTMLProps<Element> | undefined
  ) => Record<string, unknown>;
  getFloatingProps: (
    userProps?: React.HTMLProps<HTMLElement> | undefined
  ) => Record<string, unknown>;
}

const TooltipContext = createContext({} as ToolTipContextType);

interface TooltipMenuProviderProps {
  children: React.ReactNode;
  open?: boolean;
  defaultOpen?: boolean;
  onOpenChange?: (value: boolean) => void;
}

const TooltipMenuProvider = ({
  children,
  open: openProps,
  defaultOpen = false,
  onOpenChange,
}: TooltipMenuProviderProps) => {
  const [open, setOpen] = useControlledState(openProps, defaultOpen, (open) => {
    onOpenChange?.(!!open);
  });

  const arrowRef = useRef<HTMLDivElement | null>(null);

  const tooltipFloating = useFloating({
    whileElementsMounted: autoUpdate,
    placement: "right",
    open,
    onOpenChange: setOpen,
    middleware: [
      offset({ mainAxis: 15, crossAxis: 0, alignmentAxis: 0 }),
      arrow({
        element: arrowRef,
      }),
    ],
  });

  const { context } = tooltipFloating;

  const { getReferenceProps, getFloatingProps } = useInteractions([
    useHover(context),
    useFocus(context),
    useRole(context, { role: "tooltip" }),
    useDismiss(context),
  ]);

  return (
    <TooltipContext.Provider
      value={{
        open,
        setOpen,
        arrowRef,
        tooltipFloating,
        getReferenceProps,
        getFloatingProps,
      }}
    >
      {children}
    </TooltipContext.Provider>
  );
};

/* ----------------------------------------------------------------------------
 * TooltipTrigger
 * ---------------------------------------------------------------------------*/

interface TooltipTriggerProps<T extends React.ElementType> {
  children?: React.ReactNode;
  as?: T;
}

let ToolTipTriggerTag = "button" as const;

const TooltipTrigger = <T extends ElementType = typeof ToolTipTriggerTag>({
  children,
  as,
  ...props
}: TooltipTriggerProps<T>) => {
  const Component = as || ToolTipTriggerTag;
  const { tooltipFloating, getReferenceProps } = useContext(TooltipContext);
  const { reference } = tooltipFloating;

  return (
    <Component
      {...getReferenceProps({
        ref: reference,
        ...props,
      })}
    >
      {children}
    </Component>
  );
};

/* ----------------------------------------------------------------------------
 * TooltipLabel
 * ---------------------------------------------------------------------------*/

interface TooltipLabelProps {
  label: string;
}

const TooltipLabel = ({ label }: TooltipLabelProps) => {
  const { tooltipFloating, arrowRef, open, getFloatingProps } =
    useContext(TooltipContext);
  const { x, y, floating, strategy } = tooltipFloating;

  return open ? (
    <div
      className="tooltip"
      {...getFloatingProps({
        className: "tooltip__wrapper",
        ref: floating,
        style: {
          position: strategy,
          top: y ?? 0,
          left: x ?? 0,
        },
      })}
    >
      <div className="tooltip__label">{label}</div>
      <div ref={arrowRef} className="tooltip__arrow" />
    </div>
  ) : (
    <></>
  );
};

export let Tooltip = Object.assign(TooltipMenuProvider, {
  Trigger: TooltipTrigger,
  Label: TooltipLabel,
});
