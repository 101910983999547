import "./NodeCard.scss";

interface NodeCardProps {
  children?: React.ReactNode;
  className?: string;
}

export const NodeCard = ({ className, children }: NodeCardProps) => {
  return <div className={`node-card ${className}`}>{children}</div>;
};
