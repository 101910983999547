import React from "react";
import { AddVideoModal } from "./AddVideoModal/AddVideoModal";
import { useVideoModalStore, VIDEO_MODAL_PAGES } from "./MainNode/MainNode";
import { NameYourVideoModal } from "./NameYourVideoModal/NameYourVideoModal";
import { UploadingYourVideoModal } from "./UploadingYourVideoModal/UploadingYourVideoModal";
import { UploadVideoModal } from "./UploadVideoModal/UploadVideoModal";
import { VideoPermissionsModal } from "./VideoPermissionsModal/VideoPermissionsModal";
import { VideoScreenRecordingModal } from "./VideoScreenRecordingModal/VideoScreenRecordingModal";

import create from "zustand";
import { devtools } from "zustand/middleware";
import ErrorSavingModal from "./ErrorSavingModal/ErrorSavingModal";
import { useCanvasStateStore } from "pages/Canvas";
type IUseModalStore = {
  shouldShowAnimation: boolean;
  setShouldShowAnimation: (s: boolean) => void;
};
export const useModalStore = create<
  IUseModalStore,
  [["zustand/devtools", IUseModalStore]]
>(
  devtools(
    (set) => ({
      shouldShowAnimation: true,
      setShouldShowAnimation: (s: boolean) =>
        set((state: any) => ({ ...state, shouldShowAnimation: s })),
    }),
    { name: "Modal" }
  )
);
function Modals() {
  const { page, setPage } = useVideoModalStore();
  const { setShouldShowAnimation } = useModalStore();
  const { showErrorModal, setShowErrorModal } = useCanvasStateStore();
  const close = () => {
    setPage(VIDEO_MODAL_PAGES.NONE);
    setShouldShowAnimation(true);
  };

  return (
    <>
      {page !== VIDEO_MODAL_PAGES.NONE && (
        <>
          <AddVideoModal
            isOpen={page === VIDEO_MODAL_PAGES.ADD_VIDEO}
            onClose={close}
          />
          <UploadVideoModal
            isOpen={page === VIDEO_MODAL_PAGES.UPLOAD_VIDEO}
            onClose={close}
          />
          {page === VIDEO_MODAL_PAGES.UPLOAD_PROGRESS && (
            <UploadingYourVideoModal
              isOpen={page === VIDEO_MODAL_PAGES.UPLOAD_PROGRESS}
              onClose={close}
            />
          )}
          <VideoPermissionsModal
            isOpen={page === VIDEO_MODAL_PAGES.ENABLE_PERMS}
            onClose={close}
          />
          <NameYourVideoModal
            isOpen={page === VIDEO_MODAL_PAGES.NAME_VIDEO}
            onClose={close}
          />
          {page === VIDEO_MODAL_PAGES.RECORD_VIDEO && (
            <VideoScreenRecordingModal
              isOpen={page === VIDEO_MODAL_PAGES.RECORD_VIDEO}
              onClose={close}
            />
          )}
        </>
      )}
      {showErrorModal && (
        <ErrorSavingModal
          isOpen={showErrorModal}
          onClose={() => setShowErrorModal(false)}
        />
      )}
    </>
  );
}

export default Modals;
