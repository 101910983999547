import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";

import { TooltipMenu } from "common/components/TooltipMenu";
import "../OutcomeNode/ui/MultipleOutcomeNodeSetting.scss";
import { OUT_COMES_PAGE, useOutComesStore } from "../OutcomeNode";
import { useState } from "react";

export const OutcomeOptionMenu: React.FC<{ id: string }> = ({ id }) => {
  const { setEditingOutcomeId, setPage, deleteMultipleOutcome } =
    useOutComesStore();
  const [open, setOpen] = useState(false);
  return (
    <TooltipMenu open={open} onOpenChange={(o) => setOpen(o)}>
      <TooltipMenu.Trigger
        className="main-node-choice__action next-step-popup"
        aria-label="Choice Settings"
      >
        <FontAwesomeIcon fixedWidth icon={solid("ellipsis-vertical")} />
      </TooltipMenu.Trigger>
      <TooltipMenu.Content>
        <TooltipMenu.Header>
          <span>Edit Outcome</span>
        </TooltipMenu.Header>
        <TooltipMenu.Options>
          <TooltipMenu.Option
            onClick={() => {
              setEditingOutcomeId(id);
              setPage(OUT_COMES_PAGE.ADD_NEW_OUTCOME);
            }}
          >
            <TooltipMenu.OptionIcon>
              <FontAwesomeIcon fixedWidth icon={solid("pen-nib")} />
            </TooltipMenu.OptionIcon>
            <span>Edit</span>
          </TooltipMenu.Option>
          <TooltipMenu.Option
            onClick={() => {
              deleteMultipleOutcome(id);
            }}
          >
            <TooltipMenu.OptionIcon>
              <FontAwesomeIcon fixedWidth icon={solid("trash")} />
            </TooltipMenu.OptionIcon>
            <span>Delete</span>
          </TooltipMenu.Option>
        </TooltipMenu.Options>
        <TooltipMenu.Footer>
          <TooltipMenu.ActionButton onClick={() => setOpen(false)}>
            Done
          </TooltipMenu.ActionButton>
        </TooltipMenu.Footer>
      </TooltipMenu.Content>
    </TooltipMenu>
  );
};
