import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useContext } from "react";
import { useStore } from "zustand";

import { TooltipMenu } from "common/components/TooltipMenu";

import { useOutComesStore } from "modules/canvas/components/OutcomeNode";
import { currentNodeContext } from "modules/canvas/currentNodeContext";
import {
  ChoiceActionTypes,
  EdgeData,
  NodeData,
  NodeDataChildType,
} from "modules/canvas/data/types";
import useCanvasFunctions from "modules/canvas/useCanvasFunctions";
import { deleteNodeInBetweenWithRelationsMutable } from "modules/canvas/utils/math";
import { nanoid } from "nanoid";
import { Edge, Node, useReactFlow } from "react-flow-renderer";
import { NODE_SETTINGS_PAGE } from "./MainNodeSettingStore";
import { MainNodeSettingsContext } from "./MainNodeSettings.ui";

export const MainNodeSettingsInputType = () => {
  const { store, setOpen } = useContext(MainNodeSettingsContext);
  const { setCurrentPage } = useStore(store);
  const { multipleOutComes, detachAnswer } = useOutComesStore();
  const { id, data } = useContext(currentNodeContext);
  const { formatCanvas } = useCanvasFunctions();
  const rf = useReactFlow();

  const handleSaveButton = () => {
    setCurrentPage(NODE_SETTINGS_PAGE.HOME);
    setOpen(false);
  };

  function saveInputType(type: NodeDataChildType) {
    return function () {
      let nodes = rf.getNodes() as Node<NodeData>[];
      const edges = rf.getEdges() as Edge<EdgeData>[];
      if (data.children.type === type && type === NodeDataChildType.OPEN_ENDED)
        // Open the options page even if open ended is selected previously
        setCurrentPage(NODE_SETTINGS_PAGE.OPEN_ENDED_OPTIONS);
      else if (data.children.type === type) return;

      if (
        type !== NodeDataChildType.CHOICES &&
        data.children.type === NodeDataChildType.CHOICES
      )
        if (Array.isArray(data.children.choices)) {
          for (let i = 0; i < data.children.choices.length; i++) {
            multipleOutComes?.forEach((outcome) =>
              detachAnswer((data.children as any).choices[i].id, outcome.id)
            );
          }
          deleteNodeInBetweenWithRelationsMutable(id, nodes, edges, true);
        }
      const newData: NodeData["children"] = {
        type,
        openEndedType: (data.children as any).openEndedType ?? {
          v: true,
          t: true,
          a: false,
        },
        choices: [
          {
            id: nanoid(),
            selectedOption: ChoiceActionTypes.CONTINUE,
            value: "Option",
          },
        ],
      };

      // saveNodeData(newData, id);
      for (let i = 0; i < nodes.length; i++) {
        if (nodes[i].id === id) {
          nodes[i].data.children = newData;
        }
      }
      // nodes = nodes.map((n) => {
      //   if (n.id === id) {
      //     n.data =
      //     return n;
      //   }
      //   return n;
      // });

      rf.setNodes(nodes);
      rf.setEdges(edges);
      setTimeout(formatCanvas);

      if (type === NodeDataChildType.OPEN_ENDED)
        // open open ended options page
        setCurrentPage(NODE_SETTINGS_PAGE.OPEN_ENDED_OPTIONS);
    };
  }
  return (
    <>
      <TooltipMenu.Header>
        <TooltipMenu.BackNav
          onClick={() => setCurrentPage(NODE_SETTINGS_PAGE.HOME)}
        />
        <span>Input Type</span>
      </TooltipMenu.Header>
      <TooltipMenu.Options>
        <TooltipMenu.Option onClick={saveInputType(NodeDataChildType.CHOICES)}>
          <TooltipMenu.OptionIcon>
            <svg
              width="64"
              height="64"
              viewBox="0 0 64 64"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect
                x="8"
                y="26"
                width="58"
                height="12"
                rx="4"
                fill="currentColor"
              />
            </svg>
          </TooltipMenu.OptionIcon>
          <span>Single Select</span>

          {data.children.type === NodeDataChildType.CHOICES && (
            <div className="main-node-choice__active-setting">
              <FontAwesomeIcon fixedWidth icon={solid("check")} />
            </div>
          )}
        </TooltipMenu.Option>
        <TooltipMenu.Option onClick={saveInputType(NodeDataChildType.MULTIPLE)}>
          <TooltipMenu.OptionIcon>
            <svg
              width="64"
              height="64"
              viewBox="0 0 64 64"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect
                x="6"
                y="36"
                width="58"
                height="12"
                rx="4"
                fill="currentColor"
              />
              <rect
                x="6"
                y="16"
                width="58"
                height="12"
                rx="4"
                fill="currentColor"
              />
            </svg>
          </TooltipMenu.OptionIcon>
          <span>Multiple Select</span>

          {data.children.type === NodeDataChildType.MULTIPLE && (
            <div className="main-node-choice__active-setting">
              <FontAwesomeIcon fixedWidth icon={solid("check")} />
            </div>
          )}
        </TooltipMenu.Option>
        <TooltipMenu.Option
          onClick={saveInputType(NodeDataChildType.OPEN_ENDED)}
        >
          <TooltipMenu.OptionIcon>
            <FontAwesomeIcon fixedWidth icon={solid("text")} />
          </TooltipMenu.OptionIcon>
          <span>Open Ended</span>

          {data.children.type === NodeDataChildType.OPEN_ENDED && (
            <div className="main-node-choice__active-setting">
              <FontAwesomeIcon fixedWidth icon={solid("check")} />
            </div>
          )}
        </TooltipMenu.Option>
        <TooltipMenu.Option onClick={saveInputType(NodeDataChildType.NONE)}>
          <TooltipMenu.OptionIcon>
            <FontAwesomeIcon fixedWidth icon={solid("hourglass-end")} />
          </TooltipMenu.OptionIcon>
          <span>None (Auto Advance)</span>

          {data.children.type === NodeDataChildType.NONE && (
            <div className="main-node-choice__active-setting">
              <FontAwesomeIcon fixedWidth icon={solid("check")} />
            </div>
          )}
        </TooltipMenu.Option>
      </TooltipMenu.Options>
      <TooltipMenu.Footer>
        <TooltipMenu.ActionButton onClick={handleSaveButton}>
          Save
        </TooltipMenu.ActionButton>
      </TooltipMenu.Footer>
    </>
  );
};
