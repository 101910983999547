import { NodeCard } from "common/components/NodeCard";
import { Handle, NodeProps, Position } from "react-flow-renderer";
import "../OutcomeNode/OutcomeNode.scss";

export function URLOutcomeNode({
  data,
  isConnectable,
  id,
  selected,
}: NodeProps) {
  return (
    <>
      <Handle
        type="target"
        position={Position.Top}
        className="o-0"
        onConnect={(params) => console.log("handle onConnect", params)}
        isConnectable={isConnectable}
      />

      <>
        <div
          className="outcome-node"
          style={{ cursor: "pointer" }}
          onClick={() => window.open(data.label, "_blank")}
        >
          <NodeCard className="outcome-node__card">
            <div className="outcome-node__body">
              <div className="outcome-node__label" style={{ fontSize: "12px" }}>
                {data.label}
              </div>
            </div>
          </NodeCard>
        </div>
      </>

      <Handle
        type="source"
        position={Position.Bottom}
        id="branchHandle"
        className="o-0"
        isConnectable={isConnectable}
      />
    </>
  );
}
