import { useState, useCallback, useEffect } from "react";
import RecordRTC, { RecordRTCPromisesHandler } from "recordrtc";
import { useVideoModalStore } from "../MainNode/MainNode";

function mapExactId(id: string) {
  return { deviceId: id };
}

export const useRecorderPermission = (
  recordingType: RecordRTC.Options["type"]
) => {
  const { audioId, videoId } = useVideoModalStore(({ audioId, videoId }) => ({
    audioId,
    videoId,
  }));

  const [recorder, setRecorder] =
    useState<RecordRTC.RecordRTCPromisesHandler>();
  const [stream, setStream] = useState<MediaStream>();

  const initializeRecorder = useCallback(async () => {
    let stream = await window.navigator.mediaDevices.getUserMedia({
      video: videoId
        ? mapExactId(videoId)
        : {
            width: { ideal: 4096 },
            height: { ideal: 2160 },
            aspectRatio: { ideal: 16 / 9 },
            facingMode: { ideal: "user" },
          },
      audio: audioId
        ? mapExactId(audioId)
        : {
            autoGainControl: { ideal: false },
            noiseSuppression: { ideal: false },
          },
    });
    let recorder = new RecordRTCPromisesHandler(stream, {
      type: recordingType,
      mimeType: "video/webm;codecs=vp9",
    });
    setStream(stream);
    setRecorder(recorder);
    return stream;
  }, [audioId, recordingType, videoId]);

  useEffect(() => {
    initializeRecorder();
  }, [initializeRecorder]);

  return { recorder, stream };
};
