import Canvas from "modules/canvas";
import { Navbar } from "common/components/Navbar/Navbar";
import { useReactFlow } from "react-flow-renderer";
import { Setup } from "modules/canvas/data/Setup";
import create from "zustand";
import { devtools } from "zustand/middleware";
import { NewInterfaceModal } from "modules/canvas/components/NewInterfaceModal/NewInterfaceModal";
import { useState } from "react";
import { saveData } from "modules/canvas/data/apiClient";
import Modals from "modules/canvas/components/Modals";
import Loader from "./loader";
import { SavingState } from "modules/canvas/data/types";
import { SERVER_URL } from "modules/canvas/data/env";

type ICanvasState = {
  isLoadingFlow: boolean;
  isEditing: boolean;
  flowId?: string;
  flowName?: string;
  isLoaderShown?: boolean;
  savingState: SavingState;
  retries: number;
  isRetrying: boolean;
  showErrorModal: boolean;

  setIsLoaderShown: (s: boolean) => void;
  setFlowName: (flowName: string) => void;
  setIsEditing: (isEditing: boolean) => void;
  setFlowId: (flowId: string) => void;
  setIsLoadingFlow: (isLoadingFlow: boolean) => void;
  setSavingState: (newState: SavingState) => void;
  setRetries: (retries: number) => void;
  setIsRetrying: (isRetrying: boolean) => void;
  setShowErrorModal: (showErrorModal: boolean) => void;
};
export const useCanvasStateStore = create<
  ICanvasState,
  [["zustand/devtools", ICanvasState]]
>(
  devtools((set) => ({
    isLoadingFlow: !!new URLSearchParams(window.location.search).get("flow-id"),
    isLoaderShown: true,
    isEditing: false,
    savingState: SavingState.IDLE,
    retries: 0,
    isRetrying: false,
    showErrorModal: false,
    setFlowId(flowId) {
      set((state) => ({ ...state, flowId }));
    },
    setIsEditing(isEditing) {
      set((state) => ({ ...state, isEditing }));
    },
    setIsLoadingFlow: (isLoadingFlow: boolean) =>
      set((state) => ({ ...state, isLoadingFlow })),
    setFlowName(flowName) {
      set((state) => ({ ...state, flowName }));
    },
    setIsLoaderShown(s) {
      set((state) => ({ ...state, isLoaderShown: s }));
    },
    setRetries(retries) {
      set((state) => ({ ...state, retries }));
    },
    setSavingState(newState) {
      set((state) => ({ ...state, savingState: newState }));
    },
    setIsRetrying(isRetrying) {
      set((state) => ({ ...state, isRetrying }));
    },
    setShowErrorModal(showErrorModal) {
      set((state) => ({ ...state, showErrorModal }));
    },
  }))
);

export const CanvasPage: React.FC = () => {
  const {
    isLoadingFlow,
    isEditing,
    setFlowName,
    setFlowId,
    setIsEditing,
    isLoaderShown,
  } = useCanvasStateStore();
  const [newInterfaceModal, setNewInterfaceModal] = useState(true);
  const rf = useReactFlow();
  return (
    <>
      <div
        style={{
          width: "100vw",
          height: "100vh",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Setup />
        <Navbar />
        <Canvas />
        {isLoaderShown && (
          <Loader isShown={isLoadingFlow} />
          // <div
          //   style={{
          //     position: "absolute",
          //     width: "100vw",
          //     height: "100vh",
          //     top: 0,
          //     left: 0,
          //     backgroundColor: "#f5f9fc",
          //     zIndex: 9999,
          //   }}
          // >
          //   Loading Quiz
          // </div>
        )}
        {!isEditing && !isLoadingFlow && newInterfaceModal && (
          <NewInterfaceModal
            onSubmit={(val) => {
              setFlowName(val);
              saveData(rf.getNodes(), rf.getEdges(), [], val).then((data) => {
                if (data.status === 401 || data.status === 417) {
                  window.open(`${SERVER_URL}/login`, "_self");
                  return;
                }
                setFlowId(data.data.uuid);
                setIsEditing(true);
                const qs = new URL(window.location as unknown as URL);
                qs.searchParams.set("flow-id", data.data.uuid);
                window.history.pushState(null, "", qs.toString());
              });
            }}
            isOpen={!isEditing}
            onClose={() => setNewInterfaceModal(false)}
          />
        )}
        <Modals />
      </div>
    </>
  );
};
