import {
  DndContext,
  MouseSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  CanvasModal,
  CanvasModalHeader,
} from "common/components/CanvasModal/CanvasModal";

import useConstant from "common/hooks/useConstant";

import { MapOutcomeAnswerModalAnswersList } from "./MapOutcomeAnswerModalAnswersList";
import { MapOutcomeAnswerModalDragOverlays } from "./MapOutcomeAnswerModalDragOverlays";
import { MapOutcomeAnswerModalOutcomesDelete } from "./MapOutcomeAnswerModalOutcomesDelete";
import { MapOutcomeAnswerModalOutcomesList } from "./MapOutcomeAnswerModalOutcomesList";
import { MapOutcomeAnswerModalQuestionsList } from "./MapOutcomeAnswerModalQuestionsList";

import {
  createMapOutcomeStore,
  MapOutcomeContext,
} from "./MapOutcomeAnswerModalStore";

import useCanvasFunctions from "modules/canvas/useCanvasFunctions";
import { useOutComesStore } from "../OutcomeNode";
import "./MapOutcomeAnswerModal.scss";
import { LabelledSwitch } from "common/components/LabelledSwitch/LabelledSwitch";
interface MapOutcomeAnswerModalProps {
  isOpen?: boolean | undefined;
  onClose(value: boolean): void;
}

export const MapOutcomeAnswerModal = ({
  isOpen,
  onClose,
}: MapOutcomeAnswerModalProps) => {
  const store = useConstant(() =>
    createMapOutcomeStore({ initialData: {}, initialValue: {} })
  );
  const { buildOutcomeNodes } = useCanvasFunctions();
  const {
    getSelectedOutcomesArr,
    isWeightModeEnabled,
    setIsWeightModeEnabled,
  } = useOutComesStore();

  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 5,
      },
    })
  );
  return (
    <CanvasModal
      modalBodyClassName="map-outcome-modal__main"
      isClosableOnBlur
      isOpen={isOpen}
      onClose={onClose}
      className="map-outcome-modal"
    >
      <MapOutcomeContext.Provider value={{ store: store }}>
        <DndContext sensors={sensors}>
          <CanvasModalHeader>
            <div className="map-outcome-modal__header">
              <h2 className="map-outcome-modal__heading">
                Map Answers to Outcomes
              </h2>
              {/* <div>
                <p>Edit Answer Weighting</p>
                <div className="main-node-settings__delay-body">
                  <LabelledSwitch
                    enabled={isWeightModeEnabled}
                    onChange={(s) => setIsWeightModeEnabled(s as boolean)}
                    enabledLabel="On"
                    disabledLabel="Off"
                  />
                </div>
              </div> */}
            </div>
          </CanvasModalHeader>
          <div className="map-outcome-modal__body">
            <table className="map-outcome-modal__table">
              <thead className="map-outcome-modal__table-header">
                <tr>
                  <td>Question</td>
                  <td>Answers</td>
                  <td>Outcomes</td>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <MapOutcomeAnswerModalQuestionsList />
                  </td>
                  <td>
                    <MapOutcomeAnswerModalAnswersList />
                  </td>
                  <td>
                    <MapOutcomeAnswerModalOutcomesList />
                    <MapOutcomeAnswerModalOutcomesDelete />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="map-outcome-modal__footer">
            <button
              onClick={() => {
                buildOutcomeNodes(getSelectedOutcomesArr());
                onClose(false);
              }}
              className="map-outcome-modal__save-btn"
            >
              <span>Save</span>
              <span>
                <FontAwesomeIcon fixedWidth icon={solid("arrow-right")} />
              </span>
            </button>
          </div>
          <MapOutcomeAnswerModalDragOverlays />
        </DndContext>
      </MapOutcomeContext.Provider>
    </CanvasModal>
  );
};
