import { createStore, StoreApi, useStore } from "zustand";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";

import { TooltipMenu } from "common/components/TooltipMenu";
import { createContext, useContext, useState } from "react";
import "./MultipleOutcomeNodeSetting.scss";
import { OUT_COMES_PAGE, useOutComesStore } from "..";
import { useMapOutcomeStore } from "../../MapOutcomeAnswerModal/MapOutcomeAnswerModalStore";
import useCanvasFunctions from "modules/canvas/useCanvasFunctions";
import { useModalStore } from "../../Modals";

enum OUTCOMES_SETTING_PAGE {
  HOME = "home",
  SWITCH = "switch",
}

interface OutcomesSettingsState {
  currentPage: OUTCOMES_SETTING_PAGE;
  setCurrentPage: (page: OUTCOMES_SETTING_PAGE) => void;
}

interface ChoiceSettingsContextType {
  store: StoreApi<OutcomesSettingsState>;
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const OutcomesSettingsStore = createStore<OutcomesSettingsState>((set) => ({
  currentPage: OUTCOMES_SETTING_PAGE.HOME,
  setCurrentPage: (page) => set((state) => ({ ...state, currentPage: page })),
}));

const OutcomesSettingsContext = createContext({} as ChoiceSettingsContextType);

export const MultipleOutcomeNodeSettings = () => {
  const [open, setOpen] = useState(false);

  const { currentPage, setCurrentPage } = useStore(OutcomesSettingsStore);

  const handleTooltipOpen = (open: boolean) => {
    setOpen(open);
    if (!open) setCurrentPage(OUTCOMES_SETTING_PAGE.HOME);
  };

  return (
    <TooltipMenu open={open} onOpenChange={handleTooltipOpen}>
      <TooltipMenu.Trigger
        className="main-node-choice__action next-step-popup"
        aria-label="Choice Settings"
      >
        <FontAwesomeIcon fixedWidth icon={solid("cog")} />
      </TooltipMenu.Trigger>
      <TooltipMenu.Content>
        <OutcomesSettingsContext.Provider
          value={{ store: OutcomesSettingsStore, open, setOpen }}
        >
          {currentPage === OUTCOMES_SETTING_PAGE.HOME && (
            <MainNodeChoiceSettingsHome />
          )}
          {currentPage === OUTCOMES_SETTING_PAGE.SWITCH && (
            <SwitchOutcomesType />
          )}
        </OutcomesSettingsContext.Provider>
      </TooltipMenu.Content>
    </TooltipMenu>
  );
};

const MainNodeChoiceSettingsHome = () => {
  const { store, setOpen } = useContext(OutcomesSettingsContext);
  const { setCurrentPage } = useStore(store);
  const { setPage, currentOutcomeType } = useOutComesStore();
  const { setFlatQuestionsList, setQuestionsList } = useMapOutcomeStore();
  const { buildQuestionList2 } = useCanvasFunctions();
  const isMultipleOutcomes = currentOutcomeType === "multiple";
  const text = isMultipleOutcomes ? "Single" : "Multiple";
  const { setShouldShowAnimation } = useModalStore();

  return (
    <>
      <TooltipMenu.Header>
        <span>Edit Outcome/s</span>
      </TooltipMenu.Header>
      <TooltipMenu.Options>
        <TooltipMenu.Option
          onClick={() => {
            isMultipleOutcomes
              ? setPage(OUT_COMES_PAGE.MULTIPLE_OUTCOME_LIST)
              : setPage(OUT_COMES_PAGE.SINGLE_OUTCOME_LIST);
            setOpen(false);
            setTimeout(() => setShouldShowAnimation(false), 500);
          }}
        >
          <TooltipMenu.OptionIcon>
            <FontAwesomeIcon fixedWidth icon={solid("pen-nib")} />
          </TooltipMenu.OptionIcon>
          <span>Edit Outcomes</span>
        </TooltipMenu.Option>
        {isMultipleOutcomes && (
          <TooltipMenu.Option
            onClick={() => {
              const s = buildQuestionList2();
              setFlatQuestionsList(s[1]);
              setQuestionsList(s[0]);
              setPage(OUT_COMES_PAGE.MAP_ANS_TO_OUTCOME);
              setOpen(false);
            }}
          >
            <TooltipMenu.OptionIcon>
              <FontAwesomeIcon fixedWidth icon={solid("book-open")} />
            </TooltipMenu.OptionIcon>
            <span>Edit Answer Map</span>
          </TooltipMenu.Option>
        )}
        <TooltipMenu.Option
          onClick={() => setCurrentPage(OUTCOMES_SETTING_PAGE.SWITCH)}
        >
          <TooltipMenu.OptionIcon>
            <FontAwesomeIcon fixedWidth icon={solid("arrows-rotate")} />
          </TooltipMenu.OptionIcon>
          <span>Switch to {text}</span>
        </TooltipMenu.Option>
      </TooltipMenu.Options>
      <TooltipMenu.Footer>
        <TooltipMenu.ActionButton onClick={() => setOpen(false)}>
          Done
        </TooltipMenu.ActionButton>
      </TooltipMenu.Footer>
    </>
  );
};

const SwitchOutcomesType = () => {
  const { store, setOpen } = useContext(OutcomesSettingsContext);
  const { setCurrentPage } = useStore(store);
  const { setPage, currentOutcomeType, switchOutcomes } = useOutComesStore();
  const switchOutcome = () => {
    const page =
      currentOutcomeType === "multiple"
        ? OUT_COMES_PAGE.SINGLE_OUTCOME_LIST
        : OUT_COMES_PAGE.MULTIPLE_OUTCOME_LIST;
    switchOutcomes();
    setCurrentPage(OUTCOMES_SETTING_PAGE.HOME);
    setOpen(false);
    setPage(page);
  };
  return (
    <>
      <TooltipMenu.Header className="branched-node__header">
        <TooltipMenu.BackNav
          onClick={() => setCurrentPage(OUTCOMES_SETTING_PAGE.HOME)}
        />
        <span>Are you sure?</span>
      </TooltipMenu.Header>
      <TooltipMenu.Body>
        <button
          onClick={switchOutcome}
          type="button"
          className="branched-node__delete-btn"
        >
          Switch
        </button>
        <p>All existing outcomes will be lost!</p>
      </TooltipMenu.Body>

      <TooltipMenu.Footer>
        <TooltipMenu.ActionButton
          onClick={() => setCurrentPage(OUTCOMES_SETTING_PAGE.HOME)}
        >
          Cancel
        </TooltipMenu.ActionButton>
      </TooltipMenu.Footer>
    </>
  );
};
