import { createContext } from "react";
import create, { createStore, StoreApi } from "zustand";
import { devtools } from "zustand/middleware";
import { immer } from "zustand/middleware/immer";
import { Question } from "./MapOutcomeAnswerModalQuestionsList";

export interface MapOutcomeAnswerState {
  data: any;
  value: any;
  currentSelectedQuestion?: Question;
  setCurrentSelectedQuestion: (q: Question) => void;
}

interface CreateMapOutcomeStore {
  initialData?: any;
  initialValue?: any;
}

export const createMapOutcomeStore = ({
  initialData,
  initialValue,
}: CreateMapOutcomeStore) =>
  createStore<MapOutcomeAnswerState>((set) => ({
    data: initialData || {},
    value: initialValue || {},
    setCurrentSelectedQuestion: (q: Question) =>
      set((state) => ({ ...state, currentSelectedQuestion: q })),
  }));

interface MapOutcomeContextType {
  store: StoreApi<MapOutcomeAnswerState>;
}

export const MapOutcomeContext = createContext({} as MapOutcomeContextType);

interface MapOutcomesStoreType {
  questionList: Question[];
  currentSelectedQuestionId?: string;
  currentSelectedAnsId?: string;
  flatQuestionList: Question[];
  openedAnswer: string;
  setQuestionsList: (q: Question[]) => void;
  setCurrentSelectedQuestion: (q: string) => void;
  setCurrentSelectedAnswer: (ans: string) => void;
  setFlatQuestionsList: (q: Question[]) => void;
  getCurrentQuestion: () => Question | undefined;
  getCurrentAnswer: (ansId?: string) => Question["answers"][0] | undefined;
  openQuestion: (hierachyId: string, shouldCloseInstead?: boolean) => void;
  toggleQuestion: (hierachyId: string, questionId?: string) => void;
  setOpenedAnswer: (answer: string) => void;
}
export const useMapOutcomeStore = create<
  MapOutcomesStoreType,
  [
    ["zustand/devtools", MapOutcomesStoreType],
    ["zustand/immer", MapOutcomesStoreType]
  ]
>(
  devtools(
    immer<MapOutcomesStoreType>((set, get) => ({
      questionList: [],
      flatQuestionList: [],
      lastOpenedQuestionHierarchyId: "",
      openedAnswer: "",
      setCurrentSelectedQuestion: (q) => {
        console.log({ q });
        set((state) => ({
          ...state,
          currentSelectedQuestionId: q,
          openedAnswer: "",
        }));
      },
      setCurrentSelectedAnswer(ans) {
        set((state) => ({
          ...state,
          currentSelectedAnsId: ans,
          openedAnswer: "",
        }));
      },
      setQuestionsList(q) {
        set((state) => ({ ...state, questionList: q }));
      },
      getCurrentAnswer(ans) {
        const ansId = ans ?? get().currentSelectedAnsId;
        const currQuestion = get().questionList.find(
          (q) => get().currentSelectedQuestionId === q.id
        );
        if (!currQuestion) return;
        return currQuestion.answers.find((a) => a.choiceId === ansId);
      },
      getCurrentQuestion() {
        return get().flatQuestionList.find(
          (q) => get().currentSelectedQuestionId === q.id
        );
      },
      setFlatQuestionsList(q) {
        set((state) => ({ ...state, flatQuestionList: q }));
      },
      openQuestion(hierachyId, shouldCloseInstead: boolean = false) {
        const ids = hierachyId.split(".");
        set((state) => {
          let currObject = state.questionList.find(
            (question) => question.id === ids[0]
          )!;
          if (0 === ids.length - 1 && currObject?.isOpen) {
            currObject.isOpen = false;
            return;
          } else currObject.isOpen = true;

          for (let i = 1; i < ids.length; i++) {
            // start with second id
            currObject = currObject.branches.find(
              (branch) => branch.id === ids[i]
            )!;
            if (i === ids.length - 1 && currObject && currObject?.isOpen)
              currObject.isOpen = false;
            else currObject.isOpen = true;
          }
        });
      },
      toggleQuestion(hierachyId, questionId = "") {
        console.log("OPENEING HIERARCHY: ", hierachyId);
        get().openQuestion(hierachyId);
        if (questionId) get().setCurrentSelectedQuestion(questionId);
      },
      setOpenedAnswer(answer) {
        set((state) => {
          state.openedAnswer = answer;
        });
      },
    })),
    { name: "OutcomesQuestionList" }
  )
);
