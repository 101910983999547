/**
 * An object that returns the appropiate server URL based on ENV variable
 */
export const serverURLMap = {
  development: "http://localhost",
  DEV2: "https://dev2.tryinterface.io",
  production: "https://app.tryinterface.io",
};

/**
 * The current environment in which the app is running
 */
export const ENV = (
  process.env.REACT_APP_NTF_ENV ?? process.env.NODE_ENV
).trim() as keyof typeof serverURLMap;
export const IS_ENV_LOCAL = ENV === "development";
export const IS_ENV_DEV2_STAGING = ENV === "DEV2";
export const IS_ENV_PROD = ENV === "production";

export const SERVER_URL = serverURLMap[ENV];
