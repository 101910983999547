import { DragOverlay, useDndMonitor } from "@dnd-kit/core";
import { useState } from "react";
import { MapOutcomeAnswerModalAnswerItemOverlay } from "./MapOutcomeAnswerModalAnswersList";
import { MapOutcomeAnswerModalOutcomesMappedItemOverlay } from "./MapOutcomeAnswerModalOutcomesList";

export const MapOutcomeAnswerModalDragOverlays = () => {
  const [activeDragItem, setActiveDragItem] = useState<any>(null);

  useDndMonitor({
    onDragStart: ({ active }) => {
      const {
        data: { current },
      } = active;

      setActiveDragItem(current);
    },
    onDragEnd: () => {
      setActiveDragItem(null);
    },
  });
  console.log({ activeDragItem });

  return (
    <DragOverlay wrapperElement="ul" dropAnimation={null}>
      {activeDragItem?.type === "answer-item" && (
        <MapOutcomeAnswerModalAnswerItemOverlay data={{ ...activeDragItem }} />
      )}
      {activeDragItem?.type === "mapped-outcome-item" && (
        <MapOutcomeAnswerModalOutcomesMappedItemOverlay
          data={{ ...activeDragItem }}
          outcomeId=""
        />
      )}
    </DragOverlay>
  );
};
