import { ReactFlowProvider } from "react-flow-renderer";
import { CanvasPage } from "./pages/Canvas";
import SmallScreenNotAllowedModal from "modules/canvas/components/SmallScreenNotAllowedModal/SmallScreenNotAllowedModal";

console.log("Hello WOrld");
function App() {
  return (
    <ReactFlowProvider>
      {window.innerWidth <= 1024 ? (
        <SmallScreenNotAllowedModal />
      ) : (
        <CanvasPage />
      )}
      ;
    </ReactFlowProvider>
  );
}

export default App;
