import { Handle, NodeProps, Position } from "react-flow-renderer";
import { OutcomeNodeUI } from "./OutcomeNode.ui";
import create from "zustand";
import { devtools } from "zustand/middleware";
export enum OUT_COMES_PAGE {
  NONE,
  OUTCOMES,
  MULTIPLE_OUTCOME_LIST,
  ADD_NEW_OUTCOME,
  SINGLE_OUTCOME_LIST,
  CHANGE_WEIGHTING,
  MAP_ANS_TO_OUTCOME,
}
type OutcomeAnswer = {
  choiceId: string;
  questionId: string;
  weight: string;
  questionListHierarchyId: string;
};
export type OutcomeCta = { value: string; url: string; id: string };
export type OutcomeNodeData = Partial<{
  id: string;
  title: string;
  video: string;
  videoID: string;
  thumbnail: string;
  ctaLabels: Array<OutcomeCta>;
}>;
export type IOutcome = {
  id: string;
  label: string;
  redirectURL?: string;
  type: "flow" | "url";
  isWebinarMode: boolean;
  delay: number;
  answers: Array<OutcomeAnswer>;
  nodeData?: OutcomeNodeData;
};
export type SingleOutcomeType = Omit<IOutcome, "answers">;
type OutcomeType = "single" | "multiple";

type OutComesStoreType = {
  page: OUT_COMES_PAGE;
  multipleOutComes?: IOutcome[];
  singleOutcome?: SingleOutcomeType;
  selectedOutcomeId?: string;
  selectedAnswerId?: string;
  currentOutcomeType: OutcomeType;
  editingOutcomeId: string;
  isWeightModeEnabled: boolean;

  setSelectedAnswerId: (id: string) => void;
  setSelectedOutcomeId: (id: string) => void;
  setPage: (page: OUT_COMES_PAGE) => void;
  addMultipleOutCome: (outcome: IOutcome) => void;
  addAnswerToOutCome: (answer: OutcomeAnswer, outComeId: string) => void;
  deleteMultipleOutcome: (s: string) => void;
  detachAnswer: (answerId: string, outComeId: string) => void;
  switchOutcomes: () => void;
  setOutcomesType: (s: OutcomeType) => void;
  setSingleOutcome: (s: SingleOutcomeType) => void;
  editSingleOutcome: (s: Partial<SingleOutcomeType>) => void;
  getSelectedOutcomesArr: () => Array<IOutcome | SingleOutcomeType | undefined>;
  setMultipleOutcomes: (s: IOutcome[]) => void;
  editMultipleOutCome: (
    id: string,
    updatedOutcome: Partial<IOutcome>,
    overwriteCtaLabels?: boolean
  ) => void;
  changeWeighting: (
    ansId: string,
    outcomeId: string,
    weighting: string
  ) => void;
  setEditingOutcomeId: (editingOutcomeId: string) => void;
  editOutcomeCta: (
    answerId: string,
    outcomeId: string,
    data: Partial<OutcomeCta>
  ) => void;
  deleteOutcomeCta: (answerId: string, outcomeId: string) => void;
  addOutcomeCta: (outcomeId: string, data: OutcomeCta) => void;
  setIsWeightModeEnabled: (s: boolean) => void;
};

export const useOutComesStore = create<
  OutComesStoreType,
  [["zustand/devtools", OutComesStoreType]]
>(
  devtools(
    (set, get) => ({
      page: OUT_COMES_PAGE.NONE,
      editingOutcomeId: "",
      currentOutcomeType: "multiple",
      isWeightModeEnabled: false,
      setSelectedAnswerId(id) {
        set((state) => ({ ...state, selectedAnswerId: id }));
      },
      setPage: (page: OUT_COMES_PAGE) => set((state) => ({ ...state, page })),
      setSingleOutcome(s) {
        set((state) => ({
          ...state,
          singleOutcome: s,
          currentOutcomeType: "single",
        }));
      },
      setMultipleOutcomes(s) {
        set((state) => ({ ...state, multipleOutComes: s }));
      },
      addMultipleOutCome: (outcome: IOutcome) =>
        set((s) => {
          const state = Object.assign({}, s);
          state.multipleOutComes = (state.multipleOutComes ?? [])?.concat(
            outcome
          );

          return state;
        }),
      editMultipleOutCome(id, updatedOutcome, overwriteCtaLabels = false) {
        set((s) => {
          const state = { ...s };
          state.multipleOutComes = (state.multipleOutComes ?? []).map(
            (outcome) => {
              if (outcome.id === id)
                return {
                  ...outcome,
                  ...updatedOutcome,
                  nodeData: {
                    ...outcome.nodeData,
                    ...updatedOutcome.nodeData,
                    ctaLabels: [
                      ...(overwriteCtaLabels
                        ? []
                        : outcome.nodeData?.ctaLabels ?? []),
                      ...(updatedOutcome.nodeData?.ctaLabels ?? []),
                    ],
                  },
                };
              return outcome;
            }
          );
          return state;
        });
      },

      addAnswerToOutCome: (answer: OutcomeAnswer, outComeId: string) =>
        set((state) => {
          state.multipleOutComes?.map((outcome) => {
            if (
              outcome.id === outComeId &&
              !outcome.answers.find((a) => a.choiceId === answer.choiceId)
            )
              outcome.answers.push(answer);

            return outcome;
          });
          return state;
        }),
      setSelectedOutcomeId(id) {
        set((state) => ({ ...state, selectedOutcomeId: id }));
      },

      changeWeighting(ansId, outcomeId, weighting) {
        set((s) => {
          const state = { ...s };
          const currOutcomeIdx = (state.multipleOutComes ?? []).findIndex(
            (outcome) => outcome.id === outcomeId
          );
          const currOutcome = (state.multipleOutComes ?? [])[currOutcomeIdx];
          if (!currOutcome) return state;
          const currAnsIdx = currOutcome.answers.findIndex(
            (ans) => ans.choiceId === ansId
          );
          const currAns = currOutcome.answers[currAnsIdx];

          if (!currAns) return state;
          const newAns = { ...currAns, weight: weighting };
          currOutcome.answers[currAnsIdx] = newAns;
          (state.multipleOutComes ?? [])[currOutcomeIdx] = currOutcome;
          console.log({ finalState: state, currOutcome });
          return state;
        });
      },
      deleteMultipleOutcome: (outComeId: string) =>
        set((state) => {
          const newState = { ...state };
          newState.multipleOutComes = newState.multipleOutComes?.filter(
            (outcome) => outcome.id !== outComeId
          );
          return newState;
        }),
      detachAnswer: (answerId: string, outComeId: string) =>
        set((state) => {
          state.multipleOutComes = state.multipleOutComes?.map((o) => {
            if (o.id === outComeId && Array.isArray(o.answers)) {
              o.answers = o.answers.filter((ans) => ans.choiceId !== answerId);
            }
            return o;
          });
          return state;
        }),
      switchOutcomes() {
        set((state) => ({
          ...state,
          multipleOutComes: [],
          singleOutcome: {} as IOutcome,
          currentOutcomeType:
            state.currentOutcomeType === "single" ? "multiple" : "single",
        }));
      },
      setOutcomesType(s) {
        set((state) => ({ ...state, currentOutcomeType: s }));
      },
      getSelectedOutcomesArr() {
        return get().currentOutcomeType === "multiple"
          ? get().multipleOutComes ?? []
          : [get().singleOutcome];
      },
      setEditingOutcomeId(isEditingOutCome) {
        set((state) => ({ ...state, editingOutcomeId: isEditingOutCome }));
      },
      editOutcomeCta(answerId, outcomeId, data) {
        const currState = get();
        if (currState.currentOutcomeType === "single")
          set((state) => ({
            ...state,
            singleOutcome: {
              ...state.singleOutcome!,
              nodeData: {
                ...state.singleOutcome?.nodeData,
                ctaLabels:
                  state.singleOutcome!.nodeData?.ctaLabels?.map((ans) => {
                    if (ans.id === answerId) return { ...ans, ...data };
                    return ans;
                  }) ?? [],
              },
            },
          }));
        else
          set((state) => ({
            ...state,
            multipleOutComes: (currState.multipleOutComes ?? []).map(
              (outcome) => {
                if (outcome.id === outcomeId)
                  return {
                    ...outcome,
                    nodeData: {
                      ...outcome.nodeData,
                      ctaLabels: outcome.nodeData?.ctaLabels?.map((ans) => {
                        if (ans.id === answerId) return { ...ans, ...data };
                        return ans;
                      }),
                    },
                  };
                return outcome;
              }
            ),
          }));
      },

      deleteOutcomeCta(answerId, outcomeId) {
        const currState = get();
        if (currState.currentOutcomeType === "single")
          set((state) => ({
            singleOutcome: {
              ...state.singleOutcome!,
              nodeData: {
                ...state.singleOutcome?.nodeData,
                ctaLabels: state.singleOutcome!.nodeData?.ctaLabels?.filter(
                  (cta) => cta.id !== answerId
                ),
              },
            },
          }));
        else
          set((state) => ({
            ...state,
            multipleOutComes: (currState.multipleOutComes ?? []).map(
              (outcome) => {
                if (outcome.id === outcomeId)
                  return {
                    ...outcome,
                    nodeData: {
                      ...outcome.nodeData,
                      ctaLabels: outcome.nodeData?.ctaLabels?.filter(
                        (cta) => cta.id !== answerId
                      ),
                    },
                  };
                return outcome;
              }
            ),
          }));
      },
      addOutcomeCta(outcomeId, data) {
        const currState = get();
        if (currState.currentOutcomeType === "single")
          set((state) => ({
            singleOutcome: {
              ...state.singleOutcome!,
              nodeData: {
                ...state.singleOutcome!.nodeData,
                ctaLabels:
                  state.singleOutcome!.nodeData?.ctaLabels?.concat(data),
              },
            },
          }));
        else
          set((state) => ({
            ...state,
            multipleOutComes: (currState.multipleOutComes ?? []).map(
              (outcome) => {
                if (outcome.id === outcomeId)
                  return {
                    ...outcome,
                    nodeData: {
                      ...outcome.nodeData,
                      ctaLabels: outcome.nodeData?.ctaLabels?.concat(data),
                    },
                  };
                return outcome;
              }
            ),
          }));
      },
      editSingleOutcome(s) {
        set((state) => {
          if (state.currentOutcomeType === "single" && state.singleOutcome)
            return {
              ...state,
              singleOutcome: {
                ...state.singleOutcome,
                ...s,
                nodeData: {
                  ...state.singleOutcome?.nodeData,
                  ...s.nodeData,
                },
              },
            };
          return state;
        });
      },
      setIsWeightModeEnabled(s) {
        set((state) => ({
          ...state,
          isWeightModeEnabled: s,
        }));
      },
    }),
    { name: "OutcomesStore" }
  )
);

export function OutcomeNode({ data, isConnectable, id, selected }: NodeProps) {
  return (
    <>
      <Handle
        type="target"
        position={Position.Top}
        className="o-0"
        onConnect={(params) => console.log("handle onConnect", params)}
        isConnectable={isConnectable}
      />

      <OutcomeNodeUI data={data} selected={selected} />
      <Handle
        type="source"
        position={Position.Bottom}
        id="branchHandle"
        className="o-0"
        isConnectable={isConnectable}
      />
    </>
  );
}
