import { Node } from "react-flow-renderer";
import {
  NodeData,
  NodeDataChildChoice,
  NodeDataChildType,
} from "../data/types";

type NodeDataWithChoiceSelected = Omit<NodeData, "children"> & {
  children: { type: NodeDataChildType.CHOICES; choices: NodeDataChildChoice[] };
};

export function isChoiceSelected(
  s?: Node<NodeData>
): s is Node<NodeDataWithChoiceSelected> {
  return (
    s?.data.children.type === NodeDataChildType.CHOICES ||
    s?.data.children.type === NodeDataChildType.MULTIPLE
  );
}
