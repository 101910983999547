import { Edge, Node, ReactFlowInstance } from "react-flow-renderer";
import { NodeData, EdgeData, NodeTypes } from "../data/types";
import { ABSOLUTE_ROOT_NODE_ID } from "../data/initialData";
import { X_GAP, Y_GAP } from "../useCanvasFunctions";

export function formatCanvasUtil(
  reactFlow: ReactFlowInstance<NodeData, EdgeData>,
  n?: Node<NodeData>[],
  e?: Edge<EdgeData>[]
) {
  let nodes = Array.isArray(n) ? n : reactFlow.getNodes();
  const edges = Array.isArray(e) ? e : reactFlow.getEdges();

  const startingNodeId = edges.find((n) => n.source === ABSOLUTE_ROOT_NODE_ID)!
    .target!;
  if (startingNodeId === "3") return { nodes, edges };
  const startingNode = nodes.find((n) => n.id === startingNodeId)!;

  formatCanvasHelper(startingNode, { x: 0, y: Y_GAP }, nodes, edges);

  // Adjust Outcome Nodes Positions (Instead of rebuilding them from the data)
  const outcomeNode = reactFlow.getNode("4")!;
  const optinNode = reactFlow.getNode("3")!;
  nodes = nodes.map((node) => {
    if (node.id === "4")
      node.position = {
        x: node.position.x,
        y: optinNode.position.y + Y_GAP,
      };
    if (node.data.isOutcomeNode)
      if (node.type === NodeTypes.BRANCHED_NODE)
        node.position = {
          x: node.position.x,
          y: outcomeNode.position.y + Y_GAP * 0.4,
        };
      else
        node.position = {
          x: node.position.x,
          y: outcomeNode.position.y + Y_GAP * 2 * 0.4,
        };

    return node;
  });

  return { nodes, edges };
}

function formatCanvasHelper(
  node: Node<NodeData>,
  position: { x: number; y: number },
  nodes: Node<NodeData>[],
  edges: Edge<EdgeData>[]
) {
  // Format This Node
  node.position.x = position.x;
  node.position.y =
    node.type === NodeTypes.BRANCHED_NODE
      ? position.y + (450 - 60) / 2
      : position.y;

  let farthestX = position.x + X_GAP;
  let farthestY = position.y;

  // Format Branches of this node
  edges
    .filter((e) => e.data?.isTargetBranchNode && e.source === node.id)
    .map((e) => nodes.find((n) => n.id === e.target)!)
    .forEach((n) => {
      console.log(`Moving ${n.id} to`, { x: farthestX, y: position.y });
      const { x, y } = formatCanvasHelper(
        n,
        { x: farthestX, y: position.y },
        nodes,
        edges
      );
      console.log(n);
      farthestY = Math.max(farthestY, y);
      farthestX = Math.max(farthestX, x);
    });

  // Format Next Node Beneath
  let nextTargetEdge = edges.find(
    (e) => e.source === node.id && !e.data?.isTargetBranchNode
  );
  const nextNode = nodes.find((n) => n.id === nextTargetEdge?.target);
  if (!nextNode) return { x: farthestX, y: farthestY };
  const { x, y } = formatCanvasHelper(
    nextNode,
    { x: position.x, y: farthestY + Y_GAP },
    nodes,
    edges
  );

  farthestX = Math.max(x, farthestX);
  farthestY = Math.max(y, farthestY);
  return { x: farthestX, y: farthestY };
}
