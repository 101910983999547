import { createStore } from "zustand";

export enum NODE_SETTINGS_PAGE {
  HOME = "home",
  CHANGE_INPUT = "change-input",
  DELAY = "delay",
  CAPTION = "caption",
  DUPLICATE_STEP = "duplicate-step",
  REORDER = "reorder",
  DELETE = "delte",
  VIDEO_SETTINGS = "video-settings",
  OPEN_ENDED_OPTIONS = "open-ended-options",
}

export enum INPUT_TYPES {
  SINGLE = "single",
  MULTIPLE = "multiple",
  TEXT_RESPONE = "text-response",
  NONE = "none",
}

export interface MainNodeSettingsState {
  currentPage: NODE_SETTINGS_PAGE;
  setCurrentPage: (page: NODE_SETTINGS_PAGE) => void;
}

export const mainNodeSettingsStore = createStore<MainNodeSettingsState>(
  (set) => ({
    currentPage: NODE_SETTINGS_PAGE.HOME,
    setCurrentPage: (page) => set((state) => ({ ...state, currentPage: page })),
  })
);
