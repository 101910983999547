import _ from "lodash";
import { nanoid } from "nanoid";
import { useCallback } from "react";
import { Edge, Node, useReactFlow } from "react-flow-renderer";
import { Question } from "./components/MapOutcomeAnswerModal/MapOutcomeAnswerModalQuestionsList";
import {
  IOutcome,
  SingleOutcomeType,
  useOutComesStore,
} from "./components/OutcomeNode";
import { ABSOLUTE_ROOT_NODE_ID, choices } from "./data/initialData";
import {
  ChoiceActionTypes,
  EdgeData,
  EdgeTypes,
  NodeData,
  NodeDataChildChoice,
  NodeDataChildType,
  NodeTypes,
  SelectedLastBranchSetting,
} from "./data/types";
import { edgeStyles } from "./utils";
import { formatCanvasUtil } from "./utils/formatCanvas";
import { NodeTypesDisplayMap } from "./data/inputTypeMap";
import { isChoiceSelected } from "./utils/isChoiceSelected";

type NodeParam = string | Node<NodeData>;
type EdgeParam = string | Edge<EdgeData>;
export const X_GAP = 400;
export const Y_GAP = 600;

function useCanvasFunctions() {
  const reactFlow = useReactFlow<NodeData, EdgeData>();

  const getNode = useCallback(
    (node: NodeParam): Node<NodeData> | undefined => {
      if (typeof node === "string") return reactFlow.getNode(node);

      return node;
    },
    [reactFlow]
  );

  const getEdge = useCallback(
    (edge: EdgeParam): Edge<EdgeData> | undefined => {
      if (typeof edge === "string") return reactFlow.getEdge(edge);

      return edge;
    },
    [reactFlow]
  );

  const adjustNodePos = useCallback(
    (
      nodes: Node[],
      edges: Edge[],
      startingNode: Node<NodeData>,
      xAmount = 0,
      yAmount = 0,
      incrementStep?: 1 | -1,
      rootNodeId = ""
    ) => {
      // if (startingNode.id === "3") return;
      startingNode.position = {
        x: startingNode.position.x + xAmount,
        y: startingNode.position.y + yAmount,
      };
      // if (startingNode.id !== "4" && ) {
      startingNode.data.customSmallLabel = `Title`;
      if (incrementStep && startingNode.data.rootNodeId === rootNodeId) {
        startingNode.data.step = startingNode.data.step + incrementStep;
      }
      // }
      const targetNodes = edges.filter(
        (edge) => edge.source === startingNode.id
      );
      targetNodes.forEach((targetNode) => {
        if (targetNode.target) {
          const nextNode = nodes.find((n) => n.id === targetNode.target);
          nextNode &&
            adjustNodePos(
              nodes,
              edges,
              nextNode,
              xAmount,
              yAmount,
              incrementStep,
              rootNodeId
            );
        }
      });
    },
    []
  );
  const addNodeInBetween = (
    _edge: EdgeParam,
    nodeProps?: Partial<Node<NodeData>>
  ) => {
    const edges = reactFlow.getEdges();
    const edge = edges.find((e) =>
      typeof _edge === "string" ? e.id === _edge : e.id === _edge.id
    );
    if (!edge) return;
    let nodes = reactFlow.getNodes();
    const targetNode = nodes.find((node) => node.id === edge.target);
    const sourceNode = nodes.find((node) => node.id === edge.source)!;
    let newNodePos = { x: 0, y: 0 };

    if (!targetNode) return;
    newNodePos = targetNode.position;

    adjustNodePos(
      nodes,
      reactFlow.getEdges(),
      targetNode,
      0,
      Y_GAP,
      1,
      targetNode.data.rootNodeId
    );

    const newId = nanoid();
    const newNode: Node<NodeData> = {
      id: newId,
      position: newNodePos,
      data: {
        step: sourceNode.data.step + 1,
        label: `Optional title / question`,
        rootNodeId: sourceNode.data.rootNodeId || ABSOLUTE_ROOT_NODE_ID,
        hasTargetNode: true,
        children: { type: NodeDataChildType.CHOICES, choices: choices() },
      },
      type: NodeTypes.CARD,
      ...nodeProps,
    };
    newNode.id = newId;
    if (targetNode.id !== "3") newNode.position = newNodePos;
    else
      newNode.position = { x: newNodePos.x, y: sourceNode.position.y + Y_GAP };
    newNode.data = {
      ...newNode.data,
      step: sourceNode.data.step + 1,
      customSmallLabel: `Title`,
    };

    if (isChoiceSelected(newNode))
      newNode.data = {
        ...newNode.data,
        children: {
          ...newNode.data.children,
          choices: newNode.data.children.choices?.map((c) => ({
            ...c,
            id: nanoid(),
          })),
        },
      };
    // if (targetNode.id === "3") {
    //   adjustNodePos(nodes, edges, targetNode, 0, Y_GAP - targetNode.position.y );
    // }

    edge.target = newNode.id;
    const newEdges = edges
      .map((e) => {
        if (e.id === edge.id) return edge;
        return e;
      })
      .concat([
        edgeStyles({
          id: nanoid(),
          source: newNode.id,
          target: targetNode.id,
          type: EdgeTypes.BUTTON_EDGE,
        }),
      ]);
    console.log(newNode.data, sourceNode.data);
    reactFlow.setNodes(nodes.concat(newNode));
    reactFlow.setEdges(newEdges);
    setTimeout(formatCanvas);
    return newNode;
  };

  const saveNodeData = useCallback(
    (data: Partial<NodeData>, id: string) => {
      const node = getNode(id);
      if (!node) return;
      node.data = _.defaultsDeep(data, node.data);
      reactFlow.setNodes(
        reactFlow.getNodes().map((n) => {
          if (n.id === node.id) return node;
          return n;
        })
      );
    },
    [getNode, reactFlow]
  );

  const getNodeData = useCallback(
    (node: NodeParam) => getNode(node)?.data,
    [getNode]
  );

  const addNodeAtEnd = useCallback(
    (nodeId: NodeParam, nodeProps?: Partial<Node<NodeData>>) => {
      const node = getNode(nodeId);
      if (!node) return;

      const id = nanoid();
      node.data = {
        ...node.data,
        hasTargetNode: true,
      };
      const newNode: Node<NodeData> = {
        type: NodeTypes.CARD,
        data: {
          rootNodeId: node.data.rootNodeId,
          step: node.data.step + 1,
          label: "Optional title/ Question",
          children: { type: NodeDataChildType.CHOICES, choices: choices() },
          hasTargetNode: false,
        },
        ...nodeProps,
        id,
        position: { x: node.position.x, y: node.position.y + Y_GAP },
      };
      newNode.data.step = node.data.step + 1;
      newNode.data.customSmallLabel = "Title";
      reactFlow.setNodes(
        reactFlow
          .getNodes()
          .map((n) => {
            if (n.id === node.id) return node;
            return n;
          })
          .concat(newNode)
      );
      reactFlow.addEdges(
        edgeStyles({
          id: nanoid(),
          source: node.id,
          target: id,
          type: EdgeTypes.BUTTON_EDGE,
        })
      );
      return newNode;
    },
    [getNode, reactFlow]
  );

  const getHierachy = useCallback(
    (
      _node: NodeParam,
      _startingNode?: NodeParam,
      returnObjects = false,
      nodeArr: Set<string | Node<NodeData>> = new Set(),
      edgeArr: Set<string | Edge<EdgeData>> = new Set()
    ) => {
      const node = getNode(_node);
      const startingNode = getNode(_startingNode ?? "");
      if (!node) return;
      const edges = reactFlow.getEdges();

      for (let i = 0; i < edges.length; i++) {
        const e = edges[i];
        if (e.source !== node.id) continue;

        const n = getNode(e.target);
        if (!n || n.data.isOutcomeNode) continue;
        if (
          startingNode &&
          n.data.rootNodeId !== startingNode.data.rootNodeId
        ) {
          nodeArr.add(returnObjects ? n : n.id);
          edgeArr.add(returnObjects ? e : e.id);
        } else if (
          !_startingNode &&
          n.data.rootNodeId !== ABSOLUTE_ROOT_NODE_ID
        ) {
          nodeArr.add(returnObjects ? n : n.id);
          edgeArr.add(returnObjects ? e : e.id);
        }

        getHierachy(n, startingNode, returnObjects, nodeArr, edgeArr);
      }
      return { nodeIds: nodeArr, edgeIds: edgeArr };
    },
    [getNode, reactFlow]
  );

  const deleteNodeInBetweenWithRelations = async (nodeId: NodeParam) => {
    const node = getNode(nodeId);
    // alert(nodeId);
    if (!node) return;
    // Check if this node is the last node of branch; if so start deleting from that;
    const targetEdge = reactFlow.getEdges().find((e) => e.target === nodeId);
    const sourceNode = reactFlow.getEdges().find((e) => e.source === nodeId);
    console.log({ targetEdge });
    if (targetEdge && !sourceNode) {
      const sourceNode = reactFlow.getNode(targetEdge.source)!;
      if (sourceNode.data.rootNodeId === sourceNode.id) {
        deleteBranchNode(sourceNode);
        return;
      }
    }

    let newEdges = reactFlow.getEdges();
    let newNodes = reactFlow.getNodes();

    const sourceNodeEdge = newEdges.find((edge) => edge.target === node.id);
    if (!sourceNodeEdge) return;

    const data: {
      nodeIds: Set<string | Node<NodeData>>;
      edgeIds: Set<string | Edge<EdgeData>>;
    } = { nodeIds: new Set(), edgeIds: new Set() };

    const branchEdges = newEdges.filter(
      (edge) => edge.source === node.id && edge.data?.isTargetBranchNode
    );
    branchEdges.forEach((edge) => {
      const target = newNodes.find((n) => n.id === edge.target);
      if (!target) return;
      const h = getHierachy(target);
      if (!h) return;
      data.nodeIds = new Set([
        target.id,
        ...Array.from(data.nodeIds),
        ...Array.from(h.nodeIds),
      ]);

      data.edgeIds = new Set([
        ...Array.from(data.edgeIds),
        ...Array.from(h.edgeIds),
      ]);
    });

    const targetNodeEdge = newEdges.find(
      (edge) =>
        edge.source === node.id &&
        !edge.data?.isTargetBranchNode &&
        edge.type === EdgeTypes.BUTTON_EDGE
    );

    const targetId =
      newEdges.find((e) => e.source === node.id && !e.data?.isTargetBranchNode)
        ?.target ?? "";
    const target = newNodes.find((n) => n.id === targetId);

    if (target)
      adjustNodePos(
        newNodes,
        newEdges,
        target,
        0,
        -Y_GAP,
        -1,
        node.data.rootNodeId
      );
    if (targetNodeEdge) {
      sourceNodeEdge.target = targetNodeEdge.target;
      newEdges = newEdges.filter((e) => e.id !== targetNodeEdge.id);
    } else {
      newEdges = newEdges.filter((e) => e.id !== sourceNodeEdge.id);
    }
    if (!node.data.hasTargetNode) {
      const prevNode = reactFlow.getNode(
        reactFlow.getEdges().find((e) => e.target === node.id)!.source
      )!;
      newNodes = newNodes.map((n) => {
        if (n.id === prevNode.id) {
          n.data = {
            ...prevNode.data,
            hasTargetNode: false,
          };
        }
        return n;
      });
      console.log({ newNodes, data, prevNode });
    }
    newNodes = newNodes
      .filter((n) => n.id !== node.id && !data?.nodeIds.has(n.id))
      // Remove all "jump to steps" that point to the this node (the one getting deleted)
      .map((n) => {
        if (
          n.data.selectedLastBranchSetting ===
            SelectedLastBranchSetting.JUMP_TO_STEP &&
          n.data.selectedLastBranchSettingValue === node.id
        ) {
          n.data = {
            ...n.data,
            selectedLastBranchSetting:
              SelectedLastBranchSetting.RETURN_TO_PARENT,
            selectedLastBranchSettingValue: "",
          };

          console.log("NODE CHANGED", n);
        }
        if (isChoiceSelected(n)) {
          n.data = {
            ...n.data,
            children: {
              ...n.data.children,
              choices: n.data.children.choices?.map((choice) => {
                if (choice.jumpToStepId === node.id) {
                  choice.jumpToStepId = "";
                  choice.selectedOption = ChoiceActionTypes.CONTINUE;
                }
                return choice;
              }),
            },
          };
        }
        return n;
      });

    reactFlow.setEdges(newEdges);
    reactFlow.setNodes(newNodes);
    setTimeout(() => formatCanvas(), 100);
  };

  const deleteNodeWithRelations = useCallback(
    async (nodeId: NodeParam, keepSourceNode = false) => {
      console.log(nodeId);
      const node = getNode(nodeId);
      if (!node) return;

      if (isChoiceSelected(node)) {
        const branchedNodesIds = node.data.children.choices
          ?.filter((choice) => !!choice.branchId)
          .map((choice) => choice.branchId);
        console.log(branchedNodesIds, node.data.children.choices);
        if (branchedNodesIds)
          await Promise.all(
            branchedNodesIds.map((id) => id && deleteNodeWithRelations(id))
          );
      }

      const allStepEdges = reactFlow
        .getEdges()
        .filter((edge) => edge.source === node.id);
      if (allStepEdges)
        await Promise.all(
          allStepEdges.map((edge) => deleteNodeWithRelations(edge.target))
        );
      if (!keepSourceNode) {
        reactFlow.setNodes(reactFlow.getNodes().filter((n) => n.id !== nodeId));
        reactFlow.setEdges(
          reactFlow
            .getEdges()
            .filter(
              (edge) => !(edge.source === nodeId || edge.target === nodeId)
            )
        );
      }
    },
    [getNode, reactFlow]
  );

  const deleteChoice = (choice: NodeDataChildChoice, nodeId: NodeParam) => {
    const node = getNode(nodeId);
    if (isChoiceSelected(node)) {
      if (choice.branchId) deleteNodeWithRelations(choice.branchId);
      node.data = {
        ...node.data,
        children: {
          ...node.data.children,
          choices: node.data.children.choices?.filter(
            (c) => c.id !== choice.id
          ),
        },
      };
      reactFlow.setNodes(
        reactFlow
          .getNodes()
          .filter((n) => n.id !== nodeId)
          .concat([node])
      );
      setTimeout(() => formatCanvas());
    }
  };
  const deleteAllChoices = (nodeId: NodeParam) => {
    const node = getNode(nodeId);
    if (node && isChoiceSelected(node)) {
      node.data.children.choices.forEach(
        (choice) => choice.branchId && deleteNodeWithRelations(choice.branchId)
      );
      node.data.children.choices = [
        {
          id: nanoid(),
          selectedOption: ChoiceActionTypes.CONTINUE,
          value: "Option",
        },
      ];
      reactFlow.setNodes(
        reactFlow.getNodes().map((n) => (n.id === node.id ? node : n))
      );
      setTimeout(() => formatCanvas());
    }
  };

  const saveModifiedChoiceValue = useCallback(
    (updatedChoice: NodeDataChildChoice, nodeId: string) => {
      const choiceValue = updatedChoice.value.trim();
      const node = reactFlow.getNode(nodeId);
      if (!node || !isChoiceSelected(node)) return;

      const choice = node.data.children.choices?.find(
        (c) => c.id === updatedChoice.id
      );
      if (!choice) return;

      let modifiedNodes: Node<NodeData>[] = [];
      let modifiedNodesIds = new Set<string>();

      if (choice.branchId && choice.value !== updatedChoice.value) {
        const branchedNode = reactFlow.getNode(choice.branchId);
        if (!branchedNode) return;

        branchedNode.data = {
          ...branchedNode.data,
          label: choiceValue,
        };
        modifiedNodesIds.add(branchedNode.id);
        modifiedNodes.push(branchedNode);
      }

      node.data = {
        ...node.data,
        children: {
          ...node.data.children,
          choices: node.data.children.choices?.map((c) => {
            if (c.id === updatedChoice.id) return updatedChoice;
            return c;
          }),
        },
      };
      // updateNode(node.id);
      modifiedNodesIds.add(node.id);
      modifiedNodes.push(node);
      reactFlow.setNodes(
        reactFlow.getNodes().map((node) => {
          if (modifiedNodesIds.has(node.id))
            return modifiedNodes.find((n) => n.id === node.id)!;
          return node;
        })
      );
    },
    [reactFlow]
  );

  const getNumberOfNodeInCol = (_rootNode: NodeParam) => {
    const rootNode = getNode(_rootNode);
    if (!rootNode) return;
    return reactFlow
      .getNodes()
      .filter(
        (node) =>
          node.data.rootNodeId === rootNode.id &&
          node.id !== rootNode.id &&
          node.id !== "4" &&
          node.id !== "3" &&
          node.id !== ABSOLUTE_ROOT_NODE_ID
      ).length;
  };

  const reorderNode = (
    currNodePos: number,
    swapNodePos: number,
    rootNodeId: string
  ) => {
    const node1 = reactFlow
      .getNodes()
      .find(
        (node) =>
          node.data.rootNodeId === rootNodeId && node.data.step === currNodePos
      );
    const node2 = reactFlow
      .getNodes()
      .find(
        (node) =>
          node.data.rootNodeId === rootNodeId && node.data.step === swapNodePos
      );
    if (!node1 || !node2) return;
    if (node1?.id === node2?.id) return;

    let temp: any = node1.position;
    node1.position = { ...node2.position };
    node2.position = { ...temp };
    let tempTarget = {
      hasTargetNode: node1.data.hasTargetNode,
      selectedLastBranchSetting: node1.data.selectedLastBranchSetting,
      selectedLastBranchSettingValue: node1.data.selectedLastBranchSettingValue,
    };
    node1.data = {
      ...node1.data,
      step: swapNodePos,
      hasTargetNode: node2.data.hasTargetNode,
      selectedLastBranchSetting: node2.data.selectedLastBranchSetting,
      selectedLastBranchSettingValue: node2.data.selectedLastBranchSettingValue,
    };
    node2.data = {
      ...node2.data,
      step: currNodePos,
      ...tempTarget,
    };
    const edges = reactFlow.getEdges();
    const node1SourceEdge = edges.find(
      (e) =>
        e.source === node1.id &&
        (e.type === EdgeTypes.BUTTON_EDGE || e.animated)
    );
    const node1TargetEdge = edges.find(
      (e) =>
        e.target === node1.id &&
        (e.type === EdgeTypes.BUTTON_EDGE || e.animated)
    );

    const node2SourceEdge = edges.find(
      (e) =>
        e.source === node2.id &&
        (e.type === EdgeTypes.BUTTON_EDGE || e.animated)
    );
    const node2TargetEdge = edges.find(
      (e) =>
        e.target === node2.id &&
        (e.type === EdgeTypes.BUTTON_EDGE || e.animated)
    );

    if (node1SourceEdge && node2SourceEdge) {
      temp = node1SourceEdge?.source ?? "";
      node1SourceEdge.source = node2SourceEdge?.source ?? "";
      node2SourceEdge.source = temp;
    } else if (!node1SourceEdge && node2SourceEdge) {
      node2SourceEdge.source = node1.id;
    } else if (!node2SourceEdge && node1SourceEdge) {
      node1SourceEdge.source = node2.id;
    }

    if (node1TargetEdge && node2TargetEdge) {
      temp = node1TargetEdge?.target ?? "";
      node1TargetEdge.target = node2TargetEdge?.target ?? "";
      node2TargetEdge.target = temp;
    }

    reactFlow.setNodes(
      reactFlow.getNodes().map((node) => {
        if (node.id === node1.id) return node1;
        if (node.id === node2.id) return node2;
        return node;
      })
    );
    reactFlow.setEdges(
      edges.map((e) => {
        if (e.id === node1SourceEdge?.id) return node1SourceEdge;
        if (e.id === node2SourceEdge?.id) return node2SourceEdge;
        if (e.id === node1TargetEdge?.id) return node1TargetEdge;
        if (e.id === node2TargetEdge?.id) return node2TargetEdge;

        return e;
      })
    );
    setTimeout(() => formatCanvas());
  };

  const moveParentsDown = useCallback(
    (
      _startingNode: NodeParam,
      formattedNodes: Node[],
      lowestPosInHierarchy = 0
    ) => {
      let startingNode: Node<NodeData>;
      if (typeof _startingNode === "string")
        startingNode = formattedNodes.find((n) => n.id === _startingNode)!;
      else startingNode = _startingNode;

      if (!startingNode) return;
      if (startingNode.type === NodeTypes.BRANCHED_NODE) {
      }
      lowestPosInHierarchy = Math.max(
        startingNode.position.y,
        lowestPosInHierarchy
      );

      const s = reactFlow
        .getEdges()
        .find((e) =>
          startingNode.type === NodeTypes.BRANCHED_NODE
            ? e.target === startingNode.id
            : e.target === startingNode.data.rootNodeId
        );
      const parent = formattedNodes.find((n) => n.id === s?.source)!;
      if (!parent?.data?.hasTargetNode) {
        console.log({ parent });
        moveParentsDown(parent, formattedNodes, lowestPosInHierarchy);
        return;
      }

      const parentSiblingId = reactFlow
        .getEdges()
        .find(
          (e) => !e.data?.isTargetBranchNode && e.source === parent.id
        )?.target;
      if (!parentSiblingId) return;
      const parentSibling = formattedNodes.find(
        (node) => node.id === parentSiblingId
      )!; //getNode(parentSiblingId);
      if (!parentSibling) return;

      if (parentSibling.position.y - lowestPosInHierarchy - 450 > Y_GAP) {
        adjustNodePos(
          formattedNodes,
          reactFlow.getEdges(),
          parentSibling,
          0,
          -parentSibling.position.y + lowestPosInHierarchy + Y_GAP
        );
      } else if (
        lowestPosInHierarchy + 450 + Y_GAP >
        parentSibling.position.y
      ) {
        adjustNodePos(
          formattedNodes,
          reactFlow.getEdges(),
          parentSibling,
          0,
          lowestPosInHierarchy + Y_GAP - parentSibling.position.y
        );
      }

      moveParentsDown(parentSibling, formattedNodes, lowestPosInHierarchy);
    },
    [adjustNodePos, reactFlow]
  );
  const buildOutcomeNodes = useCallback(
    (outcomesArr: Array<IOutcome | SingleOutcomeType | undefined>) => {
      const nodes: Node<NodeData>[] = [];
      const edges: Edge<EdgeData>[] = [];
      const outcomeNode = reactFlow.getNode("4")!;
      let pos = 1;
      let col = 0;
      console.log({ outcomesArr });
      outcomesArr?.forEach((outcome, i) => {
        if (!outcome) return;
        const nodeDataId = outcome.nodeData?.id ?? nanoid();
        nodes.push({
          id: outcome.id,
          position: {
            x: col * X_GAP * pos,
            y: outcomeNode.position.y + Y_GAP * 0.4,
          },
          type: NodeTypes.BRANCHED_NODE,
          data: {
            label: outcome.label,
            rootNodeId: outcomeNode.id,
            children: { type: NodeDataChildType.NONE },
            hasTargetNode: false,
            isOutcomeNode: true,
            step: 0,
          },
        });
        edges.push(
          edgeStyles({
            id: nanoid(),
            source: outcomeNode.id,
            target: outcome.id,
            targetHandle: "outcomeHandle",
            type: "step",
          })
        );

        if (outcome.type === "flow" && "nodeData" in outcome) {
          nodes.push({
            id: nodeDataId,
            type: NodeTypes.CARD,
            data: {
              label: outcome.nodeData?.title ?? "",
              children: {
                type: NodeDataChildType.CHOICES,
                choices: outcome.nodeData?.ctaLabels?.map((label) => ({
                  id: label.id,
                  selectedOption: ChoiceActionTypes.CONTINUE,
                  value: label.value,
                  redirectURL: label.url,
                })),
              },
              isOutcomeNode: true,
              hasTargetNode: false,
              rootNodeId: outcome.id,
              isWebinarMode: outcome.isWebinarMode,
              video: outcome.nodeData?.video,
              thumbnail: outcome.nodeData?.thumbnail,
              videoID: outcome.nodeData?.videoID,
              delay: outcome.delay,
              step: 1,
            },
            position: {
              x: col * X_GAP * pos + 0.5,
              y: outcomeNode.position.y + Y_GAP * 2 * 0.4,
            },
          });
          edges.push(
            edgeStyles({
              id: nanoid(),
              source: outcome.id,
              target: nodeDataId,
              // targetHandle: "",
              type: "step",
            })
          );
        } else {
          const nodeDataId = nanoid();
          nodes.push({
            id: nodeDataId,
            type: NodeTypes.URL_OUTCOME_NODE,
            data: {
              customSmallLabel: "",
              label: outcome.redirectURL ?? "",
              children: { type: NodeDataChildType.NONE },
              isOutcomeNode: true,
              hasTargetNode: false,
              rootNodeId: outcome.id,
              step: 1,
            },
            position: {
              x: col * X_GAP * pos + 0.5,
              y: outcomeNode.position.y + Y_GAP * 2 * 0.4,
            },
          });
          edges.push(
            edgeStyles({
              id: nanoid(),
              source: outcome.id,
              target: nodeDataId,
              // targetHandle: "",
              type: "step",
            })
          );
        }

        if (pos === 1) col++;
        pos *= -1;
      });
      const allNodesWithOutcome = reactFlow
        .getNodes()
        .filter((n) => n.data.isOutcomeNode)
        .map((n) => n.id);
      reactFlow.setNodes(
        reactFlow
          .getNodes()
          .filter(
            (n) =>
              !(
                n.data.isOutcomeNode ||
                allNodesWithOutcome.includes(n.data.rootNodeId)
              )
          )
          .concat(nodes)
      );
      reactFlow.setEdges(
        reactFlow
          .getEdges()
          .filter(
            (e) =>
              !(
                allNodesWithOutcome.includes(e.target) ||
                allNodesWithOutcome.includes(e.source)
              )
          )
          .concat(edges)
      );
    },
    [reactFlow]
  );

  const formatCanvas = useCallback(() => {
    const { nodes, edges } = formatCanvasUtil(reactFlow);

    reactFlow.setNodes(nodes);
    reactFlow.setEdges(edges);
  }, [reactFlow]);

  const deleteBranchNode = useCallback(
    (_branchNode: NodeParam) => {
      const branchNode = getNode(_branchNode);
      const parentNode = reactFlow
        .getNodes()
        .find((n) => n.id === branchNode?.data.derivedFromNodeId);
      if (
        !parentNode ||
        parentNode.data.children.type !== NodeDataChildType.CHOICES ||
        !branchNode
      )
        return;
      const choice = parentNode.data.children.choices?.find(
        (c) => c.branchId === branchNode.id
      );
      if (!choice) return;
      choice.branchId = undefined;
      parentNode.data = {
        ...parentNode.data,
        children: {
          ...parentNode.data.children,
          choices: parentNode.data.children.choices?.map((c) => {
            if (c.id === choice.id) return choice;
            return c;
          }),
        },
      };
      deleteNodeWithRelations(branchNode.id);
      reactFlow.setNodes(
        reactFlow
          .getNodes()
          .map((n) => (n.id === parentNode.id ? parentNode : n))
      );
      setTimeout(formatCanvas, 800);
    },
    [deleteNodeWithRelations, formatCanvas, getNode, reactFlow]
  );

  const timer = (ms: number) => new Promise((res) => setTimeout(res, ms));

  const duplicateNode = async (_node: NodeParam) => {
    const sourceNode = getNode(_node);
    if (!sourceNode) return;
    // Find if the sourceNode has sourceEdge, i-e it is in between nodes. If yes, add new node after source node in the chain
    const sourceEdgeOfSourceNode = reactFlow
      .getEdges()
      .find(
        (e) => e.source === sourceNode.id && e.type === EdgeTypes.BUTTON_EDGE
      );
    let duplicatedNode: Node<NodeData> | undefined;
    const duplicatedNodeProps: Node<NodeData> = {
      ...sourceNode,
      data: {
        ...sourceNode.data,
        "server-id": undefined,
        children: {
          ...sourceNode.data.children,
          type: sourceNode.data.children.type,
          choices: (sourceNode.data.children as any)?.choices?.map(
            (c: any) => ({
              ...c,
              id: nanoid(),
              "server-id": undefined,
            })
          ),
        },
      },
    };
    if (sourceEdgeOfSourceNode)
      duplicatedNode = addNodeInBetween(
        sourceEdgeOfSourceNode.id,
        duplicatedNodeProps
      );
    else duplicatedNode = addNodeAtEnd(sourceNode, duplicatedNodeProps);

    await timer(10);
    if (duplicatedNode) {
      const hierachy = getHierachy(sourceNode, sourceNode, true);
      console.log({ hierachy });
      if (hierachy?.edgeIds.size && hierachy?.nodeIds.size) {
        console.log(hierachy);
        const { nodeIds, edgeIds } = hierachy;
        let nodes = JSON.parse(
          JSON.stringify(Array.from(nodeIds))
        ) as Node<NodeData>[];
        let edges = JSON.parse(
          JSON.stringify(Array.from(edgeIds))
        ) as Edge<EdgeData>[];
        const nodeIdsMap: Record<string, string> = nodes.reduce(
          (state, n) => ({ ...state, [n.id]: nanoid() }),
          {
            [sourceNode.id]: duplicatedNode.id,
          }
        );

        console.log(nodes, edges, nodeIdsMap);
        await timer(10);
        nodes = nodes.map((node) => {
          node.position.x += X_GAP;
          node.position.y += Y_GAP * 2;
          node.data = {
            ...node.data,
            "server-id": undefined,
            children: {
              type: node.data.children.type,
              choices: (node.data.children as any)?.choices?.map((c: any) => ({
                ...c,
                id: nanoid(),
                "server-id": undefined,
              })),
            },
          };
          if (nodeIdsMap[node.data.rootNodeId])
            node.data.rootNodeId = nodeIdsMap[node.data.rootNodeId];
          if (
            node.data.derivedFromNodeId &&
            nodeIdsMap[node.data.derivedFromNodeId]
          )
            node.data.derivedFromNodeId =
              nodeIdsMap[node.data.derivedFromNodeId];
          if (isChoiceSelected(sourceNode)) {
            const branchNodeFromSourceNodeOptions =
              sourceNode.data.children?.choices?.find(
                (c) => c.branchId === node.id
              );
            if (
              branchNodeFromSourceNodeOptions &&
              isChoiceSelected(duplicatedNode)
            ) {
              const duplicatedChoice =
                duplicatedNode.data.children.choices?.find(
                  (c) => c.branchId === node.id
                );

              if (!duplicatedChoice) return node;
              node.data = {
                ...node.data,
                brachedFromChoice: duplicatedChoice.id,
              };
              duplicatedChoice.branchId = nodeIdsMap[node.id];
              console.log("NEW NODE DATA", duplicatedChoice, node.data);
              duplicatedNode.data = {
                ...duplicatedNode.data,
                "server-id": undefined,
                children: {
                  ...duplicatedNode.data.children,
                  choices: duplicatedNode.data.children.choices
                    ?.filter((c) => c.id !== duplicatedChoice.id)
                    .concat(duplicatedChoice)
                    .map((c) => ({ ...c, "server-id": null })),
                },
              };
            }
          }

          if (nodeIdsMap[node.id]) node.id = nodeIdsMap[node.id];
          return node;
        });
        await timer(10);
        edges.forEach((edge) => {
          edge.id = nanoid();
          if (nodeIdsMap[edge.source]) edge.source = nodeIdsMap[edge.source];

          if (nodeIdsMap[edge.target]) edge.target = nodeIdsMap[edge.target];
          return edge;
        });

        await timer(10);
        console.log(nodes, edges);
        reactFlow.setNodes(reactFlow.getNodes().concat(nodes));
        reactFlow.setEdges(reactFlow.getEdges().concat(edges));
      }
    }
    setTimeout(formatCanvas);
  };

  const toggleOptionBranches = (
    choice: NodeDataChildChoice,
    _parentNode: NodeParam
  ) => {
    const branchNode = getNode(choice.branchId ?? "");
    if (!choice.branchId || !branchNode) return;
    const hierachy = getHierachy(branchNode);
    if (!hierachy) return;
    const { edgeIds, nodeIds } = hierachy;

    const shouldShowHierachy =
      choice.selectedOption === ChoiceActionTypes.BRANCH;
    reactFlow.setNodes(
      reactFlow.getNodes().map((node) => {
        if (nodeIds.has(node.id)) node.hidden = shouldShowHierachy;

        return node;
      })
    );

    reactFlow.setEdges(
      reactFlow.getEdges().map((edge) => {
        if (edgeIds.has(edge.id)) edge.hidden = shouldShowHierachy;

        return edge;
      })
    );
  };

  const buildQuestionList = () => {
    const questionList: Question[] = [];
    const flatQuestionList: Question[] = [];
    function traverseHierarchy(nodeId?: string, currParent?: Question[]) {
      const node = reactFlow.getNode(
        nodeId ??
          reactFlow.getEdges().find((e) => e.source === ABSOLUTE_ROOT_NODE_ID)
            ?.target!
      );

      if (
        !node ||
        node.type === NodeTypes.OPTIN_NODE ||
        node.data.isOutcomeNode
      )
        return;

      currParent = currParent ?? questionList;
      const currQuestion: Question = {
        branches: [],
        id: node.id,
        label: node.data.label ?? "",
        isOpen: false,
        isActive: false,
        hierarchyId: "",
        answers:
          isChoiceSelected(node) && Array.isArray(node.data.children.choices)
            ? node.data.children.choices.map((choice) => ({
                id: choice.id,
                label: choice.value,

                choiceId: choice.id,
                questionId: node.id,
                questionHierarchyId: "",
              }))
            : [],
      };
      flatQuestionList.push(currQuestion);
      reactFlow.getEdges().forEach((e) => {
        if (e.source !== node.id) return;
        if (e.type === EdgeTypes.BUTTON_EDGE)
          traverseHierarchy(e.target, currParent);

        if (e.type !== EdgeTypes.BUTTON_EDGE && e.data?.isTargetBranchNode) {
          const branchNode = reactFlow.getNode(e.target)!;
          console.log({ branchNode });
          const firstNodeIdOfBranch = reactFlow
            .getEdges()
            .find(
              (ed) =>
                ed.source === branchNode.id && ed.type === EdgeTypes.BUTTON_EDGE
            )?.target;

          traverseHierarchy(firstNodeIdOfBranch, currQuestion.branches);
        }
      });
      currParent.push(currQuestion);
    }
    traverseHierarchy();
    return {
      flatQuestionList: flatQuestionList.reverse(),
      questionList: questionList.reverse(),
    };
  };

  const getMapOutcomeQuestionListFromNode = useCallback(
    (
      _node: NodeParam,
      parentHierarchyId: string = "",
      flatList: Question[] = []
    ): Question[] => {
      const node = getNode(_node);
      /* ((_n) => {
        let n = getNode(_n);
        if (n?.type === NodeTypes.BRANCHED_NODE)
          n = getNode(reactFlow.getEdges().find((e) => e.source === n?.id)?.target ?? "");
        return n;
      })(_node);*/

      if (!node) return [];
      const questions: Question[] = [];

      const question: Question = {
        label: node.data.label ?? "",
        hierarchyId: parentHierarchyId
          ? `${parentHierarchyId}.${node.id}`
          : node.id,
        isActive: false,
        answers: [],
        branches: [],
        isOpen: false,
        id: node.id,
      };
      questions.push(question);
      flatList.push(question);
      if (isChoiceSelected(node) && Array.isArray(node.data.children.choices)) {
        question.answers = node.data.children.choices.map((choice) => ({
          choiceId: choice.id,
          questionId: node.id,
          questionHierarchyId: question.hierarchyId,
        }));

        question.branches = node.data.children.choices
          .filter((choice) => Boolean(choice.branchId))
          .map(
            (choice, i) =>
              (choice.branchId &&
                getMapOutcomeQuestionListFromNode(
                  choice.branchId,
                  question.hierarchyId,
                  flatList
                )) as Question[]
          )
          .reduce((prev, curr) => {
            return prev!.concat(curr);
          }, []);
      } else if (node.type !== NodeTypes.BRANCHED_NODE) {
        question.label += ` (${NodeTypesDisplayMap[node.data.children.type]})`;
      }

      if (node.type === NodeTypes.BRANCHED_NODE) {
        let targetNodeEdge = reactFlow
          .getEdges()
          .find((e) => e.source === node.id && !e.data?.isTargetBranchNode);
        // The question is a child of some question
        while (!!targetNodeEdge) {
          const targetNode = reactFlow.getNode(targetNodeEdge.target);
          if (
            !targetNode ||
            targetNode.data.isOutcomeNode ||
            targetNode.type === NodeTypes.OPTIN_NODE
          )
            break;

          const targetNodeToQuestion = getMapOutcomeQuestionListFromNode(
            targetNode,
            question.hierarchyId,
            flatList
          );
          if (!targetNodeToQuestion) break;

          question.branches.push(...targetNodeToQuestion);
          targetNodeEdge = reactFlow
            .getEdges()
            .find(
              (e) => e.source === targetNode?.id && !e.data?.isTargetBranchNode
            );
        }
      }
      return questions;
    },
    [getNode, reactFlow]
  );

  const buildQuestionList2 = useCallback((): [Question[], Question[]] => {
    const list: Question[] = [];
    const flatList: Question[] = [];
    let startNode = getNode(
      reactFlow.getEdges().find((e) => e.source === ABSOLUTE_ROOT_NODE_ID)!
        .target!
    );

    if (
      !startNode ||
      startNode.data.isOutcomeNode ||
      startNode.type === NodeTypes.OPTIN_NODE
    )
      return [[], []];

    const question = getMapOutcomeQuestionListFromNode(startNode, "", flatList);

    list.push(...question);

    let targetNodeEdge = reactFlow
      .getEdges()
      .find((e) => e.source === startNode?.id && !e.data?.isTargetBranchNode);

    while (!!targetNodeEdge) {
      const targetNode = reactFlow.getNode(targetNodeEdge.target);
      if (
        !targetNode ||
        targetNode.data.isOutcomeNode ||
        targetNode.type === NodeTypes.OPTIN_NODE
      )
        break;

      const targetNodeToQuestion = getMapOutcomeQuestionListFromNode(
        targetNode,
        "",
        flatList
      );
      if (!targetNodeToQuestion) break;

      list.push(...targetNodeToQuestion);
      targetNodeEdge = reactFlow
        .getEdges()
        .find(
          (e) => e.source === targetNode?.id && !e.data?.isTargetBranchNode
        );
    }

    return [list, flatList];
  }, [getMapOutcomeQuestionListFromNode, getNode, reactFlow]);

  const addBranchedNode = useCallback(
    (choice: NodeDataChildChoice, sourceNodeId: NodeParam) => {
      const sourceNode = getNode(sourceNodeId);
      if (
        !sourceNode ||
        sourceNode.data.children.type !== NodeDataChildType.CHOICES
      )
        return;
      const sourceNodePos = sourceNode.position;

      const newBranchNodeId = nanoid();
      const newChoice: typeof choice = {
        ...choice,
        branchId: newBranchNodeId,
        redirectURL: undefined,
        jumpToStepId: undefined,
        selectedOption: ChoiceActionTypes.BRANCH,
      };
      saveNodeData(
        {
          children: {
            type: NodeDataChildType.CHOICES,
            choices: sourceNode.data.children.choices!.map((c) => {
              if (c.id === newChoice.id) return newChoice;
              return c;
            }),
          },
        },
        sourceNode.id
      );
      const targetNodeId = reactFlow
        .getEdges()
        .find(
          (e) => e.type === EdgeTypes.BUTTON_EDGE && e.source === sourceNode.id
        )?.target;
      reactFlow.addNodes({
        type: NodeTypes.BRANCHED_NODE,
        data: {
          step: 0,
          label: choice.value,
          rootNodeId: newBranchNodeId,
          brachedFromChoice: choice.id,
          derivedFromNodeId: sourceNode.id,
          children: { type: NodeDataChildType.NONE },
          hasTargetNode: true,
        },
        id: newBranchNodeId,
        position: { x: 400, y: sourceNodePos.y },
      });
      reactFlow.addEdges(
        edgeStyles({
          source: sourceNode.id,
          target: newBranchNodeId,
          id: nanoid(),
          // animated: true,
          sourceHandle: "branchHandle",
          targetHandle: "defaultHandle",
          labelBgPadding: [20, 20],
          type: "step",
          data: { isTargetBranchNode: true },
        })
      );
      setTimeout(() => {
        addNodeAtEnd(newBranchNodeId, {
          data: {
            children: { type: NodeDataChildType.CHOICES, choices: choices() },
            rootNodeId: newBranchNodeId,
            hasTargetNode: false,
            label: "New step",
            step: 0,
            selectedLastBranchSetting:
              SelectedLastBranchSetting.RETURN_TO_PARENT,
            selectedLastBranchSettingValue: targetNodeId,
          },
        });
        // if (!newNode) return;
        // setTimeout(() => {
        //   const targetNodeId = reactFlow
        //     .getEdges()
        //     .find(
        //       (e) =>
        //         e.source === sourceNode.id && e.type === EdgeTypes.BUTTON_EDGE
        //     )?.target;
        //   if (!targetNodeId) {
        //     alert("SDSDS");
        //     return;
        //   }
        //   reactFlow.addEdges(
        //     edgeStyles({
        //       source: newNode.id,
        //       target: targetNodeId,
        //       id: nanoid(),
        //       sourceHandle: "bottomBranchSource",
        //       targetHandle: "rightBranchTarget",
        //       // labelBgPadding: [20, 20],
        //       type: "step",
        //     })
        //   );
        // });
      });
      setTimeout(() => formatCanvas(), 100);
    },
    [addNodeAtEnd, formatCanvas, getNode, reactFlow, saveNodeData]
  );

  const deleteNodeInBetweenWithRelationsMutable = useCallback(
    async (
      nodeId: NodeParam,
      nodes: Node<NodeData>[],
      edges: Edge<EdgeData>[],
      keepSourceNode = false
    ) => {
      console.log(nodeId);
      const node = getNode(nodeId);
      if (!node) return;

      if (isChoiceSelected(node)) {
        const branchedNodesIds = node.data.children.choices
          ?.filter((choice) => !!choice.branchId)
          .map((choice) => choice.branchId);
        if (branchedNodesIds)
          await Promise.all(
            branchedNodesIds.map(
              (id) =>
                id && deleteNodeInBetweenWithRelationsMutable(id, nodes, edges)
            )
          );
      }

      const allStepEdges = edges.filter((edge) => edge.source === node.id);
      if (allStepEdges)
        await Promise.all(
          allStepEdges.map((edge) =>
            deleteNodeInBetweenWithRelationsMutable(edge.target, nodes, edges)
          )
        );
      if (!keepSourceNode) {
        nodes = nodes.filter((n) => n.id !== nodeId);
        edges = edges.filter(
          (edge) => !(edge.source === nodeId || edge.target === nodeId)
        );
      }
      console.log("IN THE HOOK: ", nodes);
    },
    [getNode]
  );
  return {
    addNodeInBetween,
    saveNodeData,
    getNodeData,
    addNodeAtEnd,
    addBranchedNode,
    deleteChoice,
    getNumberOfNodeInCol,
    reorderNode,
    saveModifiedChoiceValue,
    deleteNodeInBetweenWithRelations,
    formatCanvas,
    duplicateNode,
    deleteBranchNode,
    toggleOptionBranches,
    getHierachy,
    getNode,
    getEdge,
    buildOutcomeNodes,
    buildQuestionList2,
    buildQuestionList,
    deleteAllChoices,
    deleteNodeInBetweenWithRelationsMutable,
  };
}

export default useCanvasFunctions;
