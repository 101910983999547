import { createContext, useState } from "react";
import { StoreApi, useStore } from "zustand";

import {
  MainNodeSettingsState,
  mainNodeSettingsStore,
  NODE_SETTINGS_PAGE,
} from "./MainNodeSettingStore";

import { MainNodeSettingsHome } from "./MainNodeSettingsHome";
import { MainNodeSettingsInputType } from "./MainNodeSettingsInputType";
import { MainNodeSettingsReorder } from "./MainNodeSettingsReorder";
import { MainNodeSettingsDelay } from "./MainNodeSettingsDelay";
import { MainNodeSettingsDelete } from "./MainNodeSettingsDelete";
import { TooltipMenu } from "common/components/TooltipMenu";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { MainNodeSettingsVideo } from "./MainNodeSettingsVideo";
import { MainNodeSettingsOpenEnded } from "./MainNodeSettingsOpenEnded";

interface MainNodeSettingsUIProps {}

interface MainNodeSettingsContextType {
  store: StoreApi<MainNodeSettingsState>;
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

export const MainNodeSettingsContext = createContext(
  {} as MainNodeSettingsContextType
);

export const MainNodeSettingsUI = (_: MainNodeSettingsUIProps) => {
  const [open, setOpen] = useState(false);
  const currentPage = useStore(
    mainNodeSettingsStore,
    (state) => state.currentPage
  );
  const setCurrentPage = useStore(
    mainNodeSettingsStore,
    (state) => state.setCurrentPage
  );

  const handleTooltipOpen = (open: boolean) => {
    setOpen(open);
    if (!open) setCurrentPage(NODE_SETTINGS_PAGE.HOME);
  };

  return (
    <TooltipMenu open={open} onOpenChange={handleTooltipOpen}>
      <TooltipMenu.Trigger className="main-node-settings-btn">
        <FontAwesomeIcon fixedWidth icon={solid("cog")} />
      </TooltipMenu.Trigger>

      <TooltipMenu.Content>
        <MainNodeSettingsContext.Provider
          value={{ store: mainNodeSettingsStore, open, setOpen }}
        >
          {currentPage === NODE_SETTINGS_PAGE.HOME && <MainNodeSettingsHome />}
          {currentPage === NODE_SETTINGS_PAGE.VIDEO_SETTINGS && (
            <MainNodeSettingsVideo />
          )}

          {currentPage === NODE_SETTINGS_PAGE.CHANGE_INPUT && (
            <MainNodeSettingsInputType />
          )}
          {currentPage === NODE_SETTINGS_PAGE.DELAY && (
            <MainNodeSettingsDelay />
          )}
          {currentPage === NODE_SETTINGS_PAGE.REORDER && (
            <MainNodeSettingsReorder />
          )}
          {currentPage === NODE_SETTINGS_PAGE.DELETE && (
            <MainNodeSettingsDelete />
          )}

          {currentPage === NODE_SETTINGS_PAGE.OPEN_ENDED_OPTIONS && (
            <MainNodeSettingsOpenEnded />
          )}
        </MainNodeSettingsContext.Provider>
      </TooltipMenu.Content>
    </TooltipMenu>
  );
};
