import { createStore, StoreApi, useStore } from "zustand";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";

import { TooltipMenu } from "common/components/TooltipMenu";
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { Listbox } from "@headlessui/react";
import { InputTextField } from "common/components/InputTextField/InputTextField";
import {
  ChoiceActionTypes,
  NodeData,
  NodeDataChildChoice,
  NodeDataChildType,
  NodeTypes,
  SelectedLastBranchSetting,
} from "modules/canvas/data/types";
import useCanvasFunctions from "modules/canvas/useCanvasFunctions";
import { currentNodeContext } from "modules/canvas/currentNodeContext";
import { Node, useReactFlow } from "react-flow-renderer";
import { ABSOLUTE_ROOT_NODE_ID } from "modules/canvas/data/initialData";
import { nanoid } from "nanoid";
import { isValidURL } from "modules/canvas/utils";

enum CHOICE_SETTING_PAGE {
  HOME = "home",
  REDIRECT_URL = "redirect_url",
  JUMP_STEP = "jump_step",
}

interface ChoiceSettingsState {
  currentPage: CHOICE_SETTING_PAGE;
  setCurrentPage: (page: CHOICE_SETTING_PAGE) => void;
}

interface ChoiceSettingsContextType {
  store: StoreApi<ChoiceSettingsState>;
  open: boolean;
  currentChoice: NodeDataChildChoice;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const ChoiceSettingsStore = createStore<ChoiceSettingsState>((set) => ({
  currentPage: CHOICE_SETTING_PAGE.HOME,
  setCurrentPage: (page) => set((state) => ({ ...state, currentPage: page })),
}));

const ChoiceeSettingsContext = createContext({} as ChoiceSettingsContextType);

export const MainNodeChoiceSettings: React.FC<{
  choice: NodeDataChildChoice;
}> = React.memo(({ choice }) => {
  const [open, setOpen] = useState(false);

  const { currentPage, setCurrentPage } = useStore(ChoiceSettingsStore);
  const { deleteBranchNode } = useCanvasFunctions();

  const handleTooltipOpen = (open: boolean) => {
    if (!open) {
      setCurrentPage(CHOICE_SETTING_PAGE.HOME);
    }

    // if (choice.selectedOption === ChoiceActionTypes.JUMP_TO_STEP && !open) deleteBranchNode(choice.branchId ?? "");
    setOpen(open);
  };

  return (
    <TooltipMenu open={open} onOpenChange={handleTooltipOpen}>
      <TooltipMenu.Trigger
        className="main-node-choice__action"
        aria-label="Choice Settings"
      >
        <FontAwesomeIcon fixedWidth icon={solid("cog")} />
      </TooltipMenu.Trigger>
      <TooltipMenu.Content>
        <ChoiceeSettingsContext.Provider
          value={{
            store: ChoiceSettingsStore,
            open,
            setOpen,
            currentChoice: choice,
          }}
        >
          {currentPage === CHOICE_SETTING_PAGE.HOME && (
            <MainNodeChoiceSettingsHome />
          )}

          {currentPage === CHOICE_SETTING_PAGE.REDIRECT_URL && (
            <MainNodeChoiceSettingsRedirect />
          )}

          {currentPage === CHOICE_SETTING_PAGE.JUMP_STEP && (
            <MainNodeChoiceSettingsJump />
          )}
        </ChoiceeSettingsContext.Provider>
      </TooltipMenu.Content>
    </TooltipMenu>
  );
});

const MainNodeChoiceSettingsHome = () => {
  const { store, setOpen, currentChoice } = useContext(ChoiceeSettingsContext);
  const { setCurrentPage } = useStore(store);
  const { saveModifiedChoiceValue, deleteBranchNode } = useCanvasFunctions();
  const { id } = useContext(currentNodeContext);

  const setContinueStep = () => {
    const newChoice: NodeDataChildChoice = {
      ...currentChoice,
      selectedOption: ChoiceActionTypes.CONTINUE,
      branchId: undefined,
      redirectURL: undefined,
      jumpToStepId: undefined,
    };

    if (currentChoice.branchId) deleteBranchNode(currentChoice.branchId);
    saveModifiedChoiceValue(newChoice, id);
  };
  return (
    <>
      <TooltipMenu.Header>
        <span>When Clicked</span>
      </TooltipMenu.Header>
      <TooltipMenu.Options>
        <TooltipMenu.Option
          disabled={!!currentChoice.branchId}
          onClick={() => setContinueStep()}
        >
          <TooltipMenu.OptionIcon>
            <FontAwesomeIcon fixedWidth icon={solid("right")} />
          </TooltipMenu.OptionIcon>
          <span>Continue Flow</span>
          {currentChoice.selectedOption === ChoiceActionTypes.CONTINUE && (
            <div className="main-node-choice__active-setting">
              <FontAwesomeIcon fixedWidth icon={solid("check")} />
            </div>
          )}
        </TooltipMenu.Option>
        <TooltipMenu.Option
          disabled={!!currentChoice.branchId}
          onClick={() => setCurrentPage(CHOICE_SETTING_PAGE.REDIRECT_URL)}
        >
          <TooltipMenu.OptionIcon>
            <FontAwesomeIcon fixedWidth icon={solid("link")} />
          </TooltipMenu.OptionIcon>
          <span>Redirect to URL</span>
          {currentChoice.selectedOption === ChoiceActionTypes.REDIRECT && (
            <div className="main-node-choice__active-setting">
              <FontAwesomeIcon fixedWidth icon={solid("check")} />
            </div>
          )}
        </TooltipMenu.Option>
        <TooltipMenu.Option
          disabled={!!currentChoice.branchId}
          onClick={() => setCurrentPage(CHOICE_SETTING_PAGE.JUMP_STEP)}
        >
          <TooltipMenu.OptionIcon>
            <FontAwesomeIcon fixedWidth icon={solid("up-right-from-square")} />
          </TooltipMenu.OptionIcon>
          <span>Jump to Step</span>
          {currentChoice.selectedOption === ChoiceActionTypes.JUMP_TO_STEP && (
            <div className="main-node-choice__active-setting">
              <FontAwesomeIcon fixedWidth icon={solid("check")} />
            </div>
          )}
        </TooltipMenu.Option>
      </TooltipMenu.Options>
      <TooltipMenu.Footer>
        <TooltipMenu.ActionButton
          onClick={() => {
            setCurrentPage(CHOICE_SETTING_PAGE.HOME);
            setOpen(false);
          }}
        >
          Done
        </TooltipMenu.ActionButton>
      </TooltipMenu.Footer>
    </>
  );
};

const MainNodeChoiceSettingsRedirect = () => {
  const { store, setOpen, currentChoice } = useContext(ChoiceeSettingsContext);
  const [url, setUrl] = useState(currentChoice.redirectURL ?? "");
  const { setCurrentPage } = useStore(store);
  const { saveModifiedChoiceValue, deleteBranchNode } = useCanvasFunctions();
  const { id } = useContext(currentNodeContext);
  const [isUrlValid, setIsUrlValid] = useState(true);

  const handleSaveButton = () => {
    setRedirectURL(url);
    setCurrentPage(CHOICE_SETTING_PAGE.HOME);
    setOpen(false);
  };

  const setRedirectURL = (url: string) => {
    const newChoice: NodeDataChildChoice = {
      ...currentChoice,
      redirectURL: url,
      selectedOption: ChoiceActionTypes.REDIRECT,
      branchId: undefined,
      jumpToStepId: undefined,
    };
    if (currentChoice.branchId) deleteBranchNode(currentChoice.branchId);
    saveModifiedChoiceValue(newChoice, id);
  };
  useEffect(() => {
    setUrl(currentChoice.redirectURL ?? "");
  }, [currentChoice.redirectURL]);
  return (
    <>
      <TooltipMenu.Header>
        <TooltipMenu.BackNav
          onClick={() => setCurrentPage(CHOICE_SETTING_PAGE.HOME)}
        />
        <span>Redirect To URL</span>
      </TooltipMenu.Header>
      <TooltipMenu.Body>
        <InputTextField
          hasError={!isUrlValid}
          variant="small"
          value={url}
          onChange={(e) => {
            setUrl(e.target.value);
            setIsUrlValid(isValidURL(e.target.value));
          }}
        />
      </TooltipMenu.Body>
      <TooltipMenu.Footer>
        <TooltipMenu.ActionButton
          disabled={!isUrlValid}
          onClick={handleSaveButton}
        >
          Save
        </TooltipMenu.ActionButton>
      </TooltipMenu.Footer>
    </>
  );
};

const MainNodeChoiceSettingsJump = React.memo(() => {
  const { store, setOpen, currentChoice } = useContext(ChoiceeSettingsContext);
  const { setCurrentPage } = useStore(store);
  const { saveModifiedChoiceValue, getHierachy, deleteBranchNode } =
    useCanvasFunctions();
  const [jumpToStepVal, setJumpToStepVal] = useState<{
    label: string;
    id: string;
    unavailable?: boolean | undefined;
  }>({
    label: "None Selected",
    id: nanoid(),
    unavailable: true,
  });
  const { id } = useContext(currentNodeContext);
  const reactFlow = useReactFlow<NodeData>();

  const allNodes = useMemo(() => {
    const steps: { label: string; id: string }[] = [];
    const hierarchy = getHierachy(currentChoice.branchId ?? "");
    reactFlow.getNodes().forEach((node) => {
      if (node.type === NodeTypes.BRANCHED_NODE) return;
      if (node.id === ABSOLUTE_ROOT_NODE_ID) return;
      if (node.type === NodeTypes.OUTCOME_NODE) return;
      if (node.id === id) return;
      if (node.data.isOutcomeNode) return;
      if (hierarchy && hierarchy.nodeIds.has(node.id)) return;
      steps.push({
        id: node.id,
        label:
          node.id === "3" ? "Optin Screen" : node.data.customSmallLabel ?? "",
      });
    });
    return steps;
  }, [currentChoice.branchId, getHierachy, id, reactFlow]);
  const save = (data: { label: string; id: any }) => {
    console.log({ data });
    const newChoice: NodeDataChildChoice = {
      ...currentChoice,
      selectedOption: ChoiceActionTypes.JUMP_TO_STEP,
      jumpToStepId: data.id,
      branchId: undefined,
      redirectURL: undefined,
    };
    if (currentChoice.branchId) deleteBranchNode(currentChoice.branchId);
    saveModifiedChoiceValue(newChoice, id);
  };

  const handleSaveButton = () => {
    // if (currentChoice.selectedOption === ChoiceActionTypes.JUMP_TO_STEP) deleteBranchNode(currentChoice.branchId ?? "");
    setCurrentPage(CHOICE_SETTING_PAGE.HOME);
    save(jumpToStepVal);
    setOpen(false);
  };

  const currentStep = useMemo(() => {
    const jumpToStepNode = reactFlow.getNode(currentChoice.jumpToStepId ?? "");
    if (
      currentChoice.jumpToStepId &&
      jumpToStepNode &&
      currentChoice.selectedOption === ChoiceActionTypes.JUMP_TO_STEP
    ) {
      return {
        label:
          jumpToStepNode.id === "3"
            ? "Optin Screen"
            : jumpToStepNode?.data.customSmallLabel ?? "",
        id: jumpToStepNode?.id ?? "",
      };
    } else {
      return {
        label: "None Selected",
        id: nanoid(),
        unavailable: true,
      };
    }
  }, [currentChoice.jumpToStepId, currentChoice.selectedOption, reactFlow]);

  useEffect(() => {
    setJumpToStepVal(currentStep);
  }, [currentStep]);
  // const setJumpToStep = (nodeId: string) => {
  //   const newChoice: NodeDataChildChoice = {
  //     ...currentChoice,
  //     selectedOption: ChoiceActionTypes.JUMP_TO_STEP,
  //     jumpToStepId: nodeId,
  //     branchId: undefined,
  //     redirectURL: undefined,
  //   };
  //   saveModifiedChoiceValue(newChoice, id);
  //   setOpen(true);
  // };

  return (
    <>
      <TooltipMenu.Header>
        <TooltipMenu.BackNav
          onClick={() => setCurrentPage(CHOICE_SETTING_PAGE.HOME)}
        />
        <span>Jump to Step</span>
      </TooltipMenu.Header>
      <TooltipMenu.Body>
        <Listbox value={jumpToStepVal} onChange={(v) => setJumpToStepVal(v)}>
          <div className="listbox__wrapper">
            <Listbox.Button className="listbox__button">
              <span className="listbox__label">{jumpToStepVal.label}</span>
              <span className="listbox__button-icon">
                <FontAwesomeIcon fixedWidth icon={solid("caret-down")} />
              </span>
            </Listbox.Button>

            <Listbox.Options className="listbox__options">
              {allNodes.map((item, i) => (
                <Listbox.Option
                  onClick={(e: any) => e.stopPropagation()}
                  key={i}
                  className={({ active, selected }) =>
                    `listbox__option ${active ? "listbox__option--active" : ""}
                    ${selected ? "listbox__option--selected" : ""}`
                  }
                  value={item}
                >
                  <span>{item.label}</span>
                </Listbox.Option>
              ))}
            </Listbox.Options>
          </div>
        </Listbox>
      </TooltipMenu.Body>

      <TooltipMenu.Footer>
        <TooltipMenu.ActionButton onClick={handleSaveButton}>
          Save
        </TooltipMenu.ActionButton>
      </TooltipMenu.Footer>
    </>
  );
});
