import InlineTextEdit from "common/components/InlineTextEdit";
import { currentNodeContext } from "modules/canvas/currentNodeContext";
import useCanvasFunctions from "modules/canvas/useCanvasFunctions";
import { useContext } from "react";

import { PlayActionIcon } from "assets/icons/PlayActionIcon";
import { PersonPlaceholder } from "assets/illustrations/PersonPlaceholder";

import { NodeCard } from "common/components/NodeCard";
import { NodeTitle } from "modules/canvas/components/NodeTitle/NodeTitle";

import "./StartingNode.scss";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { NodeCardSideAction } from "common/components/NodeCardSideAction";
import { Tooltip } from "common/components/Tooltip";

export const StartingNodeUI = () => {
  const { data, id } = useContext(currentNodeContext);
  const { saveNodeData } = useCanvasFunctions();

  const saveSmallLabel = (val: string) => {
    saveNodeData({ customSmallLabel: val }, id);
  };

  return (
    <div className="starting-node">
      <div className="mainNode-step-title">
        <InlineTextEdit
          editableValue={data.customSmallLabel ?? ""}
          onSubmit={saveSmallLabel}
        >
          {(edit) => (
            <div className="step-title-input" onClick={edit}>
              <NodeTitle title={data.customSmallLabel ?? ""} />
            </div>
          )}
        </InlineTextEdit>
      </div>

      <NodeCard className="starting-node__card">
        {/* <div className="starting-node__title">Welcome to my quiz</div> */}

        <InlineTextEdit
          editableValue={data.label ?? ""}
          onSubmit={(val) => saveNodeData({ label: val }, id)}
          inputClass="main-node-flow__title-input"
        >
          {(enableEdit) => (
            <div onClick={enableEdit} className="main-node-flow__title">
              {data.label}
            </div>
          )}
        </InlineTextEdit>

        <div
          className="starting-node__content "
          aria-describedby="A muted video preview of the second card will automatically be shown here"
        >
          <div className="starting-node__bg-placeholder">
            <PersonPlaceholder />
          </div>

          <button type="button" className="starting-node__play-btn disabled">
            <Tooltip>
              <Tooltip.Trigger as="div">
                <PlayActionIcon />
              </Tooltip.Trigger>

              <Tooltip.Label label="A muted video preview of the second card will automatically be shown here" />
            </Tooltip>
          </button>
        </div>

        <div className="starting-node__footer">
          <button type="button" className="starting-node__cta-btn">
            <InlineTextEdit
              editableValue={data.ctaLabel ?? ""}
              onSubmit={(val) => {
                saveNodeData({ ctaLabel: val }, id);
              }}
              inputClass="starting-node__button_label-input"
            >
              {(enableEdit) => (
                <div
                  onClick={enableEdit}
                  className="starting-node__button-title"
                >
                  {data.ctaLabel}
                </div>
              )}
            </InlineTextEdit>
          </button>
        </div>
      </NodeCard>
    </div>
  );
};
