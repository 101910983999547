import { CurrentNodeContextProvider } from "modules/canvas/currentNodeContext";
import { NodeData } from "modules/canvas/data/types";
// import useCanvasFunctions from "modules/canvas/useCanvasFunctions";
import { useEffect } from "react";

import { Handle, NodeProps, Position } from "react-flow-renderer";
import { StartingNodeUI } from "../StartingNode/StartingNode.ui";

function StartingNode(props: NodeProps<NodeData>) {
  const { isConnectable } = props;
  // const { formatCanvas } = useCanvasFunctions();
  useEffect(() => {
    props.selected && console.log("MAIN NODE", props);
  }, [props]);
  return (
    <>
      <Handle
        type="target"
        className="o-0"
        position={Position.Top}
        onConnect={(params) => console.log("handle onConnect", params)}
        isConnectable={isConnectable}
      />
      {/* <button onClick={() => formatCanvas()}>Format</button> */}
      <div className="nodrag nowheel">
        <CurrentNodeContextProvider node={props}>
          <StartingNodeUI />
        </CurrentNodeContextProvider>
      </div>
      <Handle
        type="source"
        position={Position.Bottom}
        id="a"
        className="o-0"
        isConnectable={isConnectable}
      />

      {/* <Handle
        type="source"
        className="o-0"
        id="bottomBranchSource"
        position={Position.Bottom}
        onConnect={(params) => console.log("handle onConnect", params)}
        isConnectable={isConnectable}
      /> */}
      <Handle
        type="source"
        position={Position.Right}
        id="branchHandle"
        className="o-0"
        isConnectable={isConnectable}
      />

      {/* <Handle
        type="target"
        position={Position.Right}
        style={{ transform: "translateY(50px)" }}
        id="rightBranchTarget"
        className="o-0"
        isConnectable={isConnectable}
      /> */}
    </>
  );
}

export default StartingNode;
