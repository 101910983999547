import axios, { AxiosResponse } from "axios";
import { API_URL } from "../../apiClient";

interface ReturnType {
  status: number;
  message: string;
  presignedUrl: string;
  video_id: string;
}

export const genS3Url = (flowId: string) =>
  axios.post<any, AxiosResponse<ReturnType>>(
    `${API_URL}/create-presigned-s3-url`,
    { survey_id: flowId },
    { withCredentials: true }
  );
