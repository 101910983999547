import { EdgeData, NodeData, SavingState } from "modules/canvas/data/types";
import { useCanvasStateStore } from "pages/Canvas";
import { useReactFlow } from "react-flow-renderer";
import {
  FaArrowLeft,
  FaMinus,
  FaPlay,
  FaPlus,
  FaQuestion,
  FaRocket,
} from "react-icons/fa";
import "./Navbar.scss";
import { SERVER_URL } from "modules/canvas/data/env";

const savingStateToClassMap = {
  [SavingState.IDLE]: "idle",
  [SavingState.SAVING]: "saving",
  [SavingState.ERROR]: "error",
  [SavingState.SUCCESS]: "success",
};

const savingStateToMessageMap = {
  [SavingState.IDLE]: "",
  [SavingState.SAVING]: "Saving",
  [SavingState.ERROR]: "Couldn't save successfully, Retrying...",
  [SavingState.SUCCESS]: "Saved",
};

export const Navbar = () => {
  const rf = useReactFlow<NodeData, EdgeData>();
  const { flowName, savingState, flowId } = useCanvasStateStore();

  return (
    <>
      <header className="header">
        <div className="header__title-section">
          <div className="headerTitle">
            <a href={`${SERVER_URL}/inbox`}>
              <button style={{ display: "flex" }}>
                <FaArrowLeft color="#808080;" className="header__back-icon" />
                <span className="headerTitle__left">Back/</span>
              </button>
            </a>
            <span className="headerTitle__right">{flowName}</span>
          </div>
          <div className="save-notifications">
            <div
              className={`save-notifications__indicator save-notifications__indicator--${savingStateToClassMap[savingState]}`}
            ></div>
            <div className="save-notifications__msg">
              {savingStateToMessageMap[savingState]}
            </div>
          </div>
        </div>
        <div className="header__actions-section">
          <div className="zoomButtons">
            <button
              onClick={() => rf.zoomIn()}
              className="zoomButtons__btn-left"
            >
              <FaPlus size={11} />
            </button>
            <button
              onClick={() => rf.zoomOut()}
              className="zoomButtons__btn-right"
            >
              <FaMinus size={11} />
            </button>
          </div>

          <a
            target="_blank"
            href={`${SERVER_URL}/survey/setting/${flowId}#share-embed`}
            className="action__button"
            rel="noreferrer"
          >
            <FaRocket />
            Share
          </a>
          <a
            target="_blank"
            href={`${SERVER_URL}/s/${flowId}`}
            className="action__button action__button--dark"
            rel="noreferrer"
          >
            <FaPlay />
            Preview
          </a>
        </div>
      </header>

      <div className="fixed-foot">
        <a
          target="_blank"
          href={`https://interface.helpscoutdocs.com/`}
          className="action__button"
          rel="noreferrer"
        >
          <FaQuestion />
          Help
        </a>
      </div>
    </>
  );
};
