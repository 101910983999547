import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { currentNodeContext } from "modules/canvas/currentNodeContext";
import useCanvasFunctions from "modules/canvas/useCanvasFunctions";
import { createContext, useContext, useEffect, useState } from "react";

import InlineTextEdit from "common/components/InlineTextEdit";
import { NodeCard } from "common/components/NodeCard";
import { NodeTitle } from "../NodeTitle/NodeTitle";

import {
  CanvasModal,
  CanvasModalHeader,
} from "common/components/CanvasModal/CanvasModal";
import { InputTextField } from "common/components/InputTextField/InputTextField";
import {
  OptInInputFields,
  OptInInputKeys,
  OptInPutData,
} from "modules/canvas/data/types";
import "./OptInNode.scss";
import { isValidURL } from "modules/canvas/utils";

export let sampleOptinInputData: OptInInputFields = {
  name: {
    value: "Name",
    required: true,
    shown: true,
  },
  email: {
    required: true,
    shown: true,
    value: "Email",
  },
  "phone-number": {
    required: true,
    shown: false,
    value: "Phone Number",
  },
  terms: {
    required: false,
    shown: true,
    value: "Yes I agree to my info being processed in accordance with the ",
  },
  buttonLabel: "Continue",
  descLabel: "Description",
  privPolURL: "",
};

interface OptIncontextType {
  optInValue: OptInInputFields;
  setOptInValue: React.Dispatch<React.SetStateAction<OptInInputFields>>;
}

const OptInNodeContext = createContext({} as OptIncontextType);

interface OptInNodeUIProps {
  data: any;
  selected: boolean;
}

export const OptInNodeUI = ({ selected }: OptInNodeUIProps) => {
  const { id, data } = useContext(currentNodeContext);

  const [smallLabel, setSmallLabel] = useState(`OPT-IN FORM`);
  const [optInFields, setOptInFields] =
    useState<OptInInputFields>(sampleOptinInputData);
  const { saveNodeData } = useCanvasFunctions();

  const saveOptinInput = (
    name: keyof OptInInputFields,
    values: OptInPutData | string
  ) => {
    saveNodeData(
      {
        optinData: {
          ...(data?.optinData ?? sampleOptinInputData),
          [name]: values,
        },
      },
      id
    );
  };

  const saveSmallLabel = (val: string) => {
    setSmallLabel(val);
  };

  const saveLabel = (val: string) => {
    saveNodeData({ label: val }, id);
  };

  return (
    <div className="optin-node">
      <div className="mainNode-step-title">
        <InlineTextEdit editableValue={smallLabel} onSubmit={saveSmallLabel}>
          {(edit) => (
            <div onClick={edit}>
              <NodeTitle title={smallLabel} />
            </div>
          )}
        </InlineTextEdit>
      </div>
      <NodeCard className="optin-node__card">
        <OptInNodeContext.Provider
          value={{ optInValue: optInFields, setOptInValue: setOptInFields }}
        >
          <div className="optin-node__header">
            <h2 className="optin-node__title">
              <InlineTextEdit
                editableValue={data?.label ?? ""}
                defaultText="Optional Title/ question"
                onSubmit={saveLabel}
                inputClass="main-node-flow__title-input"
              >
                {(enableEdit) => (
                  <div onClick={enableEdit} className="main-node-flow__title">
                    {data?.label}
                  </div>
                )}
              </InlineTextEdit>
            </h2>
            <p className="optin-node__description">
              <InlineTextEdit
                editableValue={data.optinData?.descLabel ?? ""}
                onSubmit={(v) => saveOptinInput("descLabel", v)}
                inputClass="optin-node__title-input"
                defaultText="Some Description"
              >
                {(enableEdit) => (
                  <div onClick={enableEdit}>{data.optinData?.descLabel}</div>
                )}
              </InlineTextEdit>
            </p>
          </div>
          <div className="optin-node__body">
            <div className="optin-node__fields">
              <div className="optin-node__inputs">
                <div>
                  <OptInNodeInput
                    icon={<FontAwesomeIcon icon={solid("user")} />}
                    name="name"
                    label={data.optinData?.name.value ?? ""}
                  />
                </div>
                <div>
                  <OptInNodeInput
                    icon={<FontAwesomeIcon icon={solid("envelope")} />}
                    name="email"
                    label={data.optinData?.email.value ?? ""}
                  />
                </div>

                <div>
                  <OptInNodeInput
                    icon={<FontAwesomeIcon icon={solid("phone")} />}
                    name="phone-number"
                    label={data.optinData?.["phone-number"].value ?? ""}
                  />
                </div>
              </div>
            </div>
            <OptInNodeTerms />
          </div>
          <div className="optin-node__footer">
            <button type="button" className="optin-node__continue-btn">
              <InlineTextEdit
                editableValue={data?.optinData?.buttonLabel ?? ""}
                onSubmit={(v) => saveOptinInput("buttonLabel", v)}
                inputClass="starting-node__button_label-input"
                defaultText="Submit"
              >
                {(enableEdit) => (
                  <div
                    onClick={enableEdit}
                    className="starting-node__button-title"
                  >
                    {data?.optinData?.buttonLabel ?? "Submit"}
                  </div>
                )}
              </InlineTextEdit>
            </button>
          </div>
        </OptInNodeContext.Provider>
      </NodeCard>
    </div>
  );
};

interface OptinNodeInputProps {
  icon: React.ReactNode;
  name: OptInInputKeys;
  label: string;
}

const OptInNodeInput = ({ icon, name, label }: OptinNodeInputProps) => {
  const { saveNodeData } = useCanvasFunctions();
  const { data, id } = useContext(currentNodeContext);
  const { required, shown } = data.optinData![name];

  const saveOptinInput = (
    name: keyof OptInInputFields,
    values: OptInPutData | string
  ) => {
    saveNodeData(
      {
        optinData: {
          ...(data?.optinData ?? sampleOptinInputData),
          [name]: values,
        },
      },
      id
    );
  };

  return (
    <div className={`optin-node-input ${!shown && "optin-node-input--hidden"}`}>
      <div className="optin-node-input__icon">{icon}</div>

      <InlineTextEdit
        editableValue={data.optinData?.[name].value ?? ""}
        defaultText={name.toLocaleUpperCase()}
        onSubmit={
          (v) =>
            saveNodeData(
              {
                optinData: {
                  ...data.optinData!,
                  [name]: { ...data.optinData![name]!, value: v },
                },
              },
              id
            ) /*saveOptinInput(name, { ...data.optinData![name], value: v })*/
        }
        inputClass="main-node-flow__title-input__edit"
      >
        {(enableEdit) => (
          <div style={{ width: "62%" }} onClick={shown ? enableEdit : () => {}}>
            <div className="optin-node-input__label">
              {data.optinData?.[name].value ?? name}
            </div>
          </div>
        )}
      </InlineTextEdit>
      {name !== "email" && (
        <div className="optin-node-input__actions">
          <button
            type="button"
            className={`optin-node-input__action ${
              !required && "optin-node-input__action--hidden"
            }`}
            disabled={!shown}
            onClick={() => {
              saveOptinInput(name, {
                ...data.optinData![name],
                required: !required,
              });
            }}
          >
            <FontAwesomeIcon fixedWidth icon={solid("asterisk")} />
          </button>
          <button
            type="button"
            className="optin-node-input__action"
            onClick={() =>
              saveOptinInput(name, {
                ...data.optinData![name],
                shown: !shown,
                required: false,
              })
            }
          >
            {shown && <FontAwesomeIcon fixedWidth icon={solid("eye")} />}
            {!shown && <FontAwesomeIcon fixedWidth icon={solid("eye-slash")} />}
          </button>
        </div>
      )}
    </div>
  );
};

const OptInNodeTerms = () => {
  const [isPrivPolModalOpen, setIsPrivPolModalOpen] = useState(false);

  const { data, id } = useContext(currentNodeContext);
  const { required, shown } = data.optinData!.terms;
  const { saveNodeData } = useCanvasFunctions();

  const handleShowAction = (key: keyof OptInPutData, val: any) => {
    saveNodeData(
      {
        optinData: {
          ...data.optinData!,
          terms: { ...data.optinData!.terms!, [key]: val },
        },
      },
      id
    );
  };
  return (
    <>
      <div className="optin-node__terms">
        <div className="optin-node__terms-checkbox"></div>
        <div
          className="optin-node__terms-label"
          style={{ opacity: !shown ? 0.5 : 1 }}
        >
          <p>
            <InlineTextEdit
              useTextArea
              onSubmit={(val) =>
                saveNodeData(
                  {
                    optinData: {
                      ...data.optinData!,
                      terms: { ...data.optinData!.terms!, value: val },
                    },
                  },
                  id
                )
              }
              editableValue={data.optinData?.terms.value ?? ""}
            >
              {(edit) => (
                <p onClick={shown ? edit : () => {}}>
                  {data.optinData?.terms.value ?? ""}
                </p>
              )}
            </InlineTextEdit>
            <a href="#" onClick={() => setIsPrivPolModalOpen(true)}>
              privacy policy
            </a>
          </p>
        </div>
        <div className="optin-node-input__actions">
          <button
            type="button"
            disabled={!shown}
            className={`optin-node-input__action ${
              !required && "optin-node-input__action--hidden"
            }`}
            onClick={() => handleShowAction("required", !required)}
          >
            <FontAwesomeIcon fixedWidth icon={solid("asterisk")} />
          </button>
          <button
            type="button"
            className="optin-node-input__action"
            onClick={() => handleShowAction("shown", !shown)}
          >
            {shown && <FontAwesomeIcon fixedWidth icon={solid("eye")} />}
            {!shown && <FontAwesomeIcon fixedWidth icon={solid("eye-slash")} />}
          </button>
        </div>
      </div>
      {isPrivPolModalOpen && (
        <PrivPolModal
          isOpen={isPrivPolModalOpen}
          close={() => setIsPrivPolModalOpen(false)}
        />
      )}
    </>
  );
};

const PrivPolModal: React.FC<{ isOpen: boolean; close: (s: boolean) => any }> =
  ({ isOpen, close }) => {
    const [url, setUrl] = useState("");
    const [isURLValid, setIsURLValid] = useState(false);
    const { data, id } = useContext(currentNodeContext);
    const { saveNodeData } = useCanvasFunctions();
    const saveURL = () => {
      saveNodeData(
        {
          optinData: {
            ...(data?.optinData ?? sampleOptinInputData),
            privPolURL: url,
          },
        },
        id
      );
      close(false);
    };

    useEffect(() => {
      setUrl(data.optinData?.privPolURL ?? "");
    }, [data.optinData?.privPolURL]);

    return (
      <CanvasModal
        isOpen={isOpen}
        isClosableOnBlur
        onClose={() => close(false)}
        className="new-outcome-modal"
      >
        <CanvasModalHeader>
          <div className="new-outcome-modal__header">
            <h2 className="new-outcome-modal__heading">Privacy Policy URL</h2>
          </div>
        </CanvasModalHeader>
        <div className="new-outcome-modal__body">
          <div className="new-outcome-modal__content">
            <InputTextField
              hasError={!isURLValid}
              value={url}
              onChange={(e) => {
                setUrl(e.target.value);
                setIsURLValid(isValidURL(e.target.value));
              }}
              label="Privacy Policy URL"
            />
          </div>
        </div>

        <div className="new-outcome-modal__footer">
          <button
            disabled={!isURLValid}
            type="button"
            onClick={saveURL}
            className="new-outcome-modal__continue-btn"
          >
            Save
          </button>
        </div>
      </CanvasModal>
    );
  };
