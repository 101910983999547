import { CanvasModalHeader } from "common/components/CanvasModal/CanvasModal";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";

import "./VideoScreenRecordingModal.scss";
import { Dialog } from "@headlessui/react";
import { useCallback, useEffect, useRef, useState } from "react";
import { VideoRecorderSettings } from "./VideoRecorderSettings";
import { useVideoModalStore, VIDEO_MODAL_PAGES } from "../MainNode/MainNode";
import { FaCheck, FaCircle, FaSquare, FaTimes, FaVideo } from "react-icons/fa";
import { useRecorderPermission } from "./useRecordRTC";
import { deadFn } from "modules/canvas/utils";

enum RecordingState {
  STOPPED,
  RECORDING,
  PREVIEW,
}

function isRecording(s: RecordingState) {
  return s === RecordingState.RECORDING;
}
function isPreview(s: RecordingState) {
  return s === RecordingState.PREVIEW;
}
function isStopped(s: RecordingState) {
  return s === RecordingState.STOPPED;
}

interface VideoScreenRecordingModalProps {
  isOpen?: boolean | undefined;
  onClose(value: boolean): void;
}

export const VideoScreenRecordingModal = ({
  isOpen,
  onClose,
}: VideoScreenRecordingModalProps) => {
  const { setFile, setPage } = useVideoModalStore((state) => ({
    setFile: state.setFile,
    setPage: state.setPage,
  }));

  const { stream, recorder } = useRecorderPermission("video");

  const vidRef = useRef<HTMLVideoElement>(null);

  const [recordingState, setRecordingState] = useState(RecordingState.STOPPED);
  const [preview, setPreview] = useState<Blob>();
  const [areNotesShown, setAreNotesShown] = useState(true);
  const [timer, setTimer] = useState<number>();
  const [LimitTimer, setLimitTimer] = useState("");

  const cleanup = useCallback(() => {
    stream?.getTracks()?.forEach((track) => track.stop());
    return recorder?.reset();
  }, [recorder, stream]);

  useEffect(() => {
    if (!vidRef.current || !stream) return;
    vidRef.current.srcObject = stream;
    vidRef.current.play();

    return () => {
      cleanup();
    };
  }, [cleanup, stream]);

  const startRecording = useCallback(async () => {
    let time = 3;
    const boop = new Audio("/boop-sound.wav");
    setTimer(time);
    boop.play();
    let id = setInterval(async () => {
      time -= 1;
      setTimer(time);
      if (time <= 0) {
        boop.pause();
        clearInterval(id);
        setTimer(undefined);
        await recorder?.startRecording();
        setRecordingState(RecordingState.RECORDING);
        return;
      }
    }, 1000);
    return () => clearInterval(id);
  }, [recorder]);

  const stopRecording = useCallback(async () => {
    if (!recorder || !vidRef.current) return;
    await recorder.stopRecording();
    const file = await recorder.getBlob();
    const preview = URL.createObjectURL(
      new File([file], "vid.webm", {
        type: file.type ?? "video/webm",
        lastModified: Date.now(),
      })
    );
    vidRef.current.srcObject = null;
    vidRef.current.src = preview;
    vidRef.current.muted = false;
    vidRef.current.play();

    // Download Preview
    // const a = document.createElement("a");
    // a.href = preview;
    // a.download = "vid.mp4";
    // document.body.appendChild(a);
    // a.click();
    // document.body.removeChild(a);
    setPreview(file);
    setRecordingState(RecordingState.PREVIEW);
  }, [recorder]);

  useEffect(() => {
    if (recordingState !== RecordingState.RECORDING) return;
    setLimitTimer("60:00");
    let timer = 60 * 60,
      duration = 0,
      minutes = 0,
      seconds = 0;
    let id = setInterval(function () {
      minutes = parseInt((timer / 60).toString(), 10);
      seconds = parseInt((timer % 60).toString(), 10);

      setLimitTimer(
        `${minutes < 10 ? "0" + minutes : minutes}:${
          seconds < 10 ? "0" + seconds : seconds
        }`
      );

      if (--timer < 0) {
        timer = duration;
        clearInterval(id);
        stopRecording();
      }
    }, 1000);
    return () => {
      clearInterval(id);
    };
  }, [recordingState, stopRecording]);

  const deletePreview = useCallback(() => {
    if (!vidRef.current) return;
    vidRef.current.srcObject = stream!;
    vidRef.current.src = "";
    vidRef.current.muted = true;
    vidRef.current.play();
    setPreview(undefined);
    setRecordingState(RecordingState.STOPPED);
  }, [stream]);

  const saveVideo = useCallback(() => {
    if (!preview) return;
    setFile(
      new File([preview], "recorded", {
        lastModified: Date.now(),
        type: preview?.type,
      })
    );
    setPreview(undefined);
    setPage(VIDEO_MODAL_PAGES.NAME_VIDEO);
  }, [preview, setFile, setPage]);

  return (
    <Dialog
      open={isOpen}
      onClose={deadFn}
      className={`canvas-modal video-recording-modal`}
    >
      <div className="canvas-modal__overlay" />
      <div className="canvas-modal__wrapper">
        <video ref={vidRef} muted playsInline loop className="recorder" />
        {timer && <div className="timer">{timer}</div>}
        {isRecording(recordingState) && (
          <div className="green-badge">
            <FaVideo />
            <p className="limit-timer">{LimitTimer}</p>
          </div>
        )}
        <div className="canvas-modal__inner">
          <Dialog.Panel className="canvas-modal__panel">
            <button
              type="button"
              className="canvas-modal__close-btn"
              onClick={() => onClose(false)}
            >
              <FontAwesomeIcon fixedWidth icon={solid("times")} />
            </button>

            <div className={`canvas-modal__body`}>
              {areNotesShown ? (
                <textarea
                  name="notes"
                  id="notes"
                  placeholder="Write your notes here ..."
                  // cols={30}
                  rows={4}
                ></textarea>
              ) : (
                <div />
              )}
              <CanvasModalHeader>
                <div className="video-controls-svg">
                  {isPreview(recordingState) ? (
                    <>
                      <button
                        type="button"
                        onClick={saveVideo}
                        className="setting-svg"
                      >
                        <FaCheck
                          style={{ width: 70, height: 70, color: "#1fd11f" }}
                        />
                      </button>

                      <button
                        type="button"
                        onClick={deletePreview}
                        className="record-svg"
                      >
                        <FaTimes style={{ width: 70, height: 70 }} />
                      </button>
                    </>
                  ) : (
                    <>
                      <VideoRecorderSettings
                        isDisabled={!isStopped(recordingState)}
                        areNotesShown={areNotesShown}
                        toggleNotes={setAreNotesShown}
                      />

                      {isStopped(recordingState) && (
                        <button
                          type="button"
                          onClick={startRecording}
                          className="record-svg"
                        >
                          <FaCircle style={{ width: 80, height: 80 }} />
                        </button>
                      )}
                      {isRecording(recordingState) && (
                        <button
                          type="button"
                          onClick={stopRecording}
                          className="record-svg"
                        >
                          <FaSquare style={{ width: 80, height: 80 }} />
                        </button>
                      )}
                    </>
                  )}
                </div>
              </CanvasModalHeader>
            </div>
          </Dialog.Panel>
        </div>
      </div>
    </Dialog>
  );
};
