import { useContext, useMemo } from "react";
import { Listbox } from "@headlessui/react";
import { useStore } from "zustand";

import { MainNodeSettingsContext } from "./MainNodeSettings.ui";
import { NODE_SETTINGS_PAGE } from "./MainNodeSettingStore";
import { currentNodeContext } from "modules/canvas/currentNodeContext";
import useCanvasFunctions from "modules/canvas/useCanvasFunctions";
import { TooltipMenu } from "common/components/TooltipMenu";

export const MainNodeSettingsReorder = () => {
  const { store } = useContext(MainNodeSettingsContext);
  const { setCurrentPage } = useStore(store);
  const { data } = useContext(currentNodeContext);
  const { getNumberOfNodeInCol, reorderNode } = useCanvasFunctions();
  const selected = useMemo(
    () => ({ step: data.step, id: data.step }),
    [data.step]
  );
  const positions = useMemo(() => {
    return new Array(getNumberOfNodeInCol(data.rootNodeId))
      .fill(0)
      .map((_, i) => ({ step: i + 1, id: i + 1 }));
  }, [data.rootNodeId, getNumberOfNodeInCol]);

  return (
    <>
      <TooltipMenu.Header>
        <TooltipMenu.BackNav
          onClick={() => setCurrentPage(NODE_SETTINGS_PAGE.HOME)}
        />
        <span>Reorder Step</span>
      </TooltipMenu.Header>
      <TooltipMenu.Body>
        <Listbox
          value={{ id: data.step, step: data.step }}
          onChange={(newData) => {
            reorderNode(selected.step, newData.step, data.rootNodeId);
          }}
        >
          <div className="listbox__wrapper">
            <Listbox.Button className="listbox__button">
              <span className="listbox__label">
                <span>Position &nbsp;</span>
                <span className="listbox__label-step">{selected.step}</span>
              </span>
              <span className="listbox__button-icon">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  strokeWidth={2}
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M19 9l-7 7-7-7"
                  />
                </svg>
              </span>
            </Listbox.Button>

            <Listbox.Options className="listbox__options">
              {positions.map((position, positionIdx) => (
                <Listbox.Option
                  key={positionIdx}
                  className={({ active, selected }) =>
                    `listbox__option ${active ? "listbox__option--active" : ""}
                    ${selected ? "listbox__option--selected" : ""}`
                  }
                  value={position}
                >
                  <span>
                    <span>Position &nbsp;</span>
                    <span>{position.step}</span>
                  </span>
                </Listbox.Option>
              ))}
            </Listbox.Options>
          </div>
        </Listbox>
      </TooltipMenu.Body>
      <TooltipMenu.Footer>
        <TooltipMenu.ActionButton
          onClick={() => setCurrentPage(NODE_SETTINGS_PAGE.HOME)}
        >
          Save
        </TooltipMenu.ActionButton>
      </TooltipMenu.Footer>
    </>
  );
};
