import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useContext, useEffect, useState } from "react";

import { TooltipMenu } from "common/components/TooltipMenu";
import { currentNodeContext } from "modules/canvas/currentNodeContext";
import useCanvasFunctions from "modules/canvas/useCanvasFunctions";

export const BranchNodeDeleteAction = () => {
  const [open, setOpen] = useState(false);
  const { deleteBranchNode } = useCanvasFunctions();
  const { id } = useContext(currentNodeContext);

  // useEffect(() => {
  //   return () => {
  //     setOpen(false);
  //   };
  // }, []);

  return (
    <TooltipMenu open={open} onOpenChange={setOpen}>
      <TooltipMenu.Trigger className="branched-node__delete-action">
        <FontAwesomeIcon fixedWidth icon={solid("times")} />
      </TooltipMenu.Trigger>

      <TooltipMenu.Content>
        <TooltipMenu.Header className="branched-node__header">
          <TooltipMenu.BackNav onClick={() => setOpen(false)} />
          <span>Are you sure?</span>
        </TooltipMenu.Header>
        <TooltipMenu.Body>
          <button
            onClick={() => deleteBranchNode(id)}
            type="button"
            className="branched-node__delete-btn"
          >
            Delete
          </button>
          <p>This action cannot be undone</p>
        </TooltipMenu.Body>
        <TooltipMenu.Footer>
          <TooltipMenu.ActionButton
            style={{ backgroundColor: "gray" }}
            onClick={() => setOpen(false)}
          >
            Cancel
          </TooltipMenu.ActionButton>
        </TooltipMenu.Footer>
      </TooltipMenu.Content>
    </TooltipMenu>
  );
};
