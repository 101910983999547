import { Dialog } from "@headlessui/react";

import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import "./CanvasModal.scss";
import useCanvasFunctions from "modules/canvas/useCanvasFunctions";
import { useOutComesStore } from "modules/canvas/components/OutcomeNode";
import create from "zustand";
import classNames from "classnames";
import { useModalStore } from "modules/canvas/components/Modals";

interface CanvasModalProps {
  isOpen?: boolean | undefined;
  onClose(value: boolean): void;
  children?: React.ReactNode;
  className?: string;
  modalBodyClassName?: string;
  isClosableOnBlur?: boolean;
  onlyCloseByButton?: boolean;
}

type ModalStore = {
  modalOverlayOpen: boolean;
  setModalOverlayOpen: (val: boolean) => void;
};
export const modalOverlayStore = create<ModalStore>((set) => ({
  modalOverlayOpen: true,
  setModalOverlayOpen: (val: boolean) =>
    set((state) => ({ ...state, modalOverlayOpen: val })),
}));
export const CanvasModal: React.FunctionComponent<CanvasModalProps> = ({
  isOpen,
  onClose,
  className,
  modalBodyClassName,
  onlyCloseByButton,
  isClosableOnBlur,
  children,
}) => {
  const { shouldShowAnimation } = useModalStore();
  const close = () => {
    onClose(false);
  };
  return (
    <Dialog
      open={isOpen}
      onClose={
        isClosableOnBlur ? (onlyCloseByButton ? () => {} : close) : () => {}
      }
      className={`canvas-modal ${className}`}
    >
      <div
        className={classNames(
          "canvas-modal__overlay",
          shouldShowAnimation ? "animate-open" : "animated"
        )}
      />
      <div className="canvas-modal__wrapper">
        <div className="canvas-modal__inner">
          <Dialog.Panel className="canvas-modal__panel">
            {(isClosableOnBlur || onlyCloseByButton) && (
              <button
                type="button"
                className="canvas-modal__close-btn"
                onClick={close}
              >
                <FontAwesomeIcon fixedWidth icon={solid("times")} />
              </button>
            )}
            <div
              className={`canvas-modal__body ${modalBodyClassName}`}
              style={{ maxHeight: "80vh", overflow: "auto" }}
            >
              {children}
            </div>
          </Dialog.Panel>
        </div>
      </div>
    </Dialog>
  );
};

interface CanvasModalHeaderProps {
  children?: React.ReactNode;
}

export const CanvasModalHeader = ({ children }: CanvasModalHeaderProps) => {
  return <div className="canvas-modal__header">{children}</div>;
};
