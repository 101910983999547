import { useState } from "react";

import {
  CanvasModal,
  CanvasModalHeader,
} from "common/components/CanvasModal/CanvasModal";

import "./NewInterfaceModal.scss";
import { InputTextField } from "common/components/InputTextField/InputTextField";

interface NewInterfaceModalProps {
  isOpen?: boolean | undefined;
  onClose(value: boolean): void;
  onSubmit: (value: string) => void;
}

export const NewInterfaceModal = ({
  isOpen,
  onClose,
  onSubmit,
}: NewInterfaceModalProps) => {
  const [newName, setNewName] = useState<string>("");

  return (
    <>
      <CanvasModal
        isOpen={isOpen}
        onClose={onClose}
        className="new-interface-modal"
      >
        <CanvasModalHeader>
          <div className="new-interface-modal__header">
            <h2 className="new-interface-modal__heading">New Interface</h2>
          </div>
        </CanvasModalHeader>
        <div className="new-interface-modal__body">
          <div className="new-interface-modal__content">
            <InputTextField
              value={newName}
              onChange={(e) => setNewName(e.target.value)}
              label="Name"
              placeholder="Enter a name..."
            />
          </div>
        </div>

        <div className="new-interface-modal__footer">
          {!!newName && (
            <button
              onClick={() => {
                onSubmit(newName);
                onClose(false);
              }}
              type="button"
              className="new-interface-modal__continue-btn"
            >
              Continue
            </button>
          )}
        </div>
      </CanvasModal>
    </>
  );
};
