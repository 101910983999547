import React, { useEffect, useState } from "react";
interface Props {
  inputClass?: string;
  onSubmit: (val: string) => any;
  type?: string;
  editableValue: string;
  useTextArea?: boolean;
  children: (enableEdit: () => void) => React.ReactNode;
  defaultText?: string;
}

const InlineTextEdit: React.FC<Props> = ({
  defaultText,
  inputClass,
  children,
  onSubmit,
  type,
  editableValue,
  useTextArea,
}) => {
  const [isEdit, setIsEdit] = useState(false);
  const [input, setInput] = useState(editableValue);

  useEffect(() => {
    setInput(editableValue);
  }, [editableValue]);

  const submit = (input: string) => {
    onSubmit((input || defaultText) ?? "Text");
    setIsEdit(false);
  };
  const enableEdit = () => setIsEdit(true);

  return !isEdit ? (
    <>{children(enableEdit)}</>
  ) : (
    <div>
      {useTextArea ? (
        <textarea
          autoFocus
          className={inputClass}
          value={input}
          onChange={(e) => setInput(e.target.value)}
          onKeyDown={(e) => e.key === "Enter" && submit(input)}
          onBlur={() => submit(input)}
          style={{ resize: "vertical", padding: "4px" }}
        />
      ) : (
        <input
          autoFocus
          className={inputClass}
          type={type ?? "text"}
          value={input}
          onChange={(e) => setInput(e.target.value)}
          onKeyDown={(e) => e.key === "Enter" && submit(input)}
          onBlur={() => submit(input)}
        />
      )}
    </div>
  );
};
export default InlineTextEdit;
