import { BranchNode } from "../components/BranchedNode";
import CustomEdge from "../components/CustomEdge";
import MainNode from "../components/MainNode/MainNode";
import { OutcomeNode } from "../components/OutcomeNode";
import StartingNode from "../components/StartingNode/StartingNode";

import { OptinNode } from "../components/OptInNode";
import { URLOutcomeNode } from "../components/URLOutcomeNode";
import { EdgeTypes, NodeTypes } from "./types";

export const edgeTypes = { [EdgeTypes.BUTTON_EDGE]: CustomEdge };

export const nodeTypes = {
  [NodeTypes.CARD]: MainNode,
  [NodeTypes.BRANCHED_NODE]: BranchNode,
  [NodeTypes.STARTING_NODE]: StartingNode,
  [NodeTypes.OUTCOME_NODE]: OutcomeNode,
  [NodeTypes.OPTIN_NODE]: OptinNode,
  [NodeTypes.URL_OUTCOME_NODE]: URLOutcomeNode,
};
