import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { createStore, StoreApi, useStore } from "zustand";

import { TooltipMenu } from "common/components/TooltipMenu";
import { createContext, useContext, useEffect, useState } from "react";
import "./../OutcomeNode/ui/MultipleOutcomeNodeSetting.scss";

import { Switch } from "@headlessui/react";

import { useVideoModalStore } from "../MainNode/MainNode";
import "./SideSwitch.scss";

enum RECORDER_SETTING_PAGE {
  HOME,
  AUDIO_SOURCES,
  VIDEO_SOURCES,
  NONE,
}

interface RecorderSettingsState {
  currentPage: RECORDER_SETTING_PAGE;
  audioSources: Device[];
  videoSources: Device[];
  setVideoSources: (source: Device[]) => void;
  setAudioSources: (source: Device[]) => void;
  setCurrentPage: (page: RECORDER_SETTING_PAGE) => void;
}

interface ChoiceSettingsContextType {
  store: StoreApi<RecorderSettingsState>;
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

type Device = {
  label: string;
  id: string;
};

const RecorderSettingsStore = createStore<RecorderSettingsState>((set) => ({
  currentPage: RECORDER_SETTING_PAGE.HOME,
  audioSources: [],
  videoSources: [],
  setAudioSources(source) {
    set((state) => ({ ...state, audioSources: source }));
  },

  setVideoSources(source) {
    set((state) => ({ ...state, videoSources: source }));
  },
  setCurrentPage: (page) => set((state) => ({ ...state, currentPage: page })),
}));

const OutcomesSettingsContext = createContext({} as ChoiceSettingsContextType);

export const VideoRecorderSettings: React.FC<{
  toggleNotes: React.Dispatch<React.SetStateAction<boolean>>;
  isDisabled?: boolean;
  areNotesShown: boolean;
}> = ({ toggleNotes, areNotesShown, isDisabled }) => {
  const [open, setOpen] = useState(false);

  const { currentPage, setCurrentPage, setAudioSources, setVideoSources } =
    useStore(RecorderSettingsStore);

  const handleTooltipOpen = (open: boolean) => {
    if (!open) setCurrentPage(RECORDER_SETTING_PAGE.HOME);
    setOpen((p) => !p);
  };
  useEffect(() => {
    if (!open) return;

    navigator.mediaDevices.enumerateDevices().then((devices) => {
      const audios: Device[] = [];
      const videos: Device[] = [];
      devices.forEach((device) => {
        if (device.kind === "audioinput") {
          audios.push({ id: device.deviceId, label: device.label });
        } else if (device.kind === "videoinput") {
          videos.push({ id: device.deviceId, label: device.label });
        }
      });

      console.log({ audios, videos });

      setAudioSources(audios);
      setVideoSources(videos);
    });
  }, [currentPage, open, setAudioSources, setVideoSources]);

  return (
    <TooltipMenu
      open={open}
      direction="top-end"
      onOpenChange={handleTooltipOpen}
    >
      <TooltipMenu.Trigger
        disabled={isDisabled}
        className="setting-svg"
        aria-label="Choice Settings"
      >
        <FontAwesomeIcon fixedWidth icon={solid("gear")} />
      </TooltipMenu.Trigger>
      <TooltipMenu.Content>
        <OutcomesSettingsContext.Provider
          value={{ store: RecorderSettingsStore, open, setOpen }}
        >
          {currentPage === RECORDER_SETTING_PAGE.HOME && (
            <MainNodeChoiceSettingsHome
              toggleNotes={toggleNotes}
              areNotesShown={areNotesShown}
            />
          )}
          {currentPage === RECORDER_SETTING_PAGE.VIDEO_SOURCES && (
            <VideoSourcesSetting />
          )}

          {currentPage === RECORDER_SETTING_PAGE.AUDIO_SOURCES && (
            <AudioSourcesSetting />
          )}
        </OutcomesSettingsContext.Provider>
      </TooltipMenu.Content>
    </TooltipMenu>
  );
};

const MainNodeChoiceSettingsHome: React.FC<{
  toggleNotes: React.Dispatch<React.SetStateAction<boolean>>;
  areNotesShown: boolean;
}> = ({ toggleNotes, areNotesShown }) => {
  const { setOpen } = useContext(OutcomesSettingsContext);
  const { setCurrentPage } = useStore(RecorderSettingsStore);

  return (
    <>
      <TooltipMenu.Header>
        <span>Settings</span>
      </TooltipMenu.Header>
      <TooltipMenu.Options>
        <TooltipMenu.Option
          style={{ justifyContent: "space-between", alignItems: "center" }}
          onClick={() => toggleNotes((p) => !p)}
        >
          <span>Show Notes</span>
          <SidesideSwitch
            enabled={areNotesShown}
            onChange={() => {}} /*onChange={() => setNotesEnabled((p) => !p}*/
          />
        </TooltipMenu.Option>
        <TooltipMenu.Option
          onClick={() => setCurrentPage(RECORDER_SETTING_PAGE.VIDEO_SOURCES)}
        >
          <TooltipMenu.OptionIcon>
            <FontAwesomeIcon fixedWidth icon={solid("camcorder")} />
          </TooltipMenu.OptionIcon>
          <span>Select Camera</span>
        </TooltipMenu.Option>
        <TooltipMenu.Option
          onClick={() => {
            setCurrentPage(RECORDER_SETTING_PAGE.AUDIO_SOURCES);
          }}
        >
          <TooltipMenu.OptionIcon>
            <FontAwesomeIcon fixedWidth icon={solid("microphone")} />
          </TooltipMenu.OptionIcon>
          <span>Select Microphone</span>
        </TooltipMenu.Option>
      </TooltipMenu.Options>
      <TooltipMenu.Footer>
        <TooltipMenu.ActionButton
          onClick={() => {
            setCurrentPage(RECORDER_SETTING_PAGE.HOME);
            setOpen(false);
          }}
        >
          Done
        </TooltipMenu.ActionButton>
      </TooltipMenu.Footer>
    </>
  );
};

const VideoSourcesSetting = () => {
  const { setOpen } = useContext(OutcomesSettingsContext);

  const { videoSources, setCurrentPage } = useStore(RecorderSettingsStore);
  const { setVideoId } = useVideoModalStore();

  return (
    <>
      <TooltipMenu.Header>
        <TooltipMenu.BackNav
          onClick={() => setCurrentPage(RECORDER_SETTING_PAGE.HOME)}
        />
        <span>Video Sources</span>
      </TooltipMenu.Header>

      <TooltipMenu.Options>
        {videoSources.map((source) => (
          <TooltipMenu.Option onClick={() => setVideoId(source.id)}>
            <span>{source.label}</span>
          </TooltipMenu.Option>
        ))}
      </TooltipMenu.Options>

      <TooltipMenu.Footer>
        <TooltipMenu.ActionButton
          onClick={() => {
            setCurrentPage(RECORDER_SETTING_PAGE.HOME);
            setOpen(false);
          }}
        >
          Done
        </TooltipMenu.ActionButton>
      </TooltipMenu.Footer>
    </>
  );
};

const AudioSourcesSetting = () => {
  const { setOpen } = useContext(OutcomesSettingsContext);

  const { audioSources, setCurrentPage } = useStore(RecorderSettingsStore);
  const { setAudioId } = useVideoModalStore();

  return (
    <>
      <TooltipMenu.Header>
        <TooltipMenu.BackNav
          onClick={() => setCurrentPage(RECORDER_SETTING_PAGE.HOME)}
        />
        <span>Audio Sources</span>
      </TooltipMenu.Header>

      <TooltipMenu.Options>
        {audioSources.map((source) => (
          <TooltipMenu.Option onClick={() => setAudioId(source.id)}>
            <span>{source.label}</span>
          </TooltipMenu.Option>
        ))}
      </TooltipMenu.Options>

      <TooltipMenu.Footer>
        <TooltipMenu.ActionButton
          onClick={() => {
            setCurrentPage(RECORDER_SETTING_PAGE.HOME);
            setOpen(false);
          }}
        >
          Done
        </TooltipMenu.ActionButton>
      </TooltipMenu.Footer>
    </>
  );
};

interface sideSwitchProps {
  enabled: boolean;
  onChange?: React.Dispatch<React.SetStateAction<boolean>>;
  enabledLabel?: string;
  disabledLabel?: string;
}

export const SidesideSwitch = ({
  enabled,
  onChange,
  enabledLabel,
  disabledLabel,
}: sideSwitchProps) => {
  return (
    <>
      <Switch
        checked={enabled}
        onChange={onChange}
        className={`${enabled ? "side-switch--enabled" : ""} side-switch`}
        style={{ height: "16px" }}
      >
        {enabled && enabledLabel && (
          <div className="side-switch__label">{enabledLabel}</div>
        )}
        {!enabled && disabledLabel && (
          <div className="side-switch__label">{disabledLabel}</div>
        )}
        <span className="side-switch__thumb"></span>
      </Switch>
    </>
  );
};
