import {
  CanvasModal,
  CanvasModalHeader,
} from "common/components/CanvasModal/CanvasModal";

import { MultipleOutcomeMap } from "assets/illustrations/MultipleOutcomeMap";
import "./MultipleOutcomeModal.scss";

import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import useCanvasFunctions from "modules/canvas/useCanvasFunctions";
import { useMapOutcomeStore } from "../MapOutcomeAnswerModal/MapOutcomeAnswerModalStore";
import { OUT_COMES_PAGE, useOutComesStore } from "../OutcomeNode";
import { OutcomeOptionMenu } from "./OutcomeOptionMenu";

interface MultipleOutcomeModalProps {
  isOpen?: boolean | undefined;
  onClose(value: boolean): void;
}

export const MultipleOutcomeModal = ({
  isOpen,
  onClose,
}: MultipleOutcomeModalProps) => {
  const { buildQuestionList, buildQuestionList2 } = useCanvasFunctions();
  const { setPage, multipleOutComes } = useOutComesStore();
  const { setFlatQuestionsList, setQuestionsList } = useMapOutcomeStore();
  const HasOutcome = multipleOutComes?.length;

  return (
    <>
      <CanvasModal
        isClosableOnBlur
        isOpen={isOpen}
        onClose={onClose}
        className="multiple-outcome-modal"
      >
        <CanvasModalHeader>
          <div className="multiple-outcome-modal__header">
            <button
              onClick={() => setPage(OUT_COMES_PAGE.OUTCOMES)}
              className="multiple-outcome-modal__back-button"
            >
              <FontAwesomeIcon fixedWidth icon={solid("caret-left")} />
              Back
            </button>
            <h2 className="multiple-outcome-modal__heading">
              Multiple Outcomes
            </h2>
          </div>
        </CanvasModalHeader>
        {HasOutcome ? (
          <>
            <div className="multiple-outcome-modal__body has-outcome">
              <div className="multiple-outcome-modal__content">
                <button
                  type="button"
                  className="multiple-outcome-modal__cta-btn"
                  onClick={() => setPage(OUT_COMES_PAGE.ADD_NEW_OUTCOME)}
                >
                  <FontAwesomeIcon fixedWidth icon={solid("plus")} />
                  New Outcome
                </button>
                <div style={{ width: "100%", marginBlock: "30px" }}>
                  {multipleOutComes.map((outcome) => (
                    <div
                      key={outcome.id}
                      className={`multiple-outcome-modal__choice `}
                    >
                      <div className="multiple-outcome-modal__content-wrapper">
                        <div className="multiple-outcome-modal__text-content-wrapper">
                          <h2>{outcome.label}</h2>
                          <OutcomeOptionMenu id={outcome.id} />
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
            <div className="multiple-outcome-modal__footer">
              <button
                type="button"
                onClick={() => {
                  const s = buildQuestionList2();
                  setFlatQuestionsList(s[1]);
                  setQuestionsList(s[0]);
                  setPage(OUT_COMES_PAGE.MAP_ANS_TO_OUTCOME);
                }}
                className="multiple-outcome-modal__continue-btn"
              >
                Map Answers
                <FontAwesomeIcon fixedWidth icon={solid("right-long")} />
              </button>
            </div>
          </>
        ) : (
          <>
            <div className="multiple-outcome-modal__body">
              <div className="multiple-outcome-modal__content">
                <div className="multiple-outcome-modal__placeholder">
                  <MultipleOutcomeMap />
                </div>
                <p className="text-content">Let's create your first outcome!</p>
                <button
                  type="button"
                  className="multiple-outcome-modal__cta-btn"
                  onClick={() => setPage(OUT_COMES_PAGE.ADD_NEW_OUTCOME)}
                >
                  <FontAwesomeIcon fixedWidth icon={solid("plus")} />
                  NEW OUTCOME
                </button>
              </div>
            </div>
          </>
        )}
        {/* <div className="multiple-outcome-modal__footer">
          {!!newName && (
            <button type="button" className="multiple-outcome-modal__continue-btn">
              Map Answers
            </button>
          )}
        </div> */}
      </CanvasModal>
    </>
  );
};
