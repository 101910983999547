import React from "react";
import ReactDOM from "react-dom/client";
import reportWebVitals from "./reportWebVitals";

import App from "App";

import "assets/styles/styles.scss";
import "common/assets/styles/App.css";
import "common/assets/styles/utils.css";
import "simplebar/dist/simplebar.min.css";

// if (window.innerWidth <= 1024 && window.innerHeight <= 768) {
//   alert("Your Screen resolution is not optimal. Please access canvas from desktop.");
//   window.history.back();
// }

// if (!new URLSearchParams(window.location.search).get("token")) {
// window.history.back();
// window.open("https://app.tryinterface.io/login", "_self");
// } else {
const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);
// }

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
