import { useState } from "react";
import { useDndMonitor, useDroppable } from "@dnd-kit/core";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";
import { IOutcome, useOutComesStore } from "../OutcomeNode";

export const MapOutcomeAnswerModalOutcomesDelete = () => {
  const [isCurrentValid, setIsCurrentDragValid] = useState(false);
  const { setNodeRef, isOver } = useDroppable({
    id: `outcome-delete`,
    data: {
      supports: ["mapped-outcome-item"],
    },
  });
  const { detachAnswer } = useOutComesStore();
  useDndMonitor({
    onDragMove({ active }) {
      const isValid = active?.data?.current?.type === "mapped-outcome-item";
      if ((isOver && isValid) !== isCurrentValid) {
        setIsCurrentDragValid(isOver && isValid);
      }
    },
    onDragEnd(e) {
      if (isCurrentValid) {
        const ans = e.active.data.current?.data as IOutcome["answers"][0];
        detachAnswer(ans.choiceId, e.active.data.current!.outcomeId);
      }
      setIsCurrentDragValid(false);
    },
  });

  return (
    <div className="map-outcome-modal__delete-container">
      <div
        ref={setNodeRef}
        className={classNames("map-outcome-modal__delete-wrapper", {
          "map-outcome-modal__delete-wrapper--active": isCurrentValid,
        })}
      >
        <div className="map-outcome-modal__delete-icon" aria-hidden="true">
          <FontAwesomeIcon fixedWidth icon={solid("trash-can")} />
        </div>
        <div className="map-outcome-modal__delete-label">
          Drag answers here to detach from outcome
        </div>
      </div>
    </div>
  );
};
