import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useContext } from "react";
import { useStore } from "zustand";

import { TooltipMenu } from "common/components/TooltipMenu";

import { currentNodeContext } from "modules/canvas/currentNodeContext";
import { useVideoModalStore, VIDEO_MODAL_PAGES } from "../../MainNode";
import { MainNodeSettingsContext } from "./MainNodeSettings.ui";
import { NODE_SETTINGS_PAGE } from "./MainNodeSettingStore";

export const MainNodeSettingsVideo = () => {
  const { store, setOpen } = useContext(MainNodeSettingsContext);
  const { setCurrentPage: setNodeSettingsPage } = useStore(store);
  const { setPage: setVideoModalPage } = useVideoModalStore();
  const { data } = useContext(currentNodeContext);
  return (
    <>
      <TooltipMenu.Header>
        <TooltipMenu.BackNav
          onClick={() => setNodeSettingsPage(NODE_SETTINGS_PAGE.HOME)}
        />
        <span>Video Settings</span>
      </TooltipMenu.Header>
      <TooltipMenu.Options>
        {/* Change Video */}
        <TooltipMenu.Option
          onClick={() => {
            setVideoModalPage(VIDEO_MODAL_PAGES.ADD_VIDEO);
            setOpen(false);
          }}
        >
          <TooltipMenu.OptionIcon>
            <FontAwesomeIcon fixedWidth icon={solid("video")} />
          </TooltipMenu.OptionIcon>
          <span>Change Video</span>
        </TooltipMenu.Option>
        {/* Download Video */}
        <TooltipMenu.Option onClick={() => window.open(data.video, "_blank")}>
          <TooltipMenu.OptionIcon>
            <FontAwesomeIcon fixedWidth icon={solid("download")} />
          </TooltipMenu.OptionIcon>
          <span>Download Video</span>
        </TooltipMenu.Option>
      </TooltipMenu.Options>
      <TooltipMenu.Footer>
        <TooltipMenu.ActionButton onClick={() => setOpen(false)}>
          Done
        </TooltipMenu.ActionButton>
      </TooltipMenu.Footer>
    </>
  );
};
