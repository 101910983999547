import classNames from "classnames";
import "./BranchedNode.scss";

import { NodeTitle } from "modules/canvas/components/NodeTitle/NodeTitle";
import { NodeData } from "modules/canvas/data/types";
import { BranchNodeDeleteAction } from "./BranchNodeDeleteAction";

interface BranchedNodeProps {
  data: NodeData;
  selected: boolean;
}

export const BranchedNodeUI = (props: BranchedNodeProps) => {
  const { data, selected } = props;

  return (
    <div className="branched-node">
      <NodeTitle title={data.label} />
      <div
        className={classNames("branched-node__card", {
          "branched-node__card--selected": selected,
        })}
      >
        {data.label && <span>{data.label}</span>}
        {selected && !data.isOutcomeNode && <BranchNodeDeleteAction />}
      </div>
    </div>
  );
};
