import { CurrentNodeContextProvider } from "modules/canvas/currentNodeContext";
import { useEffect } from "react";
import { Handle, NodeProps, Position } from "react-flow-renderer";
import { OptInNodeUI } from "./OptInNode.ui";

export function OptinNode(props: NodeProps) {
  const { data, isConnectable, selected } = props;
  useEffect(() => {
    selected && console.log(props);
  }, [props, selected]);

  return (
    <div className="nodrag">
      <Handle
        type="target"
        position={Position.Top}
        className="o-0"
        onConnect={(params) => console.log("handle onConnect", params)}
        isConnectable={isConnectable}
      />
      <CurrentNodeContextProvider node={props}>
        <OptInNodeUI data={data} selected={selected} />
      </CurrentNodeContextProvider>
      <Handle
        type="source"
        position={Position.Bottom}
        id="branchHandle"
        className="o-0"
        isConnectable={isConnectable}
      />
    </div>
  );
}
