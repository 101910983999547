import { Edge, MarkerType } from "react-flow-renderer";
import { EdgeData } from "./data/types";

export const deadFn = (...s: any[]) => {};

export const edgeStyles = (edge: Edge<EdgeData>): Edge => {
  return {
    style: { stroke: "#E0E7F2", strokeWidth: 3 },
    markerEnd: {
      type: MarkerType.ArrowClosed,
      strokeWidth: 1,
      color: "#E0E7F2",
    },
    ...edge,
  };
};

export function blobToBase64(blob: Blob): Promise<string> {
  return new Promise((resolve) => {
    const reader = new FileReader();
    reader.onloadend = () => resolve(reader.result as string);
    reader.readAsDataURL(blob);
  });
}

export function isValidURL(url: string) {
  // const reg =
  //   /((([A-Za-z]{3,9}:(?:\/\/)?)(?:[-;:&=\+\$,\w]+@)?[A-Za-z0-9.-]+|(?:www.|[-;:&=\+\$,\w]+@)[A-Za-z0-9.-]+)((?:\/[\+~%\/.\w-_]*)?\??(?:[-\+=&;%@.\w_]*)#?(?:[\w]*))?)/;

  // return reg.test(url);

  try {
    return Boolean(new URL(url));
  } catch (e) {
    return false;
  }
}
