import {
  CanvasModal,
  CanvasModalHeader,
} from "common/components/CanvasModal/CanvasModal";

import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { useVideoModalStore, VIDEO_MODAL_PAGES } from "../MainNode/MainNode";
import "./VideoPermissionsModal.scss";

interface VideoPermissionsModalProps {
  isOpen?: boolean | undefined;
  onClose(value: boolean): void;
}

export const VideoPermissionsModal = ({
  isOpen,
  onClose,
}: VideoPermissionsModalProps) => {
  const { setPage } = useVideoModalStore();
  return (
    <CanvasModal
      isOpen={isOpen}
      onClose={onClose}
      className="video-permission-modal"
    >
      <CanvasModalHeader>
        <div className="video-permission-modal__header">
          <div className="switch-svg">
            <FontAwesomeIcon fixedWidth icon={solid("lock-keyhole")} />
          </div>
          <h2 className="video-permission-modal__heading">Enable Access</h2>
          <p className="video-permission-modal__sub-heading">
            We need access to your camera and microphone{" "}
            <button
              onClick={() =>
                navigator.mediaDevices
                  .getUserMedia({
                    audio: true,
                    video: {
                      width: { ideal: 9999 },
                      height: { ideal: 7777 },
                    },
                  })
                  .then((stream) => {
                    stream.getTracks().forEach((track) => track.stop());
                    setPage(VIDEO_MODAL_PAGES.RECORD_VIDEO);
                  })
              }
              className="video-permission-modal__link"
            >
              click here to enable
            </button>
          </p>
        </div>
      </CanvasModalHeader>
    </CanvasModal>
  );
};
