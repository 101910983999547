import { useContext } from "react";
import { useStore } from "zustand";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";

import { TooltipMenu } from "common/components/TooltipMenu";

import { MainNodeSettingsContext } from "./MainNodeSettings.ui";
import { NODE_SETTINGS_PAGE } from "./MainNodeSettingStore";
import useCanvasFunctions from "modules/canvas/useCanvasFunctions";
import { currentNodeContext } from "modules/canvas/currentNodeContext";

import { SidesideSwitch } from "modules/canvas/components/VideoScreenRecordingModal/VideoRecorderSettings";
import { NodeDataChildType } from "modules/canvas/data/types";

export const MainNodeSettingsOpenEnded = () => {
  const { store, setOpen } = useContext(MainNodeSettingsContext);
  const { setCurrentPage: setNodeSettingsPage } = useStore(store);
  const { id, data } = useContext(currentNodeContext);

  const { saveNodeData } = useCanvasFunctions();

  type OpenEndedKeys = "v" | "a" | "t";
  const toggleOpenEndedOptions = (s: OpenEndedKeys) => {
    return function () {
      if (data.children.type !== NodeDataChildType.OPEN_ENDED) return;
      const newData: Partial<typeof data> = {
        children: {
          type: data.children.type,
          openEndedType: {
            [s]: !data.children.openEndedType?.[s],
          },
        },
      };
      saveNodeData(newData, id);
    };
  };
  if (data.children.type !== NodeDataChildType.OPEN_ENDED) return <></>;

  const checkIfdisableTooltip = (s: OpenEndedKeys) => {
    if (data.children.type !== NodeDataChildType.OPEN_ENDED) return false;
    const enabledKeys = Object.entries(data.children.openEndedType ?? {})
      .map(([key, val]) => (!!val ? key : null))
      .filter(Boolean) as string[];
    console.log(enabledKeys);
    // Only 1 option is enabled, and the given key is one of them...
    return enabledKeys.length <= 1 && enabledKeys.includes(s);
  };

  return (
    <>
      <TooltipMenu.Header>
        <TooltipMenu.BackNav
          onClick={() => setNodeSettingsPage(NODE_SETTINGS_PAGE.CHANGE_INPUT)}
        />
        <span>Open Ended</span>
      </TooltipMenu.Header>
      <TooltipMenu.Options>
        <TooltipMenu.Option
          disabled={checkIfdisableTooltip("v")}
          onClick={toggleOpenEndedOptions("v")}
          style={{ width: 190, alignItems: "center" }}
        >
          <TooltipMenu.OptionIcon>
            <FontAwesomeIcon fixedWidth icon={solid("camcorder")} />
          </TooltipMenu.OptionIcon>
          <span>Video</span>
          <div style={{ marginLeft: "auto" }}>
            <SidesideSwitch enabled={!!data.children.openEndedType?.v} />
          </div>
        </TooltipMenu.Option>
        <TooltipMenu.Option
          disabled={checkIfdisableTooltip("a")}
          onClick={toggleOpenEndedOptions("a")}
          style={{ width: 190, alignItems: "center" }}
        >
          <TooltipMenu.OptionIcon>
            <FontAwesomeIcon fixedWidth icon={solid("microphone")} />
          </TooltipMenu.OptionIcon>
          <span>Audio</span>
          <div style={{ marginLeft: "auto" }}>
            <SidesideSwitch enabled={!!data.children.openEndedType?.a} />
          </div>
        </TooltipMenu.Option>
        <TooltipMenu.Option
          disabled={checkIfdisableTooltip("t")}
          onClick={toggleOpenEndedOptions("t")}
          style={{ width: 190, alignItems: "center" }}
        >
          <TooltipMenu.OptionIcon>
            <FontAwesomeIcon fixedWidth icon={solid("t")} />
          </TooltipMenu.OptionIcon>
          <span>Text</span>
          <div style={{ marginLeft: "auto" }}>
            <SidesideSwitch enabled={!!data.children.openEndedType?.t} />
          </div>
        </TooltipMenu.Option>
      </TooltipMenu.Options>
      <TooltipMenu.Footer>
        <TooltipMenu.ActionButton
          onClick={() => {
            setNodeSettingsPage(NODE_SETTINGS_PAGE.HOME);
            setOpen(false);
          }}
        >
          Save
        </TooltipMenu.ActionButton>
      </TooltipMenu.Footer>
    </>
  );
};
