import { useContext } from "react";
import { useStore } from "zustand";

import { currentNodeContext } from "modules/canvas/currentNodeContext";
import useCanvasFunctions from "modules/canvas/useCanvasFunctions";
import { TooltipMenu } from "common/components/TooltipMenu";

import { MainNodeSettingsContext } from "./MainNodeSettings.ui";
import { NODE_SETTINGS_PAGE } from "./MainNodeSettingStore";
import { useOutComesStore } from "modules/canvas/components/OutcomeNode";
import { NodeDataChildType } from "modules/canvas/data/types";

export const MainNodeSettingsDelete = () => {
  const { store, setOpen } = useContext(MainNodeSettingsContext);
  const { deleteNodeInBetweenWithRelations } = useCanvasFunctions();
  const { id, data } = useContext(currentNodeContext);
  const { setCurrentPage } = useStore(store);
  const { multipleOutComes, detachAnswer } = useOutComesStore();
  return (
    <>
      <TooltipMenu.Header>
        <TooltipMenu.BackNav
          onClick={() => setCurrentPage(NODE_SETTINGS_PAGE.HOME)}
        />
        <span>Are you sure?</span>
      </TooltipMenu.Header>
      <TooltipMenu.Body>
        <button
          onClick={() => {
            // alert("HERER");
            multipleOutComes?.forEach((outcome) => {
              if (
                data.children.type === NodeDataChildType.CHOICES ||
                data.children.type === NodeDataChildType.MULTIPLE
              )
                data.children.choices?.forEach((choice) => {
                  detachAnswer(choice.id, outcome.id);
                });
            });

            setCurrentPage(NODE_SETTINGS_PAGE.HOME);

            deleteNodeInBetweenWithRelations(id);
          }}
          type="button"
          className="main-node-settings__delete-btn"
        >
          Delete
        </button>
        <p>This action cannot be undone</p>
      </TooltipMenu.Body>
      <TooltipMenu.Footer>
        <TooltipMenu.ActionButton
          style={{ backgroundColor: "gray" }}
          onClick={() => setOpen(false)}
        >
          Cancel
        </TooltipMenu.ActionButton>
      </TooltipMenu.Footer>
    </>
  );
};
