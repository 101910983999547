export const PlayActionIcon = () => {
  return (
    <svg
      width="100"
      height="100"
      viewBox="0 0 100 100"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="50" cy="50" r="42" stroke="#D5D5D5" strokeWidth="2" />
      <circle cx="50" cy="50" r="36" fill="#D5D5D5" />
      <circle
        opacity="0.2"
        cx="50"
        cy="50"
        r="49"
        stroke="#D5D5D5"
        strokeWidth="2"
      />
      <path
        d="M53.4066 46.5313C52.9729 46.1406 52.3346 46.2094 51.9827 46.6907C51.6281 47.1707 51.6914 47.879 52.1251 48.2723C52.6008 48.7063 52.875 49.3344 52.875 49.9578C52.875 50.622 52.6008 51.2511 52.1236 51.6842C51.69 52.0778 51.6267 52.7858 51.9813 53.2658C52.1803 53.5379 52.4715 53.6784 52.7643 53.6784C52.9897 53.6784 53.2178 53.595 53.4051 53.4253C54.3558 52.6016 54.9 51.3406 54.9 49.9578C54.9 48.575 54.3558 47.3938 53.4066 46.5313ZM55.9589 43.0719C55.5278 42.6812 54.8899 42.7485 54.5338 43.2284C54.1792 43.7084 54.2425 44.4167 54.6749 44.81C56.1066 46.0672 56.925 47.9984 56.925 49.9578C56.925 51.9172 56.1049 53.8063 54.676 55.1469C54.2436 55.5404 54.1803 56.2484 54.5349 56.7284C54.7353 56.9991 55.0251 57.1396 55.3179 57.1396C55.5447 57.1396 55.7714 57.0562 55.96 56.885C57.8616 55.1984 58.95 52.6719 58.95 49.9578C58.95 47.2438 57.8616 44.7969 55.9589 43.0719ZM58.545 39.5656C58.1138 39.175 57.476 39.2437 57.1199 39.7222C56.7653 40.2022 56.8286 40.9105 57.261 41.3038C59.6208 43.4516 60.975 46.6203 60.975 49.9578C60.975 53.2953 59.6212 56.5063 57.26 58.6531C56.8275 59.0467 56.7643 59.7547 57.1189 60.2347C57.3173 60.5469 57.6084 60.6875 57.9038 60.6875C58.1305 60.6875 58.3573 60.6041 58.5458 60.4329C61.3758 57.8563 63 54.0547 63 49.9578C63 45.8609 61.3758 42.1409 58.545 39.5656ZM48.7069 39.6397C48.2217 39.3968 47.6518 39.4955 47.2544 39.8877L41.5603 45.5047H38.025C36.9066 45.5047 36 46.5116 36 47.7528V52.2491C36 53.4903 36.9066 54.4972 38.025 54.4972H41.562L47.2531 60.1128C47.5045 60.3594 47.8252 60.4906 48.15 60.4906C48.3372 60.4906 48.528 60.447 48.7052 60.3577C49.192 60.1156 49.5 59.5813 49.5 58.9953V41.0056C49.5 40.4164 49.192 39.8811 48.7069 39.6397Z"
        fill="white"
      />
    </svg>
  );
};
