import { CurrentNodeContextProvider } from "modules/canvas/currentNodeContext";
import { NodeData } from "modules/canvas/data/types";
import { useEffect } from "react";
import { Handle, NodeProps, Position } from "react-flow-renderer";
import { BranchedNodeUI } from "./BranchedNode.ui";

export function BranchNode(props: NodeProps<NodeData>) {
  const { data, isConnectable, selected } = props;

  useEffect(() => {
    props.selected && console.log(props);
  }, [props]);
  return (
    <>
      <Handle
        type="target"
        position={Position.Top}
        className="o-0"
        id="outcomeHandle"
        onConnect={(params) => console.log("handle onConnect", params)}
        isConnectable={isConnectable}
      />
      <Handle
        type="target"
        position={Position.Left}
        className="o-0"
        id="defaultHandle"
        onConnect={(params) => console.log("handle onConnect", params)}
        isConnectable={isConnectable}
      />
      <CurrentNodeContextProvider node={props}>
        <BranchedNodeUI data={data} selected={selected} />
      </CurrentNodeContextProvider>
      <Handle
        type="source"
        position={Position.Bottom}
        id="branchHandle"
        className="o-0"
        isConnectable={isConnectable}
      />
    </>
  );
}
