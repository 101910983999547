import { createContext, useContext, useEffect, useRef } from "react";
import { useControlledState } from "@react-stately/utils";
import {
  useFloating,
  offset,
  arrow,
  UseFloatingReturn,
  ReferenceType,
  autoUpdate,
  Placement,
} from "@floating-ui/react-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";

import "./TooltipMenu.scss";
import classNames from "classnames";
import ReactDOM from "react-dom";
import create from "zustand";
import { useEffectDebugger } from "modules/canvas/components/UploadingYourVideoModal/f";

export const useTooltipStore = create<{
  isPaneMoved: boolean;
  setIsPaneMoved: (isOpen: boolean) => void;
}>((set) => ({
  isPaneMoved: false,
  setIsPaneMoved(isOpen) {
    set((state) => ({ ...state, isPaneMoved: isOpen }));
  },
}));

interface ToolTipContextType {
  open: boolean | undefined;
  setOpen: (value: boolean) => void;
  arrowRef: React.MutableRefObject<HTMLDivElement | null>;
  tooltipMenuFloating: UseFloatingReturn<ReferenceType>;
  isCurrentTriggerClicked: boolean;
}

const TooltipContext = createContext({} as ToolTipContextType);

interface TooltipMenuProviderProps {
  children: React.ReactNode;
  defaultOpen?: boolean;
  open?: boolean;
  onOpenChange?: (value: boolean) => void;
  direction?: Placement;
}

const TooltipMenuProvider = ({
  children,
  defaultOpen = false,
  onOpenChange,
  open: openProps,
  direction = "right",
}: TooltipMenuProviderProps) => {
  const { isPaneMoved } = useTooltipStore(({ isPaneMoved }) => ({
    isPaneMoved,
  }));
  const [open, setOpen] = useControlledState(openProps, defaultOpen, (open) => {
    onOpenChange?.(!!open);
  });

  useEffect(() => {
    if (isPaneMoved) setOpen(false);
  }, [isPaneMoved]);

  const arrowRef = useRef<HTMLDivElement | null>(null);

  const tooltipMenuFloating = useFloating({
    whileElementsMounted: autoUpdate,
    placement: direction,

    strategy: "fixed",
    middleware: [
      offset(-16),
      arrow({
        element: arrowRef,
      }),
    ],
  });

  return (
    <TooltipContext.Provider
      value={{
        open,
        setOpen,
        arrowRef,
        tooltipMenuFloating,
        isCurrentTriggerClicked: false,
      }}
    >
      {children}
    </TooltipContext.Provider>
  );
};

/* ----------------------------------------------------------------------------
 * TooltipMenuTrigger
 * ---------------------------------------------------------------------------*/

interface TooltipMenuTriggerProps
  extends Omit<React.ComponentPropsWithoutRef<"button">, "onClick"> {
  children?: React.ReactNode;
  conditionForOpen?: boolean;
}

const TooltipMenuTrigger = ({
  children,
  ...props
}: TooltipMenuTriggerProps) => {
  const { tooltipMenuFloating, open, setOpen } = useContext(TooltipContext);
  const { setIsPaneMoved } = useTooltipStore(
    ({ isPaneMoved, setIsPaneMoved }) => ({ setIsPaneMoved, isPaneMoved })
  );
  const { reference } = tooltipMenuFloating;
  return (
    <button
      ref={reference}
      {...props}
      type="button"
      className={`tooltip-menu__trigger ${props.className}`}
      onClick={() => {
        // if (!props.conditionForOpen) return;
        setIsPaneMoved(true);
        setTimeout(() => {
          let p = open;
          setOpen(!p);
          setIsPaneMoved(false);
        });
      }}
    >
      {children}
    </button>
  );
};

/* ----------------------------------------------------------------------------
 * TooltipMenuContent
 * ---------------------------------------------------------------------------*/

interface TooltipMenuContentProps {
  children?: React.ReactNode;
}

const TooltipMenuContent = ({ children }: TooltipMenuContentProps) => {
  const { tooltipMenuFloating, arrowRef, open, setOpen } =
    useContext(TooltipContext);
  const {
    x,
    y,
    floating,
    placement,
    strategy,
    middlewareData: { arrow: { x: arrowX, y: arrowY } = {} },
  } = tooltipMenuFloating;

  const staticSide = {
    top: "bottom",
    right: "left",
    bottom: "top",
    left: "right",
  }[placement.split("-")[0]] as string;

  // useEffect(() => {
  //   document.querySelectorAll("body, [data-headlessui-portal]").forEach((el) => {
  //     const listener = (e: Event) => {
  //       let el = e.target as HTMLElement;
  //       if (
  //         // el?.classList?.contains("fa-gear") ||
  //         // el?.closest("fa-gear") ||
  //         el?.parentElement?.classList.contains("fa-gear") ||
  //         el?.parentElement?.classList.contains("fa-xmark") ||
  //         el?.parentElement?.classList.contains("tooltip-menu__trigger") ||
  //         el.closest("#tooltip")
  //       )
  //         return;
  //       // console.log(
  //       //   (e.target as HTMLElement).parentElement,
  //       //   el?.parentElement?.classList.contains("tooltip-menu__trigger")
  //       // );
  //       setOpen(false);
  //     };
  //     (el as HTMLDivElement).addEventListener("click", listener);
  //     // (el as HTMLDivElement).addEventListener("blur", listener);
  //   });
  // }, [setOpen]);

  const ref = useRef<HTMLDivElement>(null);
  useEffect(() => {
    /**
     * Alert if clicked on outside of element
     */
    function handleClickOutside(event: Event) {
      if (ref.current && !ref.current.contains(event.target as any)) {
        setOpen(false);
      }
    }
    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref, setOpen]);

  return open ? (
    ReactDOM.createPortal(
      <div ref={ref}>
        <div
          className="tooltip-menu__content"
          ref={floating}
          onClick={(e) => e.stopPropagation()}
          style={{
            position: strategy,
            top: y ?? 0,
            left: x ?? 0,
          }}
        >
          <div className="tooltip-menu__wrapper">{children}</div>
          {/* <div
        ref={arrowRef}
        className="tooltip-menu__arrow"
        style={{
          left: arrowX != null ? `${arrowX}px` : "",
          top: arrowY != null ? `${arrowY}px` : "",
          [staticSide]: "-12px",
        }}
      /> */}
        </div>
      </div>,
      document.getElementById("tooltip")!
    )
  ) : (
    <></>
  );
};

/* ----------------------------------------------------------------------------
 * TooltipMenuHeader
 * ---------------------------------------------------------------------------*/

interface TooltipMenuHeaderProps extends React.ComponentPropsWithoutRef<"div"> {
  children?: React.ReactNode;
}

const TooltipMenuHeader = ({ children, ...props }: TooltipMenuHeaderProps) => {
  return (
    <div
      {...props}
      className={classNames("tooltip-menu__header", props.className)}
    >
      {children}
    </div>
  );
};

/* ----------------------------------------------------------------------------
 * TooltipMenuHeaderBackNav
 * ---------------------------------------------------------------------------*/

interface TooltipMenuOptionProps
  extends React.ComponentPropsWithoutRef<"button"> {}

const TooltipMenuHeaderBackNav = ({ ...props }: TooltipMenuOptionProps) => {
  return (
    <button
      {...props}
      type="button"
      className={classNames("tooltip-menu__header-back-nav", props.className)}
    >
      <FontAwesomeIcon fixedWidth icon={solid("caret-left")} />
    </button>
  );
};

/* ----------------------------------------------------------------------------
 * TooltipMenuFooter
 * ---------------------------------------------------------------------------*/

interface TooltipMenuFooterProps extends React.ComponentPropsWithoutRef<"div"> {
  children?: React.ReactNode;
}
const TooltipMenuFooter = ({ children, ...props }: TooltipMenuFooterProps) => {
  return (
    <div
      {...props}
      className={classNames("tooltip-menu__footer", props.className)}
    >
      {children}
    </div>
  );
};

/* ----------------------------------------------------------------------------
 * TooltipMenuBody
 * ---------------------------------------------------------------------------*/

interface TooltipMenuBodyProps extends React.ComponentPropsWithoutRef<"div"> {
  children?: React.ReactNode;
}

const TooltipMenuBody = ({ children, ...props }: TooltipMenuBodyProps) => {
  return (
    <div
      {...props}
      className={classNames("tooltip-menu__body", props.className)}
    >
      {children}
    </div>
  );
};

/* ----------------------------------------------------------------------------
 * TooltipMenuOptions
 * ---------------------------------------------------------------------------*/

interface TooltipMenuOptionsProps
  extends React.ComponentPropsWithoutRef<"div"> {
  children?: React.ReactNode;
}

const TooltipMenuOptions = ({
  children,
  ...props
}: TooltipMenuOptionsProps) => {
  return (
    <div
      {...props}
      className={classNames("tooltip-menu__options", props.className)}
    >
      {children}
    </div>
  );
};

/* ----------------------------------------------------------------------------
 * TooltipMenuOption
 * ---------------------------------------------------------------------------*/

interface TooltipMenuOptionProps
  extends React.ComponentPropsWithoutRef<"button"> {
  children?: React.ReactNode;
}

const TooltipMenuOption = ({ children, ...props }: TooltipMenuOptionProps) => {
  return (
    <button
      {...props}
      type="button"
      className={classNames("tooltip-menu__option", props.className)}
    >
      {children}
    </button>
  );
};

/* ----------------------------------------------------------------------------
 * TooltipMenuOptionIcon
 * ---------------------------------------------------------------------------*/

interface TooltipMenuOptionIconProps
  extends React.ComponentPropsWithoutRef<"div"> {
  children?: React.ReactNode;
}

const TooltipMenuOptionIcon = ({
  children,
  ...props
}: TooltipMenuOptionIconProps) => {
  return (
    <div
      {...props}
      className={classNames("tooltip-menu__option-icon", props.className)}
    >
      {children}
    </div>
  );
};

/* ----------------------------------------------------------------------------
 * TooltipMenuActionButton
 * ---------------------------------------------------------------------------*/

interface TooltipMenuActionButtonProps
  extends React.ComponentPropsWithoutRef<"button"> {
  variant?: "primary" | "secondary";
  children?: React.ReactNode;
}

const TooltipMenuActionButton = ({
  variant = "primary",
  children,
  ...props
}: TooltipMenuActionButtonProps) => {
  return (
    <button
      {...props}
      type="button"
      className={classNames("tooltip-menu__action-btn", {
        "tooltip-menu__action-btn--primary": variant === "primary",
        "tooltip-menu__action-btn--secondary": variant === "secondary",
      })}
    >
      {children}
    </button>
  );
};

export let TooltipMenu = Object.assign(TooltipMenuProvider, {
  Trigger: TooltipMenuTrigger,
  Content: TooltipMenuContent,
  Header: TooltipMenuHeader,
  BackNav: TooltipMenuHeaderBackNav,
  Footer: TooltipMenuFooter,
  Body: TooltipMenuBody,
  Options: TooltipMenuOptions,
  Option: TooltipMenuOption,
  OptionIcon: TooltipMenuOptionIcon,
  ActionButton: TooltipMenuActionButton,
});
