import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  CanvasModal,
  CanvasModalHeader,
} from "common/components/CanvasModal/CanvasModal";
import { editData } from "modules/canvas/data/api/editData.api";
import { useCanvasStateStore } from "pages/Canvas";
import { useCallback, useState } from "react";
import { useReactFlow } from "react-flow-renderer";
import { SingleOutcomeType, useOutComesStore } from "../OutcomeNode";
import "./ErrorSavingModal.scss";

interface Props {
  isOpen?: boolean | undefined;
  onClose(value: boolean): void;
}
function ErrorSavingModal({ isOpen, onClose }: Props) {
  const [isRetrying, setIsRetrying] = useState(false);
  const { flowId, flowName, setShowErrorModal } = useCanvasStateStore();
  const { singleOutcome, multipleOutComes, currentOutcomeType } =
    useOutComesStore();
  const { getNodes, getEdges } = useReactFlow();

  const handleRetry = useCallback(() => {
    setIsRetrying(true);
    if (!flowId || !flowName) return;
    return editData(
      getNodes(),
      getEdges(),
      flowId,
      flowName,
      currentOutcomeType === "single"
        ? singleOutcome ?? ({} as SingleOutcomeType)
        : multipleOutComes ?? []
    )
      .then(() => {
        setShowErrorModal(false);
      })
      .finally(() => {
        setIsRetrying(false);
      });
  }, [
    currentOutcomeType,
    flowId,
    flowName,
    getEdges,
    getNodes,
    multipleOutComes,
    setShowErrorModal,
    singleOutcome,
  ]);

  return (
    <CanvasModal
      isClosableOnBlur={false}
      onlyCloseByButton={false}
      isOpen={isOpen}
      onClose={onClose}
      className="saving-error-modal"
      modalBodyClassName="saving-error-modal__body"
    >
      <CanvasModalHeader>
        <div className="upload-video-modal__header">
          <h2 className="upload-video-modal__heading" style={{ color: "red" }}>
            Oops. We had trouble reaching our servers
          </h2>
        </div>
      </CanvasModalHeader>
      <div
        style={{
          minHeight: "100px",
          display: "flex",
          alignItems: "center",
          paddingInline: "1rem",
        }}
      >
        Please check your internet connection and wait a few minutes before
        retrying saving. You may lose your work if we are not able to establish
        a connection to the Interface server
      </div>
      <div className="upload-video-modal__footer">
        <button
          disabled={isRetrying}
          onClick={handleRetry}
          className="upload-video-modal__save-btn"
        >
          <span>Retry Saving</span>
          {isRetrying && (
            <span>
              <FontAwesomeIcon fixedWidth spin icon={solid("loader")} />
            </span>
          )}
        </button>
      </div>
    </CanvasModal>
  );
}

export default ErrorSavingModal;
