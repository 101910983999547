import { useEffect, useState } from "react";

import {
  CanvasModal,
  CanvasModalHeader,
} from "common/components/CanvasModal/CanvasModal";

import "./SingleOutcomeModal.scss";
import { InputTextField } from "common/components/InputTextField/InputTextField";

import { LabelledSwitch } from "common/components/LabelledSwitch/LabelledSwitch";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { IOutcome, OUT_COMES_PAGE, useOutComesStore } from "../OutcomeNode";
import { nanoid } from "nanoid";
import useCanvasFunctions from "modules/canvas/useCanvasFunctions";
import { isValidURL } from "modules/canvas/utils";

interface SingleOutcomeModalProps {
  isOpen?: boolean | undefined;
  onClose(value: boolean): void;
}

export const SingleOutcomeModal = ({
  isOpen,
  onClose,
}: SingleOutcomeModalProps) => {
  const [name, setName] = useState<string>("");
  const [url, setUrl] = useState<string>("");
  const [isURLValid, setIsURLValid] = useState(true);
  const [enabled, setEnabled] = useState(true);
  const { setPage, setSingleOutcome, singleOutcome } = useOutComesStore();
  const { buildOutcomeNodes } = useCanvasFunctions();

  useEffect(() => {
    if (enabled) setIsURLValid(true);

    if (!enabled && url.length) setIsURLValid(isValidURL(url));
  }, [enabled, url]);

  useEffect(() => {
    if (!singleOutcome?.label) return;

    setName(singleOutcome.label);
    if (singleOutcome.redirectURL) {
      setUrl(singleOutcome.redirectURL);
    }

    setEnabled(singleOutcome.type === "flow");
  }, [singleOutcome?.label, singleOutcome?.redirectURL, singleOutcome?.type]);

  return (
    <>
      <CanvasModal
        isClosableOnBlur={false}
        onlyCloseByButton={false}
        isOpen={isOpen}
        onClose={onClose}
        className="single-outcome-modal"
      >
        <CanvasModalHeader>
          <div className="single-outcome-modal__header">
            {!singleOutcome?.label && (
              <button
                className="single-outcome-modal__back-button"
                onClick={() => setPage(OUT_COMES_PAGE.OUTCOMES)}
              >
                <FontAwesomeIcon fixedWidth icon={solid("caret-left")} />
                Back
              </button>
            )}
            <h2 className="single-outcome-modal__heading">Single Outcome</h2>
          </div>
        </CanvasModalHeader>
        <div className="single-outcome-modal__body">
          <div className="single-outcome-modal__content">
            <InputTextField
              value={name}
              placeholder="Single Outcome"
              onChange={(e) => setName(e.target.value)}
              label="Outcome Name"
            />

            <div className="single-outcome-modal__switch">
              <p className="single-outcome-modal__switch-label">Destination</p>
              <LabelledSwitch
                enabled={enabled}
                onChange={setEnabled}
                enabledLabel="FLOW"
                disabledLabel="URL"
              />
            </div>

            {enabled ? null : (
              <div className="single-outcome-modal__url-input-field">
                <InputTextField
                  hasError={!isURLValid}
                  value={url}
                  onChange={(e) => setUrl(e.target.value)}
                  label="Redirect URL"
                />
              </div>
            )}
          </div>
        </div>

        <div className="single-outcome-modal__footer">
          {!!name && (
            <button
              disabled={!isURLValid}
              onClick={() => {
                const outcome: IOutcome = {
                  isWebinarMode: false,
                  id: nanoid(),
                  label: name,
                  redirectURL: url,
                  type: !enabled ? "url" : "flow",
                  answers: [],
                  delay: 0,
                  nodeData: {
                    ctaLabels: [
                      {
                        id: nanoid(),
                        url: "",
                        value: "Option1",
                      },
                    ],
                    title: "Optional title / question",
                  },
                };
                setSingleOutcome(outcome);
                setTimeout(() => {
                  buildOutcomeNodes([outcome]);
                  onClose(false);
                });
              }}
              type="button"
              className="single-outcome-modal__continue-btn"
            >
              Save
            </button>
          )}
        </div>
      </CanvasModal>
    </>
  );
};
