import { useContext } from "react";
import { useStore } from "zustand";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";

import { TooltipMenu } from "common/components/TooltipMenu";

import { MainNodeSettingsContext } from "./MainNodeSettings.ui";
import { NODE_SETTINGS_PAGE } from "./MainNodeSettingStore";
import useCanvasFunctions from "modules/canvas/useCanvasFunctions";
import { currentNodeContext } from "modules/canvas/currentNodeContext";

import { SidesideSwitch } from "modules/canvas/components/VideoScreenRecordingModal/VideoRecorderSettings";
import { useOutComesStore } from "modules/canvas/components/OutcomeNode";
import { IS_ENV_LOCAL } from "modules/canvas/data/env";

export const MainNodeSettingsHome = () => {
  const { store, setOpen } = useContext(MainNodeSettingsContext);
  const { setCurrentPage: setNodeSettingsPage } = useStore(store);
  const { duplicateNode } = useCanvasFunctions();
  const { id, data } = useContext(currentNodeContext);

  const { editMultipleOutCome, editSingleOutcome, currentOutcomeType } =
    useOutComesStore();
  const { saveNodeData } = useCanvasFunctions();
  return (
    <>
      <TooltipMenu.Header>
        <span>Settings</span>
      </TooltipMenu.Header>
      <TooltipMenu.Options>
        {!!data.thumbnail && (
          <>
            <TooltipMenu.Option
              onClick={() =>
                setNodeSettingsPage(NODE_SETTINGS_PAGE.VIDEO_SETTINGS)
              }
            >
              <TooltipMenu.OptionIcon>
                <FontAwesomeIcon fixedWidth icon={solid("camcorder")} />
              </TooltipMenu.OptionIcon>
              <span>Video Settings</span>
            </TooltipMenu.Option>
          </>
        )}

        {!data.isOutcomeNode && (
          <TooltipMenu.Option
            onClick={() => setNodeSettingsPage(NODE_SETTINGS_PAGE.CHANGE_INPUT)}
          >
            <TooltipMenu.OptionIcon>
              <FontAwesomeIcon fixedWidth icon={solid("puzzle-piece")} />
            </TooltipMenu.OptionIcon>
            <span>Change Input</span>
          </TooltipMenu.Option>
        )}

        {/* <TooltipMenu.Option
          onClick={() => setCurrentPage(NODE_SETTINGS_PAGE.DELAY)}
        >
          <TooltipMenu.OptionIcon>
            <FontAwesomeIcon fixedWidth icon={solid("timer")} />
          </TooltipMenu.OptionIcon>
          <span>Delay</span>
        </TooltipMenu.Option> */}

        {/* <TooltipMenu.Option>
          <TooltipMenu.OptionIcon>
            <FontAwesomeIcon fixedWidth icon={solid("eye")} />
          </TooltipMenu.OptionIcon>
          <span>Caption</span>
        </TooltipMenu.Option> */}
        {!data.isOutcomeNode && (
          <>
            <TooltipMenu.Option onClick={() => duplicateNode(id)}>
              <TooltipMenu.OptionIcon>
                <FontAwesomeIcon fixedWidth icon={solid("clone")} />
              </TooltipMenu.OptionIcon>
              <span>Duplicate Step</span>
            </TooltipMenu.Option>

            <TooltipMenu.Option
              onClick={() => setNodeSettingsPage(NODE_SETTINGS_PAGE.REORDER)}
            >
              <TooltipMenu.OptionIcon>
                <FontAwesomeIcon fixedWidth icon={solid("sort")} />
              </TooltipMenu.OptionIcon>
              <span>Reorder</span>
            </TooltipMenu.Option>
          </>
        )}

        {data.thumbnail && (
          <TooltipMenu.Option
            style={{ width: 190, alignItems: "center" }}
            onClick={() => {
              if (data.isOutcomeNode) {
                const newData = { isWebinarMode: !data.isWebinarMode };
                if (currentOutcomeType === "multiple")
                  editMultipleOutCome(data.rootNodeId, newData);
                else editSingleOutcome(newData);
              }

              saveNodeData({ isWebinarMode: !data.isWebinarMode }, id);
            }}
          >
            <TooltipMenu.OptionIcon>
              <FontAwesomeIcon fixedWidth icon={solid("screen-users")} />
            </TooltipMenu.OptionIcon>
            <span>Webinar Mode</span>
            <div style={{ marginLeft: "auto" }}>
              <SidesideSwitch enabled={!!data.isWebinarMode} />
            </div>
          </TooltipMenu.Option>
        )}
        {
          /* Delay Answers */ data.thumbnail && (
            <TooltipMenu.Option
              onClick={() => setNodeSettingsPage(NODE_SETTINGS_PAGE.DELAY)}
            >
              <TooltipMenu.OptionIcon>
                <FontAwesomeIcon fixedWidth icon={solid("timer")} />
              </TooltipMenu.OptionIcon>
              <span>Delay Answers</span>
            </TooltipMenu.Option>
          )
        }

        {!data.isOutcomeNode && (
          <TooltipMenu.Option
            onClick={() => setNodeSettingsPage(NODE_SETTINGS_PAGE.DELETE)}
          >
            <TooltipMenu.OptionIcon>
              <FontAwesomeIcon fixedWidth icon={solid("trash-can")} />
            </TooltipMenu.OptionIcon>
            <span>Delete</span>
          </TooltipMenu.Option>
        )}

        {IS_ENV_LOCAL && (
          <TooltipMenu.Option
            onClick={() =>
              saveNodeData(
                {
                  video:
                    "https://vz-71565bb9-743.b-cdn.net/c08fba3d-f3e1-4f73-a3d8-5d2f17a2bb29/play_720p.mp4?key=386",
                  thumbnail:
                    "https://tryinterface-files.b-cdn.net/30/541/tRNwALOFizHBABAU5iEJMSCjO2qeOD.0000000.jpg",
                },
                id
              )
            }
          >
            <TooltipMenu.OptionIcon>
              <FontAwesomeIcon fixedWidth icon={solid("cog")} />
            </TooltipMenu.OptionIcon>
            <span>(dev) Set Video</span>
          </TooltipMenu.Option>
        )}
      </TooltipMenu.Options>
      <TooltipMenu.Footer>
        <TooltipMenu.ActionButton onClick={() => setOpen(false)}>
          Done
        </TooltipMenu.ActionButton>
      </TooltipMenu.Footer>
    </>
  );
};
