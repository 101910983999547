import {
  CanvasModal,
  CanvasModalHeader,
} from "common/components/CanvasModal/CanvasModal";
import "../ErrorSavingModal/ErrorSavingModal.scss";
interface Props {
  isOpen?: boolean | undefined;
  onClose(value: boolean): void;
}
function SmallScreenNotAllowedModal() {
  return (
    <CanvasModal
      isClosableOnBlur={false}
      onlyCloseByButton={false}
      isOpen={true}
      onClose={() => {}}
      className="saving-error-modal"
      modalBodyClassName="not-allowed-modal__body"
    >
      <CanvasModalHeader>
        <div className="upload-video-modal__header">
          <h2 className="upload-video-modal__heading" style={{ color: "red" }}>
            Small Screens Are Not Supported...
          </h2>
        </div>
      </CanvasModalHeader>
      <div
        style={{
          minHeight: "100px",
          display: "flex",
          alignItems: "center",
          paddingInline: "1rem",
        }}
      >
        Interface builder is not supported on tablets or mobile devices. Please
        access on your computer.
      </div>
      {/* <div className="upload-video-modal__footer">
        <button disabled={isRetrying} onClick={handleRetry} className="upload-video-modal__save-btn">
          <span>Retry Saving</span>
          {isRetrying && (
            <span>
              <FontAwesomeIcon fixedWidth spin icon={solid("loader")} />
            </span>
          )}
        </button>
      </div> */}
    </CanvasModal>
  );
}

export default SmallScreenNotAllowedModal;
