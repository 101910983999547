import { useContext } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { currentNodeContext } from "modules/canvas/currentNodeContext";
import { NodeDataChildType } from "modules/canvas/data/types";

interface Props {
  inputClass?: string;
  type?: string;
}

type OpenEndedBlockProps = {
  attr: "v" | "a" | "t";
};
const OpenEndedBlock = ({ attr }: OpenEndedBlockProps) => {
  const titleMap = {
    v: "VIDEO",
    a: "AUDIO",
    t: "TEXT",
  };
  const iconMap = {
    v: solid("video"),
    a: solid("microphone"),
    t: solid("a"),
  };
  return (
    <div
      style={{
        backgroundColor: "var(--theme-highlight-color)",
        padding: "12px",
        height: "80px",
        width: "74px",
        borderRadius: 10,
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-around",
        marginRight: 6,
      }}
    >
      <FontAwesomeIcon
        icon={iconMap[attr]}
        style={{
          color: "var(--white)",
          height: "26px",
          marginInline: "auto",
          display: "block",
        }}
      />
      <p style={{ color: "white", fontSize: 12, textAlign: "center" }}>
        {titleMap[attr]}
      </p>
    </div>
  );
};

export const MainNodeOpenEnded: React.FC<Props> = () => {
  const { data } = useContext(currentNodeContext);
  if (data.children.type !== NodeDataChildType.OPEN_ENDED) return <></>;
  return (
    <>
      {data.children.openEndedType?.v && <OpenEndedBlock attr="v" />}
      {data.children.openEndedType?.a && <OpenEndedBlock attr="a" />}
      {data.children.openEndedType?.t && <OpenEndedBlock attr="t" />}
    </>
  );
};
