import { CurrentNodeContextProvider } from "modules/canvas/currentNodeContext";
import { NodeData } from "modules/canvas/data/types";
// import useCanvasFunctions from "modules/canvas/useCanvasFunctions";
import { useEffect } from "react";

import { Handle, NodeProps, Position } from "react-flow-renderer";
import create from "zustand";
import { devtools } from "zustand/middleware";
import { MainNodeUI } from "./ui/MainNode.ui";

export enum VIDEO_MODAL_PAGES {
  ADD_VIDEO,
  UPLOAD_VIDEO,
  UPLOAD_PROGRESS,
  RECORD_VIDEO,
  ENABLE_PERMS,
  NAME_VIDEO,
  NONE,
}
interface IVideoModalStore {
  page: VIDEO_MODAL_PAGES;
  file?: File;
  audioId?: string;
  videoId?: string;
  currNodeId?: string;
  name?: string;
  setCurrNodeId: (id: string) => void;
  setPage: (page: VIDEO_MODAL_PAGES) => void;
  setFile: (file: File) => void;
  setAudioId: (id: string) => void;
  setVideoId: (id: string) => void;
  setName: (name: string) => void;
}

export const useVideoModalStore = create<
  IVideoModalStore,
  [["zustand/devtools", IVideoModalStore]]
>(
  devtools((set) => ({
    page: VIDEO_MODAL_PAGES.NONE,
    setPage(page) {
      set((state) => ({ ...state, page }));
    },
    setFile(file) {
      set((state) => ({ ...state, file }));
    },
    setAudioId(id) {
      set((state) => ({ ...state, audioId: id }));
    },

    setVideoId(id) {
      set((state) => ({ ...state, videoId: id }));
    },
    setCurrNodeId(id) {
      set((state) => ({ ...state, currNodeId: id }));
    },
    setName(name) {
      set((state) => ({ ...state, name }));
    },
  }))
);
function MainNode(props: NodeProps<NodeData>) {
  const { isConnectable } = props;
  const setId = useVideoModalStore((state) => state.setCurrNodeId);
  // const { formatCanvas } = useCanvasFunctions();
  useEffect(() => {
    if (props.selected) {
      console.log("MAIN NODE", props);
      setId(props.id);
    }
  }, [props, setId]);

  return (
    <>
      <Handle
        type="target"
        className="o-0"
        position={Position.Top}
        onConnect={(params) => console.log("handle onConnect", params)}
        isConnectable={isConnectable}
      />
      {/* <button onClick={() => formatCanvas()}>Format</button> */}
      <div className="nodrag nowheel">
        <CurrentNodeContextProvider node={props}>
          <MainNodeUI />
        </CurrentNodeContextProvider>
      </div>
      <Handle
        type="source"
        position={Position.Bottom}
        id="a"
        className="o-0"
        isConnectable={isConnectable}
      />

      {/* <Handle
        type="source"
        className="o-0"
        id="bottomBranchSource"
        position={Position.Bottom}
        onConnect={(params) => console.log("handle onConnect", params)}
        isConnectable={isConnectable}
      /> */}
      <Handle
        type="source"
        position={Position.Right}
        id="branchHandle"
        className="o-0"
        isConnectable={isConnectable}
      />

      {/* <Handle
        type="target"
        position={Position.Right}
        style={{ transform: "translateY(50px)" }}
        id="rightBranchTarget"
        className="o-0"
        isConnectable={isConnectable}
      /> */}
    </>
  );
}

export default MainNode;
