import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";

import InlineTextEdit from "common/components/InlineTextEdit";
import {
  NodeDataChildChoice,
  NodeDataChildType,
} from "modules/canvas/data/types";
import { PropsWithChildren, useContext } from "react";
import useCanvasFunctions from "modules/canvas/useCanvasFunctions";
import { currentNodeContext } from "modules/canvas/currentNodeContext";
import { MainNodeChoiceSettings } from "./MainNodeChoiceSettings";
import { useOutComesStore } from "modules/canvas/components/OutcomeNode";
import classNames from "classnames";
import { MainNodeChoiceOutcomesSettings } from "./MainNodeChoiceOutcomesSettings";
import React from "react";
import { TooltipMenu } from "common/components/TooltipMenu";

interface MainNodeChoiceUIProps {
  choice: NodeDataChildChoice;
  isThumbnailLoaded: boolean;
}

export const MainNodeChoiceUI: React.FC<
  PropsWithChildren<MainNodeChoiceUIProps>
> = React.memo((props) => {
  const { saveModifiedChoiceValue } = useCanvasFunctions();
  const { id, data } = useContext(currentNodeContext);
  const { editOutcomeCta } = useOutComesStore();

  const isDisabled =
    (data.children.type === NodeDataChildType.MULTIPLE ||
      data.children.type === NodeDataChildType.CHOICES) &&
    data.children.choices!.length <= 1;

  return (
    <InlineTextEdit
      editableValue={props.choice.value}
      onSubmit={(val) => {
        val = val.trim();
        if (data.isOutcomeNode)
          editOutcomeCta(props.choice.id, data.rootNodeId, { value: val });
        saveModifiedChoiceValue({ ...props.choice, value: val }, id);
      }}
      inputClass="main-node-choice__edit-input"
    >
      {(enableEdit) => (
        <div
          className={classNames(
            "main-node-choice",
            props.isThumbnailLoaded && "transparent"
          )}
          style={{ zIndex: 11111 }}
        >
          <button className="main-node-choice__label" onClick={enableEdit}>
            {props.choice.value}
          </button>

          <div className="main-node-choice__actions">
            <DeleteChoiceUI choice={props.choice} disabled={isDisabled} />

            {props.children}

            {data.children.type !== NodeDataChildType.MULTIPLE &&
              (data.isOutcomeNode ? (
                <MainNodeChoiceOutcomesSettings choice={props.choice} />
              ) : (
                <MainNodeChoiceSettings choice={props.choice} />
              ))}
          </div>
        </div>
      )}
    </InlineTextEdit>
  );
});

interface DeleteChoiceUIProps {
  choice: NodeDataChildChoice;
  disabled?: boolean;
}

const DeleteChoiceUI = (props: DeleteChoiceUIProps) => {
  const { deleteChoice } = useCanvasFunctions();
  const { id, data } = useContext(currentNodeContext);
  const { deleteOutcomeCta, detachAnswer, multipleOutComes } =
    useOutComesStore();

  const handleDeleteChoice = () => {
    if (data.isOutcomeNode) deleteOutcomeCta(props.choice.id, data.rootNodeId);
    deleteChoice(props.choice, id);
    multipleOutComes?.forEach((outcome) =>
      detachAnswer(props.choice.id, outcome.id)
    );
  };

  if (!props.choice.branchId || data.isOutcomeNode)
    return (
      <button disabled={props.disabled} className="main-node-choice__action ">
        <FontAwesomeIcon
          fixedWidth
          icon={solid("trash-can")}
          onClick={handleDeleteChoice}
        />
      </button>
    );
  return (
    <TooltipMenu>
      <TooltipMenu.Trigger
        disabled={props.disabled}
        className="main-node-choice__action"
        aria-label="Delete Choice"
      >
        <FontAwesomeIcon fixedWidth icon={solid("trash-can")} />
      </TooltipMenu.Trigger>
      <TooltipMenu.Content>
        <TooltipMenu.Header>Are you sure?</TooltipMenu.Header>
        <TooltipMenu.Body>
          <button
            onClick={handleDeleteChoice}
            type="button"
            className="main-node-settings__delete-btn"
          >
            Delete
          </button>
          <p>
            Deleting selected option will
            <br /> delete the branch also.
          </p>
        </TooltipMenu.Body>
      </TooltipMenu.Content>
    </TooltipMenu>
  );
};
