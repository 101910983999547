import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Disclosure } from "@headlessui/react";
import { useMapOutcomeStore } from "./MapOutcomeAnswerModalStore";
import { nanoid } from "nanoid";

export type Question = {
  id: string | number;
  hierarchyId: string;
  label: string;
  isOpen: boolean;
  isActive: boolean;
  answers: Array<{
    choiceId: string;
    questionId: string;
    questionHierarchyId: string;
  }>;
  branches: Question[];
};

// let tempData = [
//   {
//     id: 1,
//     label: "How would you describe yourself in today's world…?",
//     branches: [
//       {
//         id: 2,
//         label:
//           "When it comes to establishing a business  online, what is most important?",
//       },
//       {
//         id: 3,
//         label:
//           "If I could grant you one wish over the next year for business growth it would be",
//         branches: [
//           {
//             id: 3,
//             label:
//               "If I could grant you one wish over the next year for business growth it would be",
//           },
//         ],
//       },
//     ],
//   },
//   {
//     id: 4,
//     label:
//       "What is the number 1 challenge currently holding you back from success?",
//   },
// ];

export const MapOutcomeAnswerModalQuestionsList = () => {
  const { questionList: q } = useMapOutcomeStore();

  return (
    <ul className="map-outcome-modal__question-list">
      {q.map((item) => {
        return <MapOutcomeAnswerModalQuestionItem key={item.id} data={item} />;
      })}
    </ul>
  );
};

interface MapOutcomeAnswerModalQuestionProps {
  data: Question;
}

const MapOutcomeAnswerModalQuestionItem = ({
  data,
}: MapOutcomeAnswerModalQuestionProps) => {
  const { setCurrentSelectedQuestion, toggleQuestion, getCurrentQuestion } =
    useMapOutcomeStore();

  const handleClick = () => {
    setCurrentSelectedQuestion(data.id.toString());
    toggleQuestion(data.hierarchyId);
  };

  const classes = {
    questionBtn: `map-outcome-modal__question-btn ${
      getCurrentQuestion()?.id === data.id && "active"
    }`,
    questionLabel: "map-outcome-modal__question-label",
  };
  const hasChildren = data?.branches?.length > 0;

  return (
    <li className="map-outcome-modal__question-item">
      {!hasChildren && (
        <button className={classes.questionBtn} onClick={handleClick}>
          <div className={classes.questionLabel}>{data.label}</div>
        </button>
      )}

      {hasChildren && (
        <Disclosure defaultOpen={data.isOpen} key={nanoid()}>
          {({ open }) => (
            <>
              <Disclosure.Button
                className={classes.questionBtn}
                onClick={handleClick}
              >
                <div className={classes.questionLabel}>{data.label}</div>
                <div className="map-outcome-modal__question-accordion-btn">
                  <>
                    {!open && (
                      <FontAwesomeIcon
                        fixedWidth
                        icon={solid("chevron-down")}
                      />
                    )}
                    {open && (
                      <FontAwesomeIcon fixedWidth icon={solid("chevron-up")} />
                    )}
                  </>
                </div>
              </Disclosure.Button>

              <Disclosure.Panel>
                <ul className="map-outcome-modal__question-sub-branch-list">
                  {data.branches.map((item: any) => (
                    <MapOutcomeAnswerModalQuestionItem
                      key={item.id}
                      data={item}
                    />
                  ))}
                </ul>
              </Disclosure.Panel>
            </>
          )}
        </Disclosure>
      )}
    </li>
  );
};
