import { useDndMonitor, useDraggable, useDroppable } from "@dnd-kit/core";
import classNames from "classnames";
import useCanvasFunctions from "modules/canvas/useCanvasFunctions";
import { useMemo, useState } from "react";
import { IOutcome, OUT_COMES_PAGE, useOutComesStore } from "../OutcomeNode";
import { Question } from "./MapOutcomeAnswerModalQuestionsList";
import { useMapOutcomeStore } from "./MapOutcomeAnswerModalStore";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { TooltipMenu } from "common/components/TooltipMenu";
import { log } from "console";
import { isChoiceSelected } from "modules/canvas/utils/isChoiceSelected";

const badgeColors = ["blue", "green", "teal", "purple"];
export const MapOutcomeAnswerModalOutcomesList = () => {
  const { multipleOutComes } = useOutComesStore();

  return (
    <ul className="map-outcome-modal__outcome-list">
      {multipleOutComes?.map((item) => (
        <MapOutcomeAnswerModalOutcomesItem key={item.id} data={item} />
      ))}
    </ul>
  );
};

interface MapOutcomeAnswerModalAnswerItemProps {
  data: IOutcome;
}

const MapOutcomeAnswerModalOutcomesItem = ({
  data,
}: MapOutcomeAnswerModalAnswerItemProps) => {
  const [isCurrentValid, setIsCurrentDragValid] = useState(false);
  const { addAnswerToOutCome } = useOutComesStore();
  const { setNodeRef, isOver } = useDroppable({
    id: `outcome-${data.id}`,
    data: {
      supports: ["answer-item"],
      ...data,
    },
  });

  useDndMonitor({
    onDragMove({ active }) {
      const isValid = active?.data?.current?.type === "answer-item";
      if ((isOver && isValid) !== isCurrentValid) {
        setIsCurrentDragValid(isOver && isValid);
      }
    },
    onDragEnd(e) {
      if (isCurrentValid) {
        console.log(e.active.data.current);
        const ans = e.active.data.current! as Question["answers"][0];
        console.log({ ans });
        addAnswerToOutCome(
          {
            choiceId: ans.choiceId,
            questionId: ans.questionId,
            weight: "1.00",
            questionListHierarchyId: ans.questionHierarchyId,
          },
          data.id
        );
      }

      setIsCurrentDragValid(false);
    },
  });

  return (
    <li
      ref={setNodeRef}
      className={classNames("map-outcome-modal__outcome-item", {
        "map-outcome-modal__outcome-item--active": isCurrentValid,
      })}
    >
      <div className="map-outcome-modal__outcome-label">{data.label}</div>
      <div className="map-outcome-modal__outcome-subtitle">
        Connected Answers:
      </div>
      <ul className="map-outcome-modal__outcome-mapped-list">
        {data.answers?.map((ans) => (
          <MapOutcomeAnswerModalOutcomesMappedItem
            data={ans}
            key={ans.choiceId}
            outcomeId={data.id}
          />
        ))}
      </ul>
    </li>
  );
};

interface MapOutcomeAnswerModalAnswerMappedItemProps {
  data: IOutcome["answers"][0];
  outcomeId: string;
}

const MapOutcomeAnswerModalOutcomesMappedItem = ({
  data,
  outcomeId,
}: MapOutcomeAnswerModalAnswerMappedItemProps) => {
  const { isWeightModeEnabled } = useOutComesStore();
  const { attributes, listeners, setNodeRef, isDragging } = useDraggable({
    id: `choice-${data.choiceId}-${outcomeId}`,
    // disabled: isWeightModeEnabled,

    data: {
      type: "mapped-outcome-item",
      data,
      outcomeId,
    },
  });

  const { setPage, setSelectedOutcomeId } = useOutComesStore();
  const {
    setCurrentSelectedAnswer,
    toggleQuestion,
    setOpenedAnswer,
    flatQuestionList,
  } = useMapOutcomeStore();

  const { getNode } = useCanvasFunctions();

  const [shouldShowSettings, setShouldShowSettings] = useState(false);
  const [isSettingMenuOpen, setIsSettingMenuOpen] = useState(false);

  const currQuestion = getNode(data.questionId);
  const label = isChoiceSelected(currQuestion)
    ? currQuestion.data.children.choices?.find((c) => c.id === data.choiceId)
        ?.value ?? ""
    : "";

  const currAnsIndex = isChoiceSelected(currQuestion)
    ? currQuestion.data.children.choices?.findIndex(
        (c) => c.id === data.choiceId
      )
    : null;

  const color =
    currAnsIndex && currAnsIndex !== -1
      ? badgeColors[currAnsIndex % badgeColors.length]
      : null;

  const hierarchyId = useMemo(() => {
    if (data.questionListHierarchyId) return data.questionListHierarchyId;

    const question = flatQuestionList.find((q) => q.id === data.questionId);
    if (!question) return "";

    const ans = question.answers.find((ans) => ans.choiceId === data.choiceId);
    return ans?.questionHierarchyId ?? "";
  }, [
    data.choiceId,
    data.questionId,
    data.questionListHierarchyId,
    flatQuestionList,
  ]);

  console.log({ hierarchyId });

  return (
    <li
      ref={setNodeRef}
      className={classNames("map-outcome-modal__outcome-mapped-item", color, {
        "map-outcome-modal__outcome-mapped-item--dragging": isDragging,
      })}
      title={label}
      onMouseOver={() => setShouldShowSettings(true)}
      onMouseLeave={() => !isSettingMenuOpen && setShouldShowSettings(false)}
      onClick={() => {
        toggleQuestion(hierarchyId, data.questionId);
        setOpenedAnswer(data.choiceId);
      }}
      {...listeners}
      {...attributes}
    >
      {label[0]?.toUpperCase()}

      {shouldShowSettings && (
        <TooltipMenu
          defaultOpen={isSettingMenuOpen}
          onOpenChange={(open) => {
            setIsSettingMenuOpen(open);
            setShouldShowSettings(open);
          }}
        >
          <TooltipMenu.Trigger className="mapped-answer-settings-btn">
            <FontAwesomeIcon fixedWidth icon={solid("cog")} />
          </TooltipMenu.Trigger>
          <TooltipMenu.Content>
            <TooltipMenu.Header>
              <span>Settings</span>
            </TooltipMenu.Header>
            <TooltipMenu.Options>
              <TooltipMenu.Option
                onClick={() => {
                  setCurrentSelectedAnswer(data.choiceId);
                  setSelectedOutcomeId(outcomeId);
                  setTimeout(
                    () => setPage(OUT_COMES_PAGE.CHANGE_WEIGHTING),
                    14
                  );
                }}
              >
                <TooltipMenu.OptionIcon>
                  <FontAwesomeIcon
                    fixedWidth
                    icon={solid("balance-scale-left")}
                  />
                </TooltipMenu.OptionIcon>
                <span>Adjust Weighting</span>
              </TooltipMenu.Option>

              {/* <TooltipMenu.Option
                onClick={() => {
                  console.log("FInding path for ", data);
                  toggleQuestion(hierarchyId, data.questionId);
                }}
              >
                <TooltipMenu.OptionIcon>
                  <FontAwesomeIcon fixedWidth icon={solid("lines-leaning")} />
                </TooltipMenu.OptionIcon>
                <span>Open Question</span>
              </TooltipMenu.Option> */}
            </TooltipMenu.Options>
            <TooltipMenu.Footer>
              <TooltipMenu.ActionButton
                onClick={() => {
                  setIsSettingMenuOpen(false);
                  setShouldShowSettings(false);
                }}
              >
                Done
              </TooltipMenu.ActionButton>
            </TooltipMenu.Footer>
          </TooltipMenu.Content>
        </TooltipMenu>
      )}
    </li>
  );
};

export const MapOutcomeAnswerModalOutcomesMappedItemOverlay = ({
  data,
}: {
  data: { data: IOutcome["answers"][0] };
  outcomeId: string;
}) => {
  const { getNode } = useCanvasFunctions();
  const currQuestion = getNode(data.data.questionId);

  const label = isChoiceSelected(currQuestion)
    ? currQuestion.data.children.choices?.find(
        (c) => c.id === data.data.choiceId
      )?.value ?? ""
    : "";

  const currAnsIndex = isChoiceSelected(currQuestion)
    ? currQuestion.data.children.choices?.findIndex(
        (c) => c.id === data.data.choiceId
      )
    : null;

  const color =
    currAnsIndex && currAnsIndex !== -1
      ? badgeColors[currAnsIndex % badgeColors.length]
      : null;
  console.log({ color });

  console.log("OVERLAY DATA", label, data);

  return (
    <li
      className={`map-outcome-modal__outcome-mapped-item map-outcome-modal__outcome-mapped-item--drag-item ${color}`}
    >
      {label?.[0]?.toUpperCase()}
    </li>
  );
};
