// @ts-nocheck
import { Edge, Node } from "react-flow-renderer";
import {
  ChoiceActionTypes,
  EdgeTypes,
  NodeData,
  NodeDataChildChoice,
  NodeDataChildType,
  NodeTypes,
} from "./types";
import { edgeStyles } from "../utils";
import { nanoid } from "nanoid";
import { sampleOptinInputData } from "../components/OptInNode/OptInNode.ui";

export const ABSOLUTE_ROOT_NODE_ID = "ABSOLUTE_ROOT_NODE_ID";
export const choices: () => NodeDataChildChoice[] = () => [
  {
    id: nanoid(),
    value: "Option 1",
    selectedOption: ChoiceActionTypes.CONTINUE,
  },

  {
    id: nanoid(),
    value: "Option 2",
    selectedOption: ChoiceActionTypes.CONTINUE,
  },
];

export const initialNodes: Node<NodeData>[] = [
  {
    id: ABSOLUTE_ROOT_NODE_ID,
    data: {
      step: 0,
      label: "Welcome to my quiz",
      customSmallLabel: "WELCOME",
      rootNodeId: ABSOLUTE_ROOT_NODE_ID,
      children: { type: NodeDataChildType.NONE },
      hasTargetNode: true,
      ctaLabel: "Start Quiz",
    },
    position: { x: 0, y: 0 },
    type: NodeTypes.STARTING_NODE,
  },

  {
    id: "2",
    type: NodeTypes.CARD,
    data: {
      label: "Optional title / question",
      step: 1,
      customSmallLabel: "STEP 1",
      rootNodeId: ABSOLUTE_ROOT_NODE_ID,
      children: { type: NodeDataChildType.CHOICES, choices: choices() },
      hasTargetNode: true,
    },
    position: { x: 0, y: 605 },
  },

  {
    id: "3",
    type: NodeTypes.OPTIN_NODE,
    data: {
      optinData: sampleOptinInputData,
      label: "Your Results are Ready!",
      descLabel:
        "Enter your details below to get instant access to your report now.",
      nameLabel: "Your Name",
      emailLabel: "Your Email",
      phoneLabel: "Phone",
      buttonLabel: "Continue",

      step: 2,
      customSmallLabel: "OPTIN SCREEN",
      rootNodeId: ABSOLUTE_ROOT_NODE_ID,
      children: { type: NodeDataChildType.NONE },
      hasTargetNode: true,
    },
    position: { x: 0, y: 605 * 2 },
  },
  {
    id: "4",
    type: NodeTypes.OUTCOME_NODE,
    data: {
      label: "Outcomes",
      step: 3,
      customSmallLabel: "OUTCOMES",
      rootNodeId: ABSOLUTE_ROOT_NODE_ID,
      children: { type: NodeDataChildType.NONE },
      hasTargetNode: false,
    },
    position: { x: 0, y: 605 * 3 },
  },
];

export const initialEdges: Edge[] = [
  edgeStyles({
    id: "e1-2",
    source: ABSOLUTE_ROOT_NODE_ID,
    target: "2",
    type: EdgeTypes.BUTTON_EDGE,
  }),
  edgeStyles({
    id: "e2-3",
    source: "2",
    target: "3",
    type: EdgeTypes.BUTTON_EDGE,
  }),
  edgeStyles({
    id: "e3-4",
    source: "3",
    target: "4",
  }),
  edgeStyles({
    id: "e4-5",
    source: "4",
    target: "5",
    type: EdgeTypes.BUTTON_EDGE,
  }),
];

export const dummyFunction = (..._: any[]) => {};
