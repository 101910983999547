import { API_URL } from "../apiClient";

export const getData = (survey_id: string) => {
  const formdata = new FormData();
  // formdata.append("token", token);
  formdata.append("survey_id", survey_id);
  return fetch(`${API_URL}/get-survey`, {
    method: "POST",
    headers: {
      Accept: "application/json",
    },
    credentials: "include",
    body: formdata,
  })
    .then((res) => {
      if (res.ok) return res.json();
      throw new Error("Not ok");
    })
    .then((data) => {
      return data as Main;
    });
};
export interface Main {
  status: number;
  message: string;
  data: MainData;
}

export interface MainData {
  survey_id: number;
  uuid: string;
  title: string;
  nodes: Node[];
  edges: Edge[];
  outcome: Outcome[] | Outcome;
}

export interface Edge {
  style: Style;
  markerEnd: MarkerEnd;
  id: string;
  source: string;
  target: string;
  type?: EdgeType;
  animated?: boolean;
  sourceHandle?: string;
  labelBgPadding?: [number, number];
  data?: EdgeData;
  selected?: boolean;
}

export interface EdgeData {
  isTargetBranchNode: boolean;
}

export interface MarkerEnd {
  type: MarkerEndType;
  strokeWidth: number;
  color: Color;
}

export enum Color {
  E0E7F2 = "#E0E7F2",
}

export enum MarkerEndType {
  Arrowclosed = "arrowclosed",
}

export interface Style {
  stroke: Color;
  strokeWidth: number;
}

export enum EdgeType {
  ButtonEdge = "buttonEdge",
  Step = "step",
}

export interface Node {
  width: number;
  height: number;
  id: string;
  data: NodeData;
  position: Position;
  type: string;
  positionAbsolute: Position;
  "server-id": number;
  selected?: boolean;
}

export interface NodeData {
  label?: string;
  step: number;
  rootNodeId: string;
  children: Children;
  hasTargetNode: boolean;
  customSmallLabel?: string;
  brachedFromChoice?: string;
  derivedFromNodeId?: string;
  selectedLastBranchSetting?: number;
  selectedLastBranchSettingValue?: string;
}

export interface Children {
  type: string;
  choices?: Choice[];
}

export interface Choice {
  id: string;
  value: string;
  selectedOption: number;
  "server-id": number;
  branchId?: string;
}

export interface Position {
  x: number;
  y: number;
}

export interface Outcome {
  id: string;
  "server-id": number;
  label: string;
  redirectURL: null;
  answers?: Answer[];
}

export interface Answer {
  "server-id": number;
  questionId: string;
  choiceId: string;
  weight: string;
}
