import { forwardRef } from "react";
import classNames from "classnames";

import "./NodeCardSideAction.scss";

interface NodeCardSideActionProps
  extends React.ComponentPropsWithoutRef<"div"> {
  position: "bottom" | "right";
  children?: React.ReactNode;
}

const Root = forwardRef<HTMLDivElement, NodeCardSideActionProps>(
  ({ children, position, ...props }, forwardRef) => {
    return (
      <div
        ref={forwardRef}
        className={classNames(
          {
            "node-card-side-action--bottom": position === "bottom",
            "node-card-side-action--right": position === "right",
          },
          "node-card-side-action",
          props.className
        )}
        {...props}
      >
        {children}
      </div>
    );
  }
);

interface NodeCardSideActionWrapperProps
  extends React.ComponentPropsWithoutRef<"div"> {
  children?: React.ReactNode;
}

const Wrapper = forwardRef<HTMLDivElement, NodeCardSideActionWrapperProps>(
  ({ children, ...props }, forwardRef) => {
    return (
      <div
        ref={forwardRef}
        className={classNames(
          "node-card-side-action__wrapper",
          props.className
        )}
        {...props}
      >
        {children}
      </div>
    );
  }
);

interface NodeCardSideActionButtonProps
  extends React.ComponentPropsWithoutRef<"button"> {
  children?: React.ReactNode;
}

const Button = forwardRef<HTMLButtonElement, NodeCardSideActionButtonProps>(
  ({ children, ...props }, forwardRef) => {
    return (
      <button
        ref={forwardRef}
        className={classNames("node-card-side-action__action", props.className)}
        {...props}
      >
        {children}
      </button>
    );
  }
);

export let NodeCardSideAction = Object.assign(Root, { Wrapper, Button });
