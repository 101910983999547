import { useEffect } from "react";

import {
  CanvasModal,
  CanvasModalHeader,
} from "common/components/CanvasModal/CanvasModal";

import "./AddVideoModal.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { useVideoModalStore, VIDEO_MODAL_PAGES } from "../MainNode/MainNode";
import { useModalStore } from "../Modals";

interface AddVideoModalProps {
  isOpen?: boolean | undefined;
  onClose(value: boolean): void;
}
export const AddVideoModal = ({ isOpen, onClose }: AddVideoModalProps) => {
  const { setPage } = useVideoModalStore();
  const { setShouldShowAnimation } = useModalStore();

  useEffect(() => {
    if (isOpen) setTimeout(() => setShouldShowAnimation(false), 500);
  }, [isOpen, setShouldShowAnimation]);
  const openRecorder = async () => {
    let agent = navigator.userAgent;
    if (agent.match(/chrome|chromium|crios/i)) {
      let camPerm = await navigator.permissions.query({
        name: "camera" as any,
      });
      let micPerm = await navigator.permissions.query({
        name: "microphone" as any,
      });
      if (camPerm.state !== "granted" || micPerm.state !== "granted")
        setPage(VIDEO_MODAL_PAGES.ENABLE_PERMS);
      else setPage(VIDEO_MODAL_PAGES.RECORD_VIDEO);
    } else setPage(VIDEO_MODAL_PAGES.RECORD_VIDEO);
  };
  return (
    <CanvasModal
      isClosableOnBlur
      onlyCloseByButton
      isOpen={isOpen}
      onClose={onClose}
      className="add-video-modal"
    >
      <CanvasModalHeader>
        <div className="add-video-modal__header">
          <h2 className="add-video-modal__heading">Add Video</h2>
        </div>
      </CanvasModalHeader>
      <div className="add-video-modal__body">
        <div className="add-video-modal__content">
          <button
            onClick={openRecorder}
            type="button"
            className={`add-video-modal__choice-btn`}
          >
            <div className="add-video-modal__content-wrapper">
              <div className="add-video-modal__icon-wrapper">
                <FontAwesomeIcon fixedWidth icon={solid("video")} />
              </div>
              <div className="add-video-modal__text-content-wrapper">
                <h2>Record Video</h2>
                <p>Directly inside Interface</p>
              </div>
            </div>
          </button>

          <button
            type="button"
            className={`add-video-modal__choice-btn`}
            onClick={() => setPage(VIDEO_MODAL_PAGES.UPLOAD_VIDEO)}
          >
            <div className="add-video-modal__content-wrapper">
              <div className="add-video-modal__icon-wrapper">
                <FontAwesomeIcon fixedWidth icon={solid("cloud-arrow-up")} />
              </div>

              <div className="add-video-modal__text-content-wrapper">
                <h2>Upload Video</h2>
                <p>Import a file from your computer</p>
              </div>
            </div>
          </button>

          {/* <button type="button" className={`add-video-modal__choice-btn`}>
            <div className="add-video-modal__content-wrapper">
              <div className="add-video-modal__icon-wrapper">
                <FontAwesomeIcon fixedWidth icon={solid("book-open-cover")} />
              </div>
              <div className="add-video-modal__text-content-wrapper">
                <h2>Choose from Library</h2>
                <p>Choose from your existing assets</p>
              </div>
            </div>
          </button> */}
        </div>
      </div>
    </CanvasModal>
  );
};
