import {
  IOutcome,
  SingleOutcomeType,
} from "modules/canvas/components/OutcomeNode";
import { Edge, Node } from "react-flow-renderer";
import { API_URL } from "../apiClient";
import { NodeData, EdgeData } from "../types";

export const editData = async (
  nodes: Node<NodeData>[],
  edges: Edge<EdgeData>[],
  flowId: string,
  title: string = "Untitled Survey",
  outcomes: IOutcome[] | SingleOutcomeType,
  abortSignal?: AbortSignal
) => {
  const formdata = new FormData();
  // formdata.append("token", token)
  formdata.append("title", title);
  formdata.append("survey_id", flowId);

  formdata.append("nodes", JSON.stringify(nodes));
  formdata.append("edges", JSON.stringify(edges));
  formdata.append("outcome", JSON.stringify(outcomes));
  return fetch(`${API_URL}/edit-survey`, {
    method: "POST",
    headers: {
      Accept: "application/json",
    },
    credentials: "include",
    body: formdata,
    signal: abortSignal,
  })
    .then((res) => {
      if (res.ok) return res.json();
      throw new Error("Not ok");
    })
    .then((data) => {
      // alert("Saved the edits! Quiz id: " + data.data.uuid);
      console.log(data);
    });
};
