export type CardNodeData = {};

export type RecursivePartial<T> = {
  [P in keyof T]?: RecursivePartial<T[P]>;
};

export enum NodeTypes {
  CARD = "card",
  BRANCHED_NODE = "branchedNode",
  STARTING_NODE = "startingNode",
  OUTCOME_NODE = "outcomeNode",
  OPTIN_NODE = "optinNode",
  URL_OUTCOME_NODE = "urlOutcomeNode",
}
export type OptInInputKeys = "name" | "email" | "phone-number" | "terms";
export type OptInPutData = {
  required: boolean;
  value: string;
  shown: boolean;
};
export type OptInInputFields = {
  name: OptInPutData;
  email: OptInPutData;
  "phone-number": OptInPutData;
  terms: OptInPutData;
  buttonLabel: string;
  descLabel: string;
  privPolURL: string;
};

export type NodeData = {
  derivedFromNodeId?: string;
  hasTargetNode: boolean;
  brachedFromChoice?: string;
  rootNodeId: string;
  video?: string;
  videoID?: string;
  thumbnail?: string;
  "server-id"?: string;
  shouldStartVideoEncoding?: boolean;
  label?: string;
  ctaLabel?: string;
  recordedVideoResolution?: { width?: number; height?: number };
  children: NodeDataChildren;
  delayVideo?: boolean;
  step: number;
  optinData?: OptInInputFields;
  isOutcomeNode?: boolean;
  customSmallLabel?: string;
  selectedLastBranchSetting?: SelectedLastBranchSetting;
  selectedLastBranchSettingValue?: string;
  isWebinarMode?: boolean;
  delay?: number;
};

export enum SelectedLastBranchSetting {
  RETURN_TO_PARENT = 0,
  JUMP_TO_STEP = 1,
}

export type BranchedNodedata = {
  title?: string;
  label?: string;
  rootNodeId: string;
};

type NodeDataChildren =
  | {
      type: NodeDataChildType.NONE;
    }
  | {
      type: NodeDataChildType.CHOICES | NodeDataChildType.MULTIPLE;
      choices?: Array<NodeDataChildChoice>;
    }
  | {
      type: NodeDataChildType.OPEN_ENDED;
      openEndedType?: {
        v?: boolean;
        a?: boolean;
        t?: boolean;
      };
    };

export enum NodeDataChildType {
  CHOICES = "choices",
  MULTIPLE = "multiple-choices",
  NONE = "none",
  OPEN_ENDED = "open-ended",
}
export type NodeDataChildChoice = {
  id: string;
  branchId?: string;
  redirectURL?: string;
  jumpToStepId?: string;
  weight?: number;
  value: string;
  selectedOption: ChoiceActionTypes;
};

export enum ChoiceActionTypes {
  CONTINUE = 0,
  REDIRECT = 2,
  JUMP_TO_STEP = 3,
  BRANCH = 1,
}

export enum EdgeTypes {
  BUTTON_EDGE = "buttonEdge",
}

export type EdgeData = {
  isTargetBranchNode?: boolean;
};

export enum SavingState {
  IDLE,
  SAVING,
  ERROR,
  SUCCESS,
}
