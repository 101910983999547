import classNames from "classnames";
import { useId } from "react";

import "./InputTextField.scss";

interface InputTextFieldProps
  extends React.DetailedHTMLProps<
    React.InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
  > {
  variant?: "default" | "small";
  label?: string;
  value?: string;
  placeholder?: string;
  name?: string;
  onChange?: React.ChangeEventHandler<HTMLInputElement> | undefined;
  hasError?: boolean;
  type?: React.HTMLInputTypeAttribute;
}

export const InputTextField = ({
  variant = "default",
  label,
  value,
  onChange,
  placeholder,
  name,
  hasError,
  type,
  ...rest
}: InputTextFieldProps) => {
  const formId = useId();

  return (
    <fieldset className="input-text-field">
      {label && (
        <label
          htmlFor={`input-text-field-${formId}`}
          className="input-text-field__label"
        >
          {label}
        </label>
      )}
      <input
        type={type ?? "text"}
        id={`input-text-field-${formId}`}
        className={classNames(
          "input-text-field__input",
          {
            "input-text-field__input--small": variant === "small",
          },
          { "has-error": hasError }
        )}
        name={name}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        {...rest}
      />
    </fieldset>
  );
};
